import { DaoService } from "./DaoService";

export default class ObjectifRealiseService extends DaoService {
    static getInstance() {
        return super.getInstance('objectifrealise');
    }

    findByAffectation(affectationObjectifUid) {
        return ObjectifRealiseService.get(`${this.prefix}/${affectationObjectifUid}/affectation-objectif`);
    }
}