import React from "react";
import OrganisationList from "../../components/organisation/OrganisationList";


export default function SousOrganisationList({ parentId }) {
    const [loading, setLoading] = React.useState(true);
    const [paginationData, setPaginationData] = React.useState(null);

    return (
        <OrganisationList loading={loading} paginationData={paginationData} setLoading={setLoading} setPaginationData={setPaginationData} parentId={parentId} />
    );

} 