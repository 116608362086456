import React from 'react';
import { Modal, Form, Input, Checkbox, Row, Col, Card, Spin, Button } from 'antd';
import { catchError } from '../../services/DaoService';
import Toast from '../../helpers/Toast';
import { GroupeService } from '../../services/GroupeService';
import UserService from '../../services/UserService';
import { useNavigate } from 'react-router-dom';

const UserNew = ({ visible, onCreate, onCancel }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [groupes, setGroupes] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [creating, setCreating] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    GroupeService.getInstance()
      .all()
      .then((response) => {
        setGroupes(response.data);
      }).catch((error) => {
        catchError(error);
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  const gotoUserDetails = (user) => {
    navigate(`/parametrage/user/${user.uid}`);
  }

  const handleSave = (isRedirection) => {
    setCreating(true);
    form.validateFields().then(values => {
      values.groupeIds = selectedGroups;
      UserService.getInstance()
        .create(values)
        .then(response => {
          Toast.success(response.message);
          form.resetFields();
          onCreate(response.data);
          setSelectedGroups([]);
          if (isRedirection) {
            gotoUserDetails(response.data);
          }
        }).catch(error => {
          catchError(error);
        }).finally(() => {
          setCreating(false);
        });
    }).catch(info => {
      console.log('Validation Failed:', info);
      setCreating(false);
    });
  };

  const onCheckGroupChange = (checkedValues) => {
    setSelectedGroups(checkedValues);
  };

  return (
    <Modal open={visible} onCancel={onCancel} title="Créer un nouvel utilisateur"
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Annuler
        </Button>,
        <Button type="primary" loading={creating} onClick={() => handleSave(true)}>
          Enregistrer et Afficher
        </Button>,
        <Button type="primary" loading={creating} onClick={() => handleSave(false)}>
          Enregistrer et Créer
        </Button>,
      ]}
    >
      <Card style={{ marginBottom: 20 }}>
        <Spin spinning={loading}>
          <Form layout='vertical'
            form={form}
            name="createUserForm"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ enabled: false, groups: [] }}
          >
            <Row gutter={24}>
              <Col xs={24} md={24}>
                <Form.Item
                  label="Nom"
                  name="name"
                  rules={[
                    { required: true, message: 'Veuillez saisir le nom!' },
                    { min: 3, message: 'Le nom doit contenir au moins 3 caractères!' }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: 'Veuillez saisir l\'adresse email!' },
                    { type: 'email', message: 'Veuillez saisir une adresse email valide!' }
                  ]}
                >
                  <Input type="email" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Groupes">
              <Checkbox.Group value={selectedGroups} options={groupes.map(group => ({ label: group.nom, value: group.id }))} onChange={onCheckGroupChange} />
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </Modal>
  );
};

export default UserNew;