import { DaoService } from "./DaoService";

export default class OrganisationService extends DaoService {
    static getInstance() {
        return super.getInstance('organisation');
    }

    all() {
        return OrganisationService.get(`${this.prefix}/all`);
    }

    findByObjectif(objectifId) {
        return OrganisationService.get(`${this.prefix}/${objectifId}/objectif`);
    }

    search(data) {
        return OrganisationService.post(`${this.prefix}/search`, data);
    }
}