import React from "react";
import { Card, Col, Form, Input, Modal, Row, Select } from "antd";
import ObjectifService from "../../services/ObjectifService";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";

export default function ObjectifEdit({ uid, visible, onUpdate, onCancel }) {

    const [form] = Form.useForm();
    const [updating, setUpdating] = React.useState(false);

    React.useEffect(() => {
        if (!visible) return;
        if (uid) {
            setUpdating(true);
            ObjectifService.getInstance()
                .find(uid)
                .then(response => {
                    form.setFieldsValue(response.data);
                }).catch(error => {
                    catchError(error);
                }).finally(() => {
                    setUpdating(false);
                });
        }
    }, [uid, form, visible]);
    
    const onFinish = (values) => {
        setUpdating(true);
        ObjectifService.getInstance()
            .update(uid, values)
            .then(response => {
                Toast.success(response.message);
                form.resetFields();
                onUpdate(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setUpdating(false);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const { Option } = Select;

    return (
        <Modal open={visible} okText="Mettre à jour" cancelText="Annuler" onOk={form.submit} onCancel={onCancel} width={"50%"}>
            <Card loading={updating}>
                <Form form={form} name="newObjectifForm" layout="vertical"
                    onFinish={onFinish} onFinishFailed={onFinishFailed}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, }}>
                        <Col className="gutter-row" span={24} >
                            <Form.Item
                                label="Intitule"
                                name="intitule"
                                rules={[
                                    { required: true, message: 'l\'intitule est obligatoire!' },
                                    { min: 3, message: 'L\'intitule doit contenir au moins 3 caractères!' }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, }}>
                        <Col className="gutter-row" span={24} >
                            <Form.Item
                                label="Indicateur"
                                name="indicateur"
                                rules={[
                                    { required: true, message: 'L\'indicateur est obligatoire!' },
                                    { min: 3, message: 'L\'indicateur doit contenir au moins 3 caractères!' }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24} >
                            <Form.Item
                                label="Unite de mesure"
                                name="unite"
                                rules={[
                                    { required: true, message: 'L\'unite est obligatoire!' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label="Pente"
                        name="pente"
                        rules={[
                            {
                                required: true,
                                message: 'Ce champ est obligatoire!',
                            },
                        ]}
                    >
                        <Select placeholder="Choisir une valeur">
                            <Option value="ascendante">Ascendante</Option>
                            <Option value="descendante">Descendante</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Card>
        </Modal>
    );

};