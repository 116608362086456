import React from "react";
import { Form, Input, Row, Col, Modal, Card } from "antd";
import { catchError } from "../../services/DaoService";
import MissionControleService from "../../services/MissionControleService";
import Toast from "../../helpers/Toast";
import TextArea from "antd/es/input/TextArea";


export default function MissionControleEdit({ onUpdate, visible, onCancel, missionControleUid }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const [updating, setUpdating] = React.useState(false);
    
    React.useEffect(() => {
        if (!visible) return;
        if (missionControleUid) {
            setLoading(true);
            MissionControleService.getInstance()
                .findByMissionControle(missionControleUid)
                .then(response => {
                    form.setFieldsValue(response.data);
                }).catch(error => {
                    catchError(error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [missionControleUid, form, visible]);
    

    const onFinish = (values) => {
        setUpdating(true);
        MissionControleService.getInstance()
            .update(missionControleUid, values)
            .then(response => {
                Toast.success(response.message);
                form.resetFields();
                onUpdate(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setUpdating(false);
            });
        
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <Modal title="Modification mission de contrôle" open={visible} okText="Mettre à jour" okType="primary" cancelText="Annuler" onOk={form.submit} onCancel={onCancel} width={"50%"}>
            <Card loading={loading}>
                <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed} loading={updating}>
                    <Row>
                        <Col className="gutter-row" span={24} >
                            <Form.Item
                                label="Nom"
                                name="nom"
                                rules={[
                                    { required: true, message: 'le nom est obligatoire!' },
                                    { min: 3, message: 'Le nom doit contenir au moins 3 caractères!' }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24} >
                            <Form.Item
                                label="Responsable"
                                name="responsable"
                                rules={[
                                    { required: true, message: 'le responsabe est obligatoire!' },
                                    { min: 3, message: 'Le nom doit contenir au moins 3 caractères!' }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={12} >
                            <Form.Item
                                label="Date affectation"
                                name="date_affectation"
                                rules={[{ required: true }]}>
                                <Input type="date" disabled />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12} >
                            <Form.Item
                                label="Delai affectation"
                                name="date_delai"
                                rules={[{ required: true }]}>
                                <Input type="date" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24} >
                            <Form.Item
                                label="Description"
                                name="description"
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24} >
                            <Form.Item
                                label="Observation"
                                name="observations"
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Modal >
    )

}