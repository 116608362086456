import React, { useCallback } from "react";
import { Button, Card, Col, Divider, Input, Popconfirm, Progress, Row, Select, Space, Spin, Tag } from "antd";
import ObjectifNew from "../../components/objectif/ObjectifNew";
import { ArrowDownOutlined, ArrowUpOutlined, DeleteFilled, EditOutlined, EyeOutlined, FileOutlined, FolderOpenOutlined, PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { useUserContext } from "../../providers/UserProvider";
import { catchError } from "../../services/DaoService";
import ObjectifService from "../../services/ObjectifService";
import ObjectifEdit from "../../components/objectif/ObjectifEdit";
import { useNavigate } from "react-router-dom";
import Toast from "../../helpers/Toast";
import UnauthorizedMessage from "../../components/utils/UnauthorizedMessage";
import InfiniteTable from "../../components/utils/InfiniteTable";



export default function ObjectifList({ paginationData, setPaginationData, loading, title="Liste des objectifs", setLoading, parentId }) {
    const [objectifs, setObjectifs] = React.useState([]);
    const [newModalVisible, setNewModalVisible] = React.useState(false);
    const [hasMore, setHasMore] = React.useState(false);
    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [selectedObjectif, setSelectedObjectif] = React.useState(null);
    const [deleting, setDeleting] = React.useState(false);
    const [isArchiving, setIsArchiving] = React.useState(false);
    const [isLoadingMore, setIsLoadingMore] = React.useState(false);
    const { check } = useUserContext();
    const navigate = useNavigate();
    const [searchText, setSearchText] = React.useState(null);
    const [penteSelected, setPenteSelected] = React.useState('tout');
    const [statut, setStatut] = React.useState('tout');
    const init = useCallback(() => {
        setLoading(true);
        ObjectifService.getInstance()
            .search({ searchText, penteSelected, statut, parent_id: parentId })
            .then((response) => {
                if (!response) return;
                setPaginationData(response.data);
                setObjectifs(response.data.data);
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [searchText, penteSelected, statut, setLoading, setPaginationData, parentId]);

    React.useEffect(() => {
        if (!check('VIEW-OBJECTIVES')) {
            return;
        }
        init();
    }, [init, check, isArchiving]);

    const removeItem = (objectif) => {
        setDeleting(true);
        ObjectifService.getInstance()
            .remove(objectif.uid)
            .then((response) => {
                setObjectifs(objectifs.filter(item => item.uid !== objectif.uid));
                Toast.success(response.message);
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setDeleting(false);
            });
    }

    React.useEffect(() => {
        if (paginationData) {
            // merge the new data with the old data without duplicates (if any)
            setObjectifs(objectifs => [...new Map([...objectifs, ...paginationData.data].map(item => [item['id'], item])).values()]);

            setHasMore(paginationData.next_page_url != null);
        } else {
            setHasMore(false);
        }
    }, [paginationData]);

    const loadMore = () => {
        console.log('load');
        if (hasMore && !isLoadingMore) {
            console.log('load if');
            setIsLoadingMore(true);
            ObjectifService.post(paginationData.next_page_url, { searchText, penteSelected, statut })
                .then((response) => {
                    if (!response) return;
                    setPaginationData(response.data);
                }).catch((error) => {
                    catchError(error);
                }).finally(() => {
                    setIsLoadingMore(false);
                });
        }
    }

    const gotoObjectifDetails = (objectif) => {
        navigate(`/objectif/details/${objectif.uid}`);
    }

    const handleDesarchiver = (objectif) => {
        setIsArchiving(true);
        ObjectifService.getInstance()
            .enable(objectif.uid)
            .then((response) => {
                Toast.success(response.message);
                setObjectifs(objectifs.map(o => o.uid === objectif.uid ? objectif : o));
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setIsArchiving(false);
            });
    }
    const handleArchiver = (objectif) => {
        setIsArchiving(true);
        ObjectifService.getInstance()
            .disable(objectif.uid)
            .then((response) => {
                Toast.success(response.message);
                setObjectifs(objectifs.map(o => o.uid === objectif.uid ? objectif : o));
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setIsArchiving(false);
            });
    }

    const columns = [
        {
            title: 'Intitule',
            dataIndex: 'intitule',
            key: 'intitule',
            sorter: (a, b) => a.intitule.localeCompare(b.intitule),
            width: 400,
            // rendre le texte en plusieurs lignes
            render: (text) => (
                <div style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                    maxWidth: 300
                }}>
                    {text}
                </div>
            ),
        },
        {
            title: 'Indicateur',
            dataIndex: 'indicateur',
            key: 'indicateur',
            width: 200,
            // rendre le texte en plusieurs lignes
            render: (text) => (
                <div style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                    maxWidth: 200
                }}>
                    {text}
                </div>
            ),
        },
        {
            title: 'Unite',
            dataIndex: 'unite',
            key: 'unite',
            width: 200,
            ellipsis: true,
        },
        {
            title: 'Pente',
            dataIndex: 'pente',
            key: 'pente',
            width: 120,
            render: (pente) => (
                <>
                    {
                        pente === 'ascendante' ? <ArrowUpOutlined style={{ color: 'green', fontSize: "24px" }} /> : <ArrowDownOutlined style={{ color: 'red', fontSize: "24px" }} />

                    }
                </>
            )
        },
        {
            title: "Progression",
            dataIndex: 'progression',
            key: 'progression',
            sorter: (a, b) => a.progression - b.progression,
            render: (progression) => <Progress percent={progression} strokeWidth={15} strokeColor={"#2f863d"} trailColor="#F8EF41" size="small" />,
            width: 250,
        },
        {
            title: 'Statut',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (enabled) =>
                enabled ? <Tag color="green"> actif</Tag> : <Tag color="red">Inactif</Tag>,
            width: 80,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 300,
            // rendre le texte en plusieurs lignes
            render: (text) => (
                <div style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                    maxWidth: 300
                }}>
                    {text ?? 'N/A'}
                </div>
            ),
        },
        {
            title: "Assignations",
            dataIndex: 'affectation_objectifs_count',
            key: 'affectation_objectifs_count',
            sorter: (a, b) => a.affectation_objectifs_count - b.affectation_objectifs_count,
            render: (count) => <Tag color='cyan'>{count} organisation(s)</Tag>,
            width: 150,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space size="small">
                    {check('VIEW-OBJECTIVE') ? <Button onClick={() => gotoObjectifDetails(record)} type="primary" icon={<EyeOutlined />}></Button> : null}
                    {check('EDIT-OBJECTIVE') ? <Button onClick={() => setItemToEdit(record)} type="default" className='btn-warning' icon={<EditOutlined />}></Button> : null}
                    {check('DELETE-OBJECTIVE') ? (
                        <Popconfirm title="Voulez-vous vraiment supprimer cet objectif?" onConfirm={() => { removeItem(record) }} okText="Oui" cancelText="Non" okType="danger">
                            <Button loading={deleting} type="primary" danger icon={<DeleteFilled />}></Button>
                        </Popconfirm>
                    ) : null}

                    {check('ARCHIVE-OBJECTIVE') ? (
                        record.enabled ? (
                            <Popconfirm
                                title="Voulez-vous archiver cet objectif ?"
                                onConfirm={() => handleArchiver(record)}
                                okText="Archiver"
                                cancelText="Annuler"
                                okType="danger"
                            >
                                <Button type="primary" danger icon={<FileOutlined />} />
                            </Popconfirm>
                        ) : (
                            <Popconfirm
                                title="Voulez-vous vraiment désarchiver ?"
                                onConfirm={() => handleDesarchiver(record)}
                                okText="Oui"
                                cancelText="Non"
                                okType="primary"
                            >
                                <Button className="btn btn-secondary" title="Désarchiver" icon={<FolderOpenOutlined />} />
                            </Popconfirm>
                        )
                    ) : null}
                </Space>
            ),
            width: 250,
        },
    ];

    const handleOjbectifUpdate = (objectif) => {
        setObjectifs(objectifs.map((item) => item.id === objectif.id ? objectif : item));
        setEditModalVisible(false);
    }

    const setItemToEdit = (objectif) => {
        setSelectedObjectif(objectif);
        setEditModalVisible(true);
    };

    const handleNewOjbectifCreation = (objectif) => {
        setObjectifs([...objectifs, objectif]);
        setNewModalVisible(false);
    }

    const labelStyle = {
        marginRight: '10px', // espace entre le label et le select
        display: 'flex',
        alignItems: 'center',
    };

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    };

    return (
        <>
            {check('VIEW-OBJECTIVES') ?
                <>
                    <ObjectifNew parent_id={parentId} onCreate={handleNewOjbectifCreation} visible={newModalVisible} onCancel={() => setNewModalVisible(false)} />
                    {selectedObjectif ? <ObjectifEdit onUpdate={handleOjbectifUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} uid={selectedObjectif.uid} /> : null}
                    <Card title={title} classNames={{ body: 'p-0' }}>
                        <Spin spinning={loading}>
                            <InfiniteTable pagination={false} loadMore={loadMore}
                                rowKey={record => record.uid} loading={loading || isLoadingMore}
                                dataSource={objectifs} columns={columns} paginationData={paginationData}
                                title={
                                    <Row gutter={[24, 16]}>
                                        <Col span={24}>
                                            <Space>
                                                {
                                                    check('ADD-OBJECTIVE') ? <Button onClick={() => setNewModalVisible(true)} icon={<PlusCircleOutlined />} type="primary">Ajouter objectif</Button> : null
                                                }
                                                <Button onClick={init} icon={<ReloadOutlined />} type="default">Rafraîchir</Button>
                                            </Space>
                                        </Col>
                                        <Col span={24}>
                                            <Row gutter={[16, 16]} justify={'center'}>
                                                <Col span={24}>
                                                    <Divider>Filtres rapides</Divider>
                                                </Col>
                                                <Col xs={24} md={8} lg={6}>
                                                    <Input.Search value={searchText} onInput={
                                                        (e) => {
                                                            setSearchText(e.target.value);
                                                        }} placeholder="Rechercher un objectif" />
                                                </Col>
                                                <Col xs={24} md={8} lg={6}>
                                                    <div style={containerStyle}>
                                                        <div style={labelStyle}>Pente:</div>
                                                        <Select value={penteSelected} onChange={
                                                            (value) => {
                                                                setPenteSelected(value);
                                                            }
                                                        } placeholder="Filtrer par pente" style={{ width: '100%' }}>
                                                            <Select.Option value="tout">Tous</Select.Option>
                                                            <Select.Option value={ObjectifService.PENTES.ASCENDANTE.value}>{ObjectifService.PENTES.ASCENDANTE.libele}</Select.Option>
                                                            <Select.Option value={ObjectifService.PENTES.DESCENDANTE.value}>{ObjectifService.PENTES.DESCENDANTE.libele}</Select.Option>
                                                        </Select>
                                                    </div>
                                                </Col>
                                                <Col xs={24} md={8} lg={6}>
                                                    <div style={containerStyle}>
                                                        <div style={labelStyle}>Statut:</div>
                                                        <Select value={statut} onChange={
                                                            (value) => {
                                                                setStatut(value);
                                                            }
                                                        } placeholder="Filtrer par statut" style={{ width: '100%' }}>
                                                            <Select.Option value="tout">Tous</Select.Option>
                                                            <Select.Option value="actif">Activé</Select.Option>
                                                            <Select.Option value="inactif">Désactivé</Select.Option>
                                                        </Select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                } />
                        </Spin>
                    </Card>
                </> : <UnauthorizedMessage />
            }
        </>
    );
}