import { DaoService } from "./DaoService";

export default class EntiteService extends DaoService {
    static getInstance() {
        return super.getInstance('entite');
    }

    findByTypeEntite(typeUid) {
        return EntiteService.get(`${this.prefix}/${typeUid}/type-entite`);
    }
}