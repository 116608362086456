import React, { useState, useEffect } from 'react';
import { List, Button, Space } from 'antd';
import { catchError } from '../../services/DaoService';
import UserService from '../../services/UserService';
import UserOrganisationsForm from './UserOrganisationsForm';
import { EditFilled, ReloadOutlined } from '@ant-design/icons';
import { useUserContext } from '../../providers/UserProvider';
import UnauthorizedMessage from '../utils/UnauthorizedMessage';


const UserOrganisations = ({ userUid }) => {
    const [loading, setLoading] = useState(false);
    const [organisations, setOrganisations] = useState([]);
    const [formVisible, setFormVisible] = useState(false);

    const { check } = useUserContext();

    const fetchOrganisations = React.useCallback(() => {
        setLoading(true);
        UserService.getInstance()
            .findLinkedOrganisations(userUid)
            .then((response) => {
                setOrganisations(response.data);
            })
            .catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [userUid]);

    useEffect(() => {
        fetchOrganisations();
    }, [fetchOrganisations]);

    const showForm = () => {
        setFormVisible(true);
    };

    const hideForm = () => {
        setFormVisible(false);
    };

    const handleUpdate = () => {
        fetchOrganisations();
        hideForm();
    }

    return (
        <div className='px-2' style={{
            maxHeight: '60vh',
            overflowY: 'auto'
        }}>
            {check('VIEW-ORGANIZATION-USERS') ? <List loading={loading}
                header={<div>Organisations auxquelles vous avez accès</div>}
                footer={
                    <Space>
                        {
                            check('ADD-USER-TO-ORGANIZATION') ?
                            <Button type="primary" className='btn btn-warning text-dark' icon={<EditFilled />} onClick={showForm}>Assigner des organisations </Button> : null
                        }
                        <Button loading={loading} type='default' icon={<ReloadOutlined />} onClick={fetchOrganisations}>Actualiser</Button>
                    </Space>
                }
                bordered
                dataSource={organisations}
                renderItem={(organisation) => (
                    <List.Item key={organisation.uid}>
                        <List.Item.Meta
                            title={organisation.nom}
                            description={organisation.description}
                        />
                    </List.Item>
                )}
            /> : <UnauthorizedMessage />}
            {(formVisible && check('ADD-USER-TO-ORGANIZATION')) ? <UserOrganisationsForm visible={formVisible} userId={userUid} onCancel={hideForm} onUpdate={handleUpdate} /> : null}
        </div>
    );
};

export default UserOrganisations;