import { DaoService } from "./DaoService";

export default class DashboardConfigService extends DaoService{

    static getInstance() {
        return super.getInstance('dashboardconfig');
    }

    getRecord() {
        return DashboardConfigService.get(`${this.prefix}`);
    }

    getOrganisationEvolutions(organisationIds) {
        return DashboardConfigService.post(`${this.prefix}/organisation-evolutions`, {organisation_ids: organisationIds});
    }

    getObjectifProgressions(objectifIds) {
        return DashboardConfigService.post(`${this.prefix}/objectif-evolutions`, {objectif_ids: objectifIds});
    }

    getOrganisationPerformanceGlobal() {
        return DashboardConfigService.get(`${this.prefix}/organisation-performance-global`);
    }

    getObjectifProgressionsGlobal() {
        return DashboardConfigService.get(`${this.prefix}/objectif-progression-global`);
    }
    
    getTop5PerformingOrganisations() {
        return DashboardConfigService.get(`${this.prefix}/top5-organisations-performing`);
    }

    getObjectifsAffectesCount() {
        return DashboardConfigService.get(`${this.prefix}/objectif-affectation-count`);
    }

    getObjectifsAtteintsCount() {
        return DashboardConfigService.get(`${this.prefix}/objectif-atteint-count`);
    }

    getObjectifsControlesCount() {
        return DashboardConfigService.get(`${this.prefix}/objectif-controle-count`);
    }
}