import { Card, Spin, Typography } from "antd";
import React from "react";
import Chart from "react-apexcharts";
import DashboardConfigService from "../../services/DashboardConfigService";
import { catchError } from "../../services/DaoService";

const { Title } = Typography;

// Composant pour le diagramme
const ChartComponent = ({ data }) => {
    const options = {
        chart: {
            type: 'bar',
            height: 200,
            stacked: true,
            toolbar: {
                show: true,
            },
            zoom: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10
            }
        },
        xaxis: {
            categories: data.map(item => item.sigle),
            labels: {
                style: {
                    colors: Array(data.length).fill('#000'),
                    fontSize: '12px',
                    fontWeight: 700,
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 700,
                },
            },
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        },
        colors: ['#2F863D', '#D9363E']
    };

    const series = [
        {
            name: 'Objectifs atteints',
            data: data.map(item => item.objectifs_atteints)
        },
        {
            name: 'Objectifs non atteints',
            data: data.map(item => item.objectifs - item.objectifs_atteints)
        }
    ];

    return (
        <div>
            <Chart options={options} series={series} type="bar" height={200} />
        </div>
    );
};

export default function OrganisationEvolutionsDynamicChart({ dashboardConfig }) {

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if(!dashboardConfig){return;}
        setLoading(true);
        const values = dashboardConfig.organisation_ids ;
        DashboardConfigService.getInstance()
            .getOrganisationEvolutions(values)
            .then((response) => {
                setData(response.data);
            }).catch(err => catchError(err))
            .finally(() => setLoading(false));
    }, [dashboardConfig]);

    return (
        <Card loading={loading} height={500} style={{ borderRadius: '0' }} title={
            <Title className="text-center" style={{whiteSpace: 'pre-wrap'}} level={4}>Progression des Objectifs par Organisation</Title>
        }>
            {loading ? <Spin size="large" /> : (
                <ChartComponent data={data} />)}
        </Card>
    );
}