import { DaoService } from "./DaoService";

export default class TypeOrganisationService extends DaoService {
    static getInstance() {
        return super.getInstance('typeorganisation');
    }

    all() {
        return TypeOrganisationService.get(`${this.prefix}/all`);
    }
}