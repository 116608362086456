import React, { useState } from "react";
import DashboardService from "../../services/DashboardService";
import { catchError } from "../../services/DaoService";
import { Card, Col, Row } from "antd";
import { DashboardFilled } from "@ant-design/icons";
import { Typography } from "antd";

const { Title } = Typography;

const CountKPIsChart = () => {
    const [kpiData, setKpiData] = useState([]);
    const [loading, setLoading] = useState(true);
  
    React.useEffect(() => {
      setLoading(true);
      DashboardService.getInstance()
        .countKPIs()
        .then((response) => {
          setKpiData(response.data);
          setLoading(false);
        }).catch(err => catchError(err))
        .finally(() => setLoading(false));
    }, []);
  
    return (
      <>
        <Row className="rowgap-vbox" justify={"center"} gutter={[24, 0]}>
          {kpiData.map((kpi, index) => (
            <Col
              key={"kpi-" + index}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Card loading={loading} bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{kpi.label}</span>
                      <Title level={3}>
                      {kpi.value}
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                        <DashboardFilled />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </>
    );
  }

export default CountKPIsChart;