import { Card, Form, Input, Modal } from "antd";
import React from "react";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";
import ObjectifRealiseService from "../../services/ObjectifRealiseService";

export default function ObjectifRealiseEdit({ visible, onUpdate, onCancel, uid }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const [updating, setUpdating] = React.useState(false);
    const [objectifRealise, setObjectifRealise] = React.useState(null);

    React.useEffect(() => {
        if (!visible) return;
        if (uid) {
            setLoading(true);
            ObjectifRealiseService.getInstance()
                .find(uid)
                .then(response => {
                    setObjectifRealise(response.data);
                    form.setFieldsValue(response.data);
                }).catch(error => {
                    catchError(error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [uid, form, visible]);

    const onFinish = (values) => {
        setUpdating(true);
        ObjectifRealiseService.getInstance()
            .update(objectifRealise.uid, values)
            .then(response => {
                Toast.success(response.message);
                form.resetFields();
                onUpdate(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setUpdating(false);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <Modal open={visible} okText="Mettre à jour" okType="primary" cancelText="Annuler" onOk={form.submit} onCancel={onCancel}>
            <Card loading={loading}>
                <Form
                    form={form} name="editObjectifRealiseForm"
                    layout="vertical"
                    onFinish={onFinish} onFinishFailed={onFinishFailed} loading={updating}
                >
                    <Form.Item label="Date Réalisation" name="date_realisation" rules={[
                        { required: true, message: 'Veuillez saisir une date de délai!' }
                    ]}>
                        <Input type="date" />
                    </Form.Item>

                    <Form.Item label="Valeur réelle" name="valeur_reelle" rules={[
                        { required: true, message: 'Veuillez saisir une valeur cible!' }
                    ]}>
                        <Input type="number" addonAfter={objectifRealise?.unite} />
                    </Form.Item>
                    
                    <Form.Item
                        label="Remarque"
                        name="remarque"
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Card>
        </Modal>
    );
}