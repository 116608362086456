import { DaoService } from "./DaoService";

export default class TypeEntiteService extends DaoService {
    static getInstance() {
        return super.getInstance('typeentite');
    }

    all() {
        return TypeEntiteService.get(`${this.prefix}/all`);
    }
}