import { useEffect } from "react";

import {
  Row,
  Col,
  Badge,
  Button,
  List,
  Avatar,
  Popover,
} from "antd";
import logo from "../../assets/images/logo.png";

import { Link, useNavigate } from "react-router-dom";
import avtar from "../../assets/images/team-2.jpg";
import SearchBar from "./SearchBar";
import Moment from "react-moment";
import { useNotificationContext } from "../../providers/NotificationProvider";
import NotificationService from "../../services/NotificationService";
import { useUserContext } from "../../providers/UserProvider";
import { catchError } from "../../services/DaoService";
import MenuHorizontal from "./MenuHorizontal";

const bell = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
      fill="#111827"
    ></path>
    <path
      d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z"
      fill="#111827"
    ></path>
  </svg>,
];

const clockicon = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V10C9 10.2652 9.10536 10.5196 9.29289 10.7071L12.1213 13.5355C12.5118 13.9261 13.145 13.9261 13.5355 13.5355C13.9261 13.145 13.9261 12.5118 13.5355 12.1213L11 9.58579V6Z"
      fill="#111827"
    ></path>
  </svg>,
];



const profile = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
    ></path>
  </svg>,
];

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({
  onPress, page, menuItems
}) {
  const { unReadNotifications,
    countUnreadNotifications, loadUnreadNotifications, loadUnreadNotificationsCount } = useNotificationContext();
  const { currentUser } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => window.scrollTo(0, 0));

  const data = unReadNotifications.map(notification => ({
    title: notification.data.title,
    description: <>
      <div>{notification.data.body}</div>
      <div>{clockicon} <Moment fromNow>{notification.created_at}</Moment></div>
    </>,
    avatar: avtar,
    url: notification.data.url,
    id: notification.id
  }));

  const handleReadNotificationAndRedirect = (notification) => {
    NotificationService.getInstance()
      .markAsRead(currentUser.uid, notification.id)
      .then(() => {
        loadUnreadNotifications();
        loadUnreadNotificationsCount();
        navigate(notification.url);
      }).catch((error) => {
        catchError(error);
      });
  }

  const menu = (
    <List
      className="header-notifications-dropdown"
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <Link onClick={() => handleReadNotificationAndRedirect(item)}>
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar shape="square" src={item.avatar} />}
              title={item.title}
              description={item.description}
            />
          </List.Item>
        </Link>
      )}
      footer={
        <div style={{ textAlign: 'center' }}>
          <Link to="/notifications">Voir plus</Link>
        </div>
      }
    />
  );


  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} className="header-control">
          <Badge size="small" count={countUnreadNotifications}>
            <Popover placement="bottomRight" content={menu} trigger={["hover"]}>
              <a
                href="#pablo"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {bell}
              </a>
            </Popover>
          </Badge>
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>
          <Link to="/profile" className="btn-sign-in">
            {profile}
            <span>Mon profil</span>
          </Link>
          <SearchBar />
          <MenuHorizontal menuItems={menuItems} page={page} />
          <div className="d-none d-sm-block" style={{
            display: "flex",
            alignItems: "center",
            padding: "16px"
          }}>
            <Link to="/">
              <img style={{
                width: "40px",
                marginRight: "8px"
              }} src={logo} alt="" />
            </Link>
            <Link to="/">
              <span style={{
                fontSize: "22px",
                fontWeight: "bold",
                color: "#1D7C55"
              }}>{process.env.REACT_APP_NAME}</span>
            </Link>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Header;
