import { DaoService } from "./DaoService";

export default class AffectationObjectifService extends DaoService {
    static getInstance() {
        return super.getInstance('affectationobjectif');
    }

    assignObjectifsToOrganisation(data) {
        return AffectationObjectifService.post(`${this.prefix}/assign-to-organisation`, data);
    }

    findByOrganisation(organisationId) {
        return AffectationObjectifService.get(`${this.prefix}/${organisationId}/organisation`);
    }

    findByOrganisations(organisationIds) {
        return AffectationObjectifService.post(`${this.prefix}/find-by-organisations`, {organisation_ids: organisationIds});
    }

    findByObjectif(objectifId) {
        return AffectationObjectifService.get(`${this.prefix}/${objectifId}/objectif`);
    }

    enable(idOrUid) {
        return AffectationObjectifService.post(`${this.prefix}/${idOrUid}/enable`);
    }

    disable(idOrUid) {
        return AffectationObjectifService.post(`${this.prefix}/${idOrUid}/disable`);
    }

    updateAffectationObjectifsList(data) {
        return AffectationObjectifService.put(`${this.prefix}`, data);
    }
}