import { DaoService } from "./DaoService";

export default class DashboardService extends DaoService {
    static getInstance() {
        return super.getInstance('dashboard');
    }

    countKPIs() {
        return DashboardService.get(`${this.prefix}/count-kpis`);
    }

    getOrganisationEvolutions() {
        return DashboardService.get(`${this.prefix}/organisation-evolutions`);
    }

    getEntiteAffectationObjectifsEvolutions(affectationObjectifUid) {
        return DashboardService.get(`${this.prefix}/${affectationObjectifUid}/entite-affectation-objectifs-evolutions`);
    }

    getAffectationDirecteObjectifsEvolutions(affectationObjectifUid) {
        return DashboardService.get(`${this.prefix}/${affectationObjectifUid}/affectation-directe-objectifs-evolutions`);
    }
}