import { Card, Col, Progress, Row, Spin, Statistic } from "antd";
import React from "react";
import DashboardConfigService from "../../services/DashboardConfigService";
import { catchError } from "../../services/DaoService";
import { ArrowsAltOutlined, CheckOutlined, LikeOutlined } from "@ant-design/icons";


export default function DashboardStatistic() {

    const [objectifAffecteCount, setObjectifAffecteCount] = React.useState(0);
    const [objectifAffecteAtteintCount, setObjectifAffecteAtteintCount] = React.useState(0);
    const [objectifControleCount, setObjectifControleCount] = React.useState(0);
    const [objectifProgressionGlobal, setObjectifProgressionGlobal] = React.useState(0);
    const [loadingObjectifAffecteCount, setLoadingObjectifAffecteCount] = React.useState(true);
    const [loadingObjectifAffecteAtteintCount, setLoadingObjectifAffecteAtteintCount] = React.useState(true);
    const [loadingObjectifControleCount, setLoadingObjectifControleCount] = React.useState(true);
    const [loadingObjectifProgressionGlobal, setLoadingObjectifProgressionGlobal] = React.useState(true);

    React.useEffect(() => {
        setLoadingObjectifAffecteCount(true);
        setLoadingObjectifAffecteAtteintCount(true);
        setLoadingObjectifControleCount(true);
        DashboardConfigService.getInstance()
            .getObjectifsAffectesCount()
            .then((response) => {
                setObjectifAffecteCount(response.data);
            }).catch(err => catchError(err))
            .finally(() => setLoadingObjectifAffecteCount(false));

        DashboardConfigService.getInstance()
            .getObjectifsAtteintsCount()
            .then((response) => {
                setObjectifAffecteAtteintCount(response.data);
            }).catch(err => catchError(err))
            .finally(() => setLoadingObjectifAffecteAtteintCount(false));

        DashboardConfigService.getInstance()
            .getObjectifsControlesCount()
            .then((response) => {
                setObjectifControleCount(response.data);
            }).catch(err => catchError(err))
            .finally(() => setLoadingObjectifControleCount(false));
    }, []);

    React.useEffect(() => {
        setLoadingObjectifProgressionGlobal(true);
        const percent = (objectifAffecteAtteintCount * 100) / objectifAffecteCount;
        setObjectifProgressionGlobal(percent);
        setLoadingObjectifProgressionGlobal(false);
    }, [objectifAffecteCount, objectifAffecteAtteintCount]);

    return (
        <Row gutter={24}>
            <Col className="gutter-row mb-4" span={24}>
                <Card size="small" style={{ borderRadius: '0' }}>
                    <h6 className="text-center">Statistiques</h6>
                </Card>
            </Col>
            <Col className="gutter-row mb-4" span={24}>
                <Card style={{ borderRadius: '0' }}>
                    {loadingObjectifAffecteCount ? <Spin size="large" /> : (
                        <div className='d-flex flex-column justify-content-center '>
                            <Statistic
                                value={objectifAffecteCount}
                                valueStyle={{
                                    color: '#3f8600',
                                    fontSize: '28px'
                                }}
                                prefix={<ArrowsAltOutlined  style={{ fontSize: '22px' }} />}
                            />
                            <h7 className="text-muted">Objectifs Affectés</h7>
                        </div>
                    )}
                </Card>
            </Col>
            <Col className="gutter-row mb-4" span={24}>
                <Card style={{ borderRadius: '0' }}>
                    {loadingObjectifAffecteAtteintCount ? <Spin size="large" /> : (
                        <div className='d-flex flex-column justify-content-center '>
                            <Statistic value={objectifAffecteAtteintCount} valueStyle={{
                                color: '#3f8600',
                                fontSize: '28px',
                            }} prefix={<LikeOutlined />} />
                            <h7 className="text-muted">Objectifs Affectés Atteints</h7>
                        </div>
                    )}
                </Card>
            </Col>
            <Col className="gutter-row mb-4 mt-4" span={24}>
                <Card style={{ borderRadius: '0' }}>
                    {loadingObjectifProgressionGlobal ? <Spin size="large" /> : (
                        <div className='d-flex flex-column justify-content-center '>
                            <Progress
                                type="dashboard"
                                steps={10}
                                percent={objectifProgressionGlobal}
                                trailColor="rgba(0, 0, 0, 0.06)"
                                strokeWidth={20}
                            />
                            <h8 className="text-center text-muted">Progression global des objectifs</h8>
                        </div>
                    )}
                </Card>
            </Col>
            <Col className="gutter-row mb-3" span={24}>
                <Card style={{ borderRadius: '0' }}>
                    {loadingObjectifControleCount ? <Spin size="large" /> : (
                        <div className='d-flex flex-column justify-content-center '>
                            <Statistic
                                value={objectifControleCount}
                                valueStyle={{
                                    color: '#3f8600',
                                    fontSize: '28px'
                                }}
                                prefix={<CheckOutlined style={{ fontSize: '22px' }} />}
                            />
                            <h8 className="text-center text-muted">objectifs controllés</h8>
                        </div>
                    )}
                </Card>
            </Col>
        </Row>
    )

}