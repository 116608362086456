import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Typography, Table, Button, Spin, Divider, Popconfirm, Space, message, Descriptions, Tag, Progress, Row, Col, Tabs } from "antd";
import ObjectifRealiseService from "../../services/ObjectifRealiseService";
import AffectationObjectifService from "../../services/AffectationObjectifService";
import { catchError } from "../../services/DaoService";
import { useUserContext } from "../../providers/UserProvider";
import UnauthorizedMessage from "../../components/utils/UnauthorizedMessage";
import Main from "../../components/layout/Main";
import Moment from "react-moment";
import styles from "./AffectationObjectifDetailsPage.module.scss";  // Import the custom SCSS module
import { DeleteFilled, DeleteOutlined, EditFilled, EditOutlined, FallOutlined, RiseOutlined } from "@ant-design/icons";
import PaginationInfo from "../../components/utils/PaginationInfo";
import ProgressChart from "../../charts-model/ProgressChart";
import EntiteAffectationObjectifsEvolutionsChart from "../../components/chart/EntiteAffectationObjectifsEvolutionsChart";
import AffectationDirecteObjectifsEvolutions from "../../components/chart/AffectationDirecteObjectifsEvolutions";
import AffectationObjectifEdit from "../../components/affectationobjectif/AffectationObjectifEdit";
import ObjectifRealiseEdit from "../../components/affectationobjectif/ObjectifRealiseEdit";
const { Title } = Typography;

const AffectationEntiteList = ({ affectationEntites, objectif }) => {

    const columns = [
        {
            title: 'Entité',
            dataIndex: 'entite',
            key: 'entite',
            render: (entite) => entite.nom,
            width: 200
        },
        {
            title: 'Valeur cible',
            dataIndex: 'valeur_cible',
            key: 'valeur_cible',
            render: (valeur_cible) => <Tag color="blue">{valeur_cible} {objectif.unite}</Tag>,
            width: 150
        },
        {
            title: "Statut",
            dataIndex: 'atteint',
            key: 'atteint',
            render: (atteint) => atteint ? <Progress strokeColor="#1d7c55" strokeWidth={15} percent={100} size="small" status="success" /> : <Progress percent={100} size="small" status="exception" />,
            width: 200
        },
        {
            title: "Valeur réelle",
            dataIndex: 'valeur_reelle',
            key: 'valeur_reelle',
            render: (valeur_reelle) => valeur_reelle !== -1 ? <Tag color="green">{valeur_reelle} {objectif.unite}</Tag> : <Tag color="red">Aucune réalisation renseignée</Tag>,
            width: 150
        },
        {
            title: "Pente",
            dataIndex: 'pente',
            key: 'pente',
            render: () => objectif.pente === "ascendante" ? <RiseOutlined style={{ fontSize: "32px", color: "#1D7C55" }} /> : <FallOutlined style={{ fontSize: "32px", color: "red" }} />,
            width: 100
        }
    ];

    return (
        <Table scroll={{ x: 1300, y: 500 }}
            columns={columns}
            dataSource={affectationEntites}
            rowKey="uid"
            pagination={false} />
    );
}

function GeneralInfo({ affectationObjectif }) {
    const { check } = useUserContext();
    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [currentAffectationObjectif, setCurrentAffectationObjectif] = React.useState(affectationObjectif);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
    }, [currentAffectationObjectif]);

    const setItemToEdit = (affectationObjectif) => {
        setEditModalVisible(true);
    };

    const handleAffectationOjbectifUpdate = (affectationObjectif) => {
        setCurrentAffectationObjectif(affectationObjectif);
        setEditModalVisible(false);
    }

    const setItemToRemove = (affectationObjectif) => {
        setIsDeleting(true);
        AffectationObjectifService.getInstance()
            .remove(affectationObjectif.uid)
            .then(() => {
                navigate(`/parametrage/organisation/${affectationObjectif.affectable.uid}`);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsDeleting(false);
            });
    }

    return (
        <Card classNames={{ body: 'px-0' }} className={styles.generalInfoCard} extra={
            <>
                <Space>
                    {check('EDIT-ORGANIZATION-OBJECTIVES') ? <Button onClick={() => setItemToEdit(affectationObjectif)} className="btn btn-warning" title="Modifier" icon={<EditOutlined />}></Button> : null}
                    {check('REMOVE-ORGANIZATION-OBJECTIVES') ?
                        <Popconfirm okType='danger' title="Voulez-vous vraiment supprimer cette affectation d'objectif?" onConfirm={() => setItemToRemove(affectationObjectif)} okText="Confirmer" cancelText="Annuler">
                            <Button loading={isDeleting}
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                className="btn btn-danger"
                            ></Button>
                        </Popconfirm> : null}
                </Space>
            </>
        }>
            <AffectationObjectifEdit onUpdate={handleAffectationOjbectifUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} uid={affectationObjectif.uid} />
            <Title level={4}>Informations générales</Title>
            <Descriptions bordered column={1} size="small">
                <Descriptions.Item label="Intitulé">{currentAffectationObjectif?.objectif?.intitule}</Descriptions.Item>
                <Descriptions.Item label="Indicateur">{currentAffectationObjectif?.objectif?.indicateur}</Descriptions.Item>
                <Descriptions.Item label="Description">{currentAffectationObjectif?.objectif?.description}</Descriptions.Item>
                <Descriptions.Item label="Organisation">{currentAffectationObjectif?.affectable?.nom}</Descriptions.Item>
                <Descriptions.Item label="Date affectation">
                    <Moment format="DD/MM/YYYY">{currentAffectationObjectif?.created_at}</Moment>
                </Descriptions.Item>
                <Descriptions.Item label="Date délai">
                    <Moment format="DD/MM/YYYY">{currentAffectationObjectif?.date_delai}</Moment>
                </Descriptions.Item>
                {currentAffectationObjectif?.directe ? <Descriptions.Item label="Valeur cible">{currentAffectationObjectif?.valeur_cible} {currentAffectationObjectif?.objectif?.unite}</Descriptions.Item> : null}
            </Descriptions>
        </Card>
    );
}

function RealisationTable({ objectifRealises, setObjectifRealises, objectifRealisesLoading, loadMore, orPaginationData, check }) {
    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [selectedUid, setSelectedUid] = React.useState(null);

    const handleDelete = (uid) => {
        ObjectifRealiseService.getInstance()
            .remove(uid)
            .then(() => {
                message.success('Objectif réalisé supprimé avec succès');
                setObjectifRealises(prev => prev.filter(item => item.uid !== uid));
            }).catch(error => {
                catchError(error);
                message.error('Erreur lors de la suppression de l\'objectif réalisé');
            });
    };

    const columns = [
        {
            title: 'Date Réalisation',
            dataIndex: 'date_realisation',
            key: 'date_realisation',
            render: (date) => <Moment format="DD/MM/YYYY">{date}</Moment>
        },
        {
            title: 'Valeur Réelle',
            dataIndex: 'valeur_reelle',
            key: 'valeur_reelle',
            render: (text, record) => `[${record.affectation_entite?.entite?.nom ?? ""}] - ${text} ${record.unite}`
        },
        {
            title: 'Remarque',
            dataIndex: 'remarque',
            key: 'remarque',
            render: (text) => text || "Aucune remarque"
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space size="middle">
                    {check('EDIT-OBJECTIVE-KPI') ? <Button type="primary" className="btn-warning" icon={<EditFilled />} onClick={() => handleEdit(record)}></Button> : null}
                    <Popconfirm
                        title="Êtes-vous sûr de vouloir supprimer cet objectif ?"
                        onConfirm={() => handleDelete(record.uid)}
                        okText="Supprimer"
                        cancelText="Annuler" okType="danger"
                    >
                        <Button type="primary" danger icon={<DeleteFilled />}></Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    const handleEdit = (record) => {
        setSelectedUid(record.uid);
        setEditModalVisible(true);
    }

    const handleObjectifRealiseUpdate = (objectifRealise) => {
        setObjectifRealises(objectifRealises.map(or => or.uid === objectifRealise.uid ? objectifRealise : or));
        setEditModalVisible(false);
    }

    return (
        <>
            {objectifRealisesLoading ? (
                <Spin size="large" />
            ) : (
                <>
                    <ObjectifRealiseEdit onUpdate={handleObjectifRealiseUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} uid={selectedUid} />
                    {check('VIEW-OBJECTIVE-KPI') ? (
                        <Card title="Réalisations" className={styles.realisationTableCard}>
                            <Table
                                columns={columns}
                                dataSource={objectifRealises}
                                rowKey="uid"
                                pagination={false}
                            />
                            <PaginationInfo loadedCount={objectifRealises.length} paginationData={orPaginationData} />
                            {orPaginationData?.next_page_url && (
                                <div style={{ textAlign: 'center', marginTop: 16 }}>
                                    <Button onClick={loadMore}>Charger plus</Button>
                                </div>
                            )}
                        </Card>
                    ) : null}
                </>
            )}
        </>
    );
}


export default function AffectationObjectifDetailsPage() {
    const { uid } = useParams();
    const [affectationObjectif, setAffectationObjectif] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [objectifRealises, setObjectifRealises] = React.useState([]);
    const [orPaginationData, setOrPaginationData] = React.useState(null);
    const [objectifRealisesLoading, setObjectifRealisesLoading] = React.useState(false);
    const { check } = useUserContext();

    React.useEffect(() => {
        if (!check('VIEW-ORGANIZATION-OBJECTIVES')) return;
        setLoading(true);
        AffectationObjectifService.getInstance()
            .find(uid)
            .then(response => {
                setAffectationObjectif(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [uid, check]);

    React.useEffect(() => {
        if (!check('VIEW-OBJECTIVE-KPI')) return;
        setObjectifRealisesLoading(true);
        ObjectifRealiseService.getInstance()
            .findByAffectation(uid)
            .then(response => {
                setOrPaginationData(response.data);
                setObjectifRealises(response.data.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setObjectifRealisesLoading(false);
            });
    }, [uid, check]);

    const loadMore = () => {
        if (!orPaginationData || !orPaginationData.next_page_url) return;
        ObjectifRealiseService.get(orPaginationData.next_page_url)
            .then(response => {
                setOrPaginationData(response.data);
                setObjectifRealises([...objectifRealises, ...response.data.data]);
            }).catch(error => {
                console.error(error);
            });
    };

    const CONTENT = (
        <React.Fragment>
            {(loading || !affectationObjectif) ? <Spin size="large" /> : (
                <Card>
                    <Title level={3}>Détails de l'affectation d'objectif</Title>
                    <Divider />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={16}>
                            <GeneralInfo affectationObjectif={affectationObjectif} />
                        </Col>
                        <Col xs={24} lg={8}>
                            <Card className={styles.progressCard}>
                                <ProgressChart percentage={affectationObjectif.progression} />
                            </Card>
                        </Col>
                    </Row>

                    {affectationObjectif?.affectation_entites?.length > 0 ? (
                        <>
                            <Divider />
                            <Title level={4}>Suivi de la répartition des objectifs</Title>
                            <Tabs defaultActiveKey="entite" items={[
                                {
                                    key: 'entite',
                                    label: 'Suivi',
                                    children: <Card>
                                        <AffectationEntiteList affectationEntites={affectationObjectif.affectation_entites} objectif={affectationObjectif.objectif} />
                                    </Card>
                                },
                                {
                                    key: 'evolution',
                                    label: "Diagramme d'évolution",
                                    children: <EntiteAffectationObjectifsEvolutionsChart affectationObjectif={affectationObjectif} />
                                }
                            ]} />
                        </>
                    ) : null}
                    <Divider />
                    {check('VIEW-OBJECTIVE-KPI') ?
                        <Tabs defaultActiveKey="realisation" items={[
                            {
                                key: 'realisation',
                                label: 'Réalisations',
                                children: <RealisationTable
                                    objectifRealises={objectifRealises}
                                    setObjectifRealises={setObjectifRealises}
                                    objectifRealisesLoading={objectifRealisesLoading}
                                    loadMore={loadMore}
                                    orPaginationData={orPaginationData}
                                    check={check}
                                />
                            },
                            affectationObjectif?.directe && {
                                key: 'progression',
                                label: "Courbe de progression",
                                children: <Card>
                                    <AffectationDirecteObjectifsEvolutions affectationObjectif={affectationObjectif} />
                                </Card>
                            }
                        ]} />
                        : null}
                </Card>
            )}
        </React.Fragment>
    );

    return (
        <Main>
            {check('VIEW-ORGANIZATION-OBJECTIVES') ? CONTENT : <UnauthorizedMessage />}
        </Main>
    );
}
