import { Card, Col, Divider, Progress, Row, Tabs} from "antd";
import Main from "../../components/layout/Main";
import UnauthorizedMessage from "../../components/utils/UnauthorizedMessage";
import { useUserContext } from "../../providers/UserProvider";
import React from "react";
import { useParams } from "react-router-dom";
import MissionControleService from "../../services/MissionControleService";
import { catchError } from "../../services/DaoService";
import MissionControleInfo from "../../components/missioncontrole/MissionControleInfo";
import AffectationMissionList from "../../components/affectationmission/AffectationMissionList";
import PieceJointeList from "../../components/piecejointe/PieceJointeList";
import PieceJointeService from "../../services/PieceJointeService";

export default function MissionControleDetailsPage() {

    const { uid } = useParams();
    const { check } = useUserContext();
    const [loading, setLoading] = React.useState(true);
    const [missionControle, setMissionControle] = React.useState(null);

    React.useEffect(() => {
        setLoading(true);
        MissionControleService.getInstance()
            .findByMissionControle(uid).then(response => {
                setMissionControle(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [uid]);

    const handleMissionControleUpdate = (missionControle) => {
        setMissionControle(missionControle);
    }

    const tabItems = [
        check('VIEW-AUDIT-MISSION') && {
            key: "objectifs",
            label: "Objectifs affectés",
            children: <>
                {missionControle ? <AffectationMissionList missionControle={missionControle} />
                    : null}
            </>
        },
        check('VIEW-AUDIT-MISSION-ATTACHMENTS') && {
            key: "attachments",
            label: "Pièces jointes",
            children: <>
                {missionControle ? <PieceJointeList canSee={check('VIEW-AUDIT-MISSION-ATTACHMENTS')}
                    canAdd={check('ADD-AUDIT-MISSION-ATTACHMENTS')} candRemove={check('DELETE-AUDIT-MISSION-ATTACHMENTS')}
                    parentType={PieceJointeService.TYPES.MISSIONCONTROLE} parentId={missionControle?.id} /> : null}
            </>
        }
    ]

    return (
        <Main>
            {!!check('VIEW-AUDIT-MISSION') ? (
                <Card loading={loading} style={{ margin: '0px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
                    <Card.Meta title={`Mission Contrôle : ${missionControle?.nom}`} />
                    <Divider />
                    {missionControle && (
                        <>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    <MissionControleInfo missionControle={missionControle} onUpdate={handleMissionControleUpdate} />
                                </Col>
                                <Col xs={24} md={12} className="d-flex align-items-stretch">
                                    <Card title='Statut validation' className="d-flex flex-column justify-content-center" style={{ width: '100%', height: '100%' }}>
                                        <Row className="flex-grow-1">
                                            <Col xs={24} className="d-flex justify-content-center align-items-center" style={{ flexGrow: 1 }}>
                                                {missionControle?.done ?
                                                    <Progress size={200} type="circle" percent={100} />
                                                    : <Progress size={200} type="circle" percent={100} status="exception" />
                                                }
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Card classNames={{ body: 'p-1' }} >
                                <Tabs items={tabItems} />
                            </Card>
                        </>
                    )}
                </Card>

            ) : <UnauthorizedMessage />}
        </Main>
    )
}