import { Button, Card, Popconfirm, Space, Tag, Typography } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditFilled } from "@ant-design/icons";
import { useUserContext } from "../../providers/UserProvider";
import styles from './MissionControleInfo.module.scss';
import React from "react";
import Moment from "react-moment";
import MissionControleEdit from "./MissionControleEdit";
import MissionControleService from "../../services/MissionControleService";
import { useNavigate } from "react-router-dom";
import { catchError } from "../../services/DaoService";

const { Title, Text } = Typography;

export default function MissionControleInfo({ missionControle, onUpdate }) {

    const { check } = useUserContext();
    const navigate = useNavigate();
    const [getMissionControle, setGetMissionControle] = React.useState(missionControle);
    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);

    const handleMissionControleUpdate = (missionControle) => {
        setGetMissionControle(missionControle);
        onUpdate(missionControle);
        setEditModalVisible(false);
    }

    const setItemToEdit = (missionControle) => {
        setEditModalVisible(true);
    }

    const removeMissionControle = (missionControle) => {
        setIsDeleting(true);
        MissionControleService.getInstance()
          .remove(missionControle.uid)
          .then(() => {
            navigate(`/objectif/mission-controle`);
          }).catch(error => {
            catchError(error);
          }).finally(() => {
            setIsDeleting(false);
          });
      }

    return (
        <Card className={styles.card} title="Détails" bordered
            extra={
                <>
                    <MissionControleEdit onUpdate={handleMissionControleUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} missionControleUid={getMissionControle.uid} />
                    <Space>
                        {check('EDIT-AUDIT-MISSION') ? <Button onClick={() => setItemToEdit(getMissionControle)} icon={<EditFilled />} className="btn btn-warning"></Button> : null}
                        {check('DELETE-AUDIT-MISSION') ?
                            <Popconfirm  okType='danger' title="Voulez-vous vraiment supprimer cette mission contrôle?" okText="Confirmer" onConfirm={() => removeMissionControle(missionControle)} cancelText="Annuler">
                                <Button loading={isDeleting}
                                    type="primary"
                                    danger
                                    icon={<DeleteOutlined />}
                                    className="btn btn-danger"
                                ></Button>
                            </Popconfirm>
                            : null}
                    </Space>
                </>
            }>
            <div className={styles.info}>
                <div className={styles.item}>
                    <Title level={5} className={styles.label}>Numéro</Title>
                    <Text>{getMissionControle.numero}</Text>
                </div>
                <div className={styles.item}>
                    <Title level={5} className={styles.label}>Responsable</Title>
                    <Text>{getMissionControle.responsable}</Text>
                </div>
                <div className={styles.item}>
                    <Title level={5} className={styles.label}>Date Affectation</Title>
                    <Moment format="DD/MM/YYYY">{getMissionControle.date_affectation}</Moment>
                </div>
                <div className={styles.item}>
                    <Title level={5} className={styles.label}>Delai Affectation</Title>
                    <Moment format="DD/MM/YYYY">{getMissionControle.date_delai}</Moment>
                </div>
                <div className={styles.item}>
                    <Title level={5} className={styles.label}>Description</Title>
                    <Text>{getMissionControle.description ?? 'NA'}</Text>
                </div>
                <div className={styles.item}>
                    <Title level={5} className={styles.label}>Observations</Title>
                    <Text>{getMissionControle.observations ?? 'NA'}</Text>
                </div>
                <div className={styles.item}>
                    <Title level={5} className={styles.label}>Statut</Title>
                    {getMissionControle.enabled ? (
                        <Tag className={`${styles.tag} ${styles.active}`} icon={<CheckCircleOutlined />} color="success">Actif</Tag>
                    ) : (
                        <Tag className={`${styles.tag} ${styles.inactive}`} icon={<CloseCircleOutlined />} color="error">Inactif</Tag>
                    )}
                </div>
            </div>
        </Card >

    )
}