import React from "react";
import Main from "../../components/layout/Main";
import { Card, Col, Divider, Row, Tabs } from "antd";
import { useParams } from "react-router-dom";
import ObjectifService from "../../services/ObjectifService";
import { catchError } from "../../services/DaoService";
import ObjectifInfo from "../../components/objectif/ObjectifInfo";
import UnauthorizedMessage from "../../components/utils/UnauthorizedMessage";
import { useUserContext } from "../../providers/UserProvider";
import OrganisationObjectifList from "../../components/objectif/OrganisationObjectifList";
import ProgressChart from "../../charts-model/ProgressChart";
import SousObjectifList from "../../components/objectif/SousObjectifList";

export default function ObjectifDetailsPage() {
    const { uid } = useParams();
    const [objectif, setObjectif] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const { check } = useUserContext();

    React.useEffect(() => {
        setLoading(true);
        ObjectifService.getInstance()
            .find(uid)
            .then(response => {
                setObjectif(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [uid]);

    const tabItems = [
        check('VIEW-ORGANIZATION-OBJECTIVES') && {
            key: "objectifs",
            label: "Objectifs affectés",
            children: <>
                <OrganisationObjectifList objectif={objectif} />
            </>
        },
        {
            key: "children",
            label: "Sous objectifs",
            children: <SousObjectifList parentId={objectif?.id} />
        },
    ]


    return (
        <Main>
            {!!check('VIEW-OBJECTIVE') ? (
                <Card loading={loading} style={{ margin: '0px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
                    <Card.Meta title={`Objectif: ${objectif?.intitule}`} />
                    <Divider />
                    {objectif && (
                        <Row>
                            <Col xs={24} md={12}>
                                <ObjectifInfo objectif={objectif} />
                            </Col>
                            <Col sm={24} md={12}>
                                <ProgressChart label="Atteinte globale" percentage={objectif.progression} />
                            </Col>
                        </Row>
                    )}
                    <Card classNames={{ body: 'p-1' }}>
                        <Tabs items={tabItems} />
                    </Card>
                </Card>
            ) : <UnauthorizedMessage />}

        </Main>
    );
}