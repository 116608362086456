import React from "react";
import { Form, Input, Select, Button, Row, Col, Modal, Space, Checkbox, Typography, Card } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import OrganisationService from "../../services/OrganisationService";
import { catchError } from "../../services/DaoService";
import AffectationObjectifService from "../../services/AffectationObjectifService";
import MissionControleService from "../../services/MissionControleService";
import Toast from "../../helpers/Toast";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const { Title } = Typography;

export default function MissionControleNew({ visible, onCancel, onCreate }) {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [organisations, setOrganisations] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loadingNextSttep, setLoadingNextSttep] = React.useState(false);
    const [step, setStep] = React.useState(1);
    const [selectedOrganisationIds, setSelectedOrganisationIds] = React.useState([]);
    const [selectedOrganisationData, setSelectedOrganisationData] = React.useState([]);
    const [creating, setCreating] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        OrganisationService.getInstance().all()
            .then(response => {
                setOrganisations(response.data);
            })
            .catch(error => {
                catchError(error);
            }).finally(() => setLoading(false));
    }, []);

    const gotoStep2 = () => {
        setLoadingNextSttep(true);
        form.validateFields().then(values => {
            AffectationObjectifService.getInstance().findByOrganisations(selectedOrganisationIds)
                .then(response => {
                    setSelectedOrganisationData(response.data);
                    setStep(2);
                    setLoadingNextSttep(false);
                }).catch(error => {
                    console.log(error);
                });
        }).catch(error => {
            setLoadingNextSttep(false)
            console.log(error);
        });
    }

    React.useEffect(() => {
        setStep(1);
    }, [selectedOrganisationIds]);

    const gotoMissionControleDetails = (missionControle) => {
        navigate(`/objectif/mission-controle/${missionControle.uid}`);
    }
    const handleSave = (isRedirection) => {
        setCreating(true);
        form.validateFields().then(values => {
            MissionControleService.getInstance()
                .create(values)
                .then(response => {
                    Toast.success(response.message);
                    form.resetFields();
                    onCreate(response.data);
                    setStep(1);
                    setSelectedOrganisationIds(null);
                    setSelectedOrganisationData(null);
                    if (isRedirection) {
                        gotoMissionControleDetails(response.data);
                    }
                }).catch(error => {
                    catchError(error);
                }).finally(() => {
                    setCreating(false);
                });
        }).catch(info => {
            console.log('Validation Failed:', info);
            setCreating(false);
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <Modal title="Nouvelle mission de contrôle" open={visible} footer={null} onCancel={onCancel} width={"50%"}>
            <Card loading={loading}>
                <Form form={form} layout="vertical" onFinishFailed={onFinishFailed}>
                    <Row>
                        <Col className="gutter-row" span={24} >
                            <Form.Item
                                label="Nom"
                                name="nom"
                                rules={[
                                    { required: true, message: 'le nom est obligatoire!' },
                                    { min: 3, message: 'Le nom doit contenir au moins 3 caractères!' }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24} >
                            <Form.Item
                                label="Responsable"
                                name="responsable"
                                rules={[
                                    { required: true, message: 'le responsabe est obligatoire!' },
                                    { min: 3, message: 'Le nom doit contenir au moins 3 caractères!' }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={12} >
                            <Form.Item
                                label="Date affectation"
                                name="date_affectation"
                                rules={[{ required: true }]}>
                                <Input type="date" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12} >
                            <Form.Item
                                label="Delai affectation"
                                name="date_delai"
                                rules={[{ required: true }]}>
                                <Input type="date" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="gutter-row" span={24} >
                            <Form.Item
                                name="organisations"
                                label="Organisations"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez sélectionner au moins une organisation !',
                                        type: 'array',
                                    },
                                ]}
                            >
                                <Select value={selectedOrganisationIds} mode="multiple" placeholder="Veuillez sélectionner les organisations concernées"
                                    onChange={(value) => setSelectedOrganisationIds(value)}>
                                    {organisations.map(organisation => (
                                        <Option disabled={organisation.affectation_objectifs_count === 0} key={organisation.uid} value={organisation.id}>
                                            {organisation.nom}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {step === 2 ? <Row>
                        <Col className="gutter-row" span={24} >
                            <Form.Item
                                name="affectation_objectif_ids"
                                label={<span className="font-semibold text-lg ">Objectifs à contrôler</span>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez sélectionner au moins une organisation !',
                                        type: 'array',
                                    },
                                ]}
                            >
                                <Checkbox.Group>
                                    {
                                        <Row>

                                            {selectedOrganisationData.map((organisationData) => (
                                                <React.Fragment key={organisationData.organisation.id}>
                                                    <Col className="gutter-row" span={24} >
                                                        <Title level={5}>{organisationData.organisation.nom}</Title>
                                                    </Col>

                                                    {
                                                        <Row>
                                                            {organisationData.affectations.map((affectation, index) => {
                                                                return (
                                                                    <Col className="gutter-row" span={24} >
                                                                        <Checkbox key={affectation.uid} value={affectation.id}>{affectation.objectif.intitule
                                                                        }</Checkbox>
                                                                    </Col>
                                                                )
                                                            })
                                                            }
                                                        </Row>
                                                    }

                                                </React.Fragment>
                                            ))
                                            }
                                        </Row>
                                    }
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row> : null}
                    <Row>
                        <Col span={24}>
                            <Form.Item>
                                <Space>
                                    <Button onClick={onCancel}>Annuler</Button>
                                    {step === 1 ? <Button type="primary" htmlType="button" onClick={gotoStep2} loading={loadingNextSttep} icon={<ArrowRightOutlined />}>Continuer</Button> :
                                        <>
                                            <Button type="primary" htmlType="submit" loading={creating} onClick={() => handleSave(true)}>Enregistrer et Afficher</Button>
                                            <Button type="primary" htmlType="submit" loading={creating} onClick={() => handleSave(false)}>Enregistrer et Créer</Button>
                                        </>}
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Modal >
    )

}