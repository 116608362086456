import React from "react";
import DashboardConfigService from "../../services/DashboardConfigService";
import { catchError } from "../../services/DaoService";
import { Card, Spin, Typography } from "antd";
import Chart from "react-apexcharts";

const { Title } = Typography;

const DonutChart = ({ data }) => {
  const options = {
    chart: {
      type: 'donut',
    },
    labels: data.map(item => item.objectif),
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
  };
  const series = data.map(item => item.progression);

  return (
    <div id="chart">
      <Chart options={options} series={series} type="donut" width={510} />
    </div>
  );
};

export default function ObjectifProgressionsGlobalChart() {

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    DashboardConfigService.getInstance()
      .getObjectifProgressionsGlobal()
      .then((response) => {
        setData(response.data);
      }).catch(err => catchError(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Card loading={loading} height={500} style={{ borderRadius: '0' }} title={
      <Title className="text-center" P level={4}>Évolution globale des objectifs</Title>
    }>
      {loading ? <Spin size="large" /> : (
        <DonutChart data={data} />)}
    </Card>
  );
}