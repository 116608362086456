import React from "react";
import Main from "../../components/layout/Main";
import OrganisationList from "../../components/organisation/OrganisationList";


export default function OrganisationListPage() {
    const [loading, setLoading] = React.useState(true);
    const [paginationData, setPaginationData] = React.useState(null);

    return (
        <Main>
            <OrganisationList loading={loading} paginationData={paginationData} setLoading={setLoading} setPaginationData={setPaginationData} />
        </Main>
    );

} 