import React from "react";
import DashboardService from "../../services/DashboardService";
import { catchError } from "../../services/DaoService";
import styles from './OrganisationEvolutionChart.module.scss';
import { Card, Table, Typography, Tabs, Spin, Progress } from "antd";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import { useUserContext } from "../../providers/UserProvider";
import EntiteAffectationObjectifsEvolutionsDonut from "./EntiteAffectationObjectifsEvolutionsDonut";

const { Title } = Typography;

// Composant pour le diagramme
const ChartComponent = ({ data }) => {
    const options = {
        chart: {
            type: 'bar',
            height: 500,
            stacked: true,
            toolbar: {
                show: true, 
            },
            zoom: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10
            }
        },
        xaxis: {
            categories: data.map(item => item.sigle),
            labels: {
                style: {
                    colors: Array(data.length).fill('#000'),
                    fontSize: '12px',
                    fontWeight: 700,
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 700,
                },
            },
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        },
        colors: ['#2F863D', '#D9363E']
    };

    const series = [
        {
            name: 'Objectifs atteints',
            data: data.map(item => item.objectifs_atteints)
        },
        {
            name: 'Objectifs non atteints',
            data: data.map(item => item.objectifs - item.objectifs_atteints)
        }
    ];

    return (
        <div className={styles.scrollContainer}>
            <Chart options={options} series={series} type="bar" height={550} />
        </div>
    );
};

// Composant pour le tableau
const TableComponent = ({ data }) => {
    const { check } = useUserContext();

    const columns = [
        {
            // row number
            title: 'N°',
            dataIndex: 'rowNumber',
            key: 'rowNumber',
            render: (text, record, index) => index + 1,
            width: 20,
            fixed: 'left'
        },
        {
            title: 'Organisation',
            dataIndex: 'organisation',
            key: 'organisation',
            width: 200,
            // afficher le lien si l'utilisateur a le droit de voir les détails de l'organisation
            render: (value, record) => <strong>
                {check('VIEW-ORGANIZATION') ? <Link style={{color: "#000"}} to={`/parametrage/organisation/${record.uid}`}>{value}</Link> : value}
            </strong>,
            ellipsis: true,
        },
        {
            title: "Objectifs Atteints (%)",
            dataIndex: "objectifs_atteints_percentage",
            key: "objectifs_atteints_percentage",
            render: (value) => <Progress strokeWidth={15} strokeColor={"#2F863D"} trailColor="#F8EF41" size={"default"} percent={value} showInfo={false} />,
            width: 200
        },
        {
            title: 'Objectifs Affectés',
            dataIndex: 'objectifs',
            key: 'objectifs',
            width: 60
        },
        {
            title: 'Objectifs Atteints',
            dataIndex: 'objectifs_atteints',
            key: 'objectifs_atteints',
            width: 60
        }
    ];

    return (
        <Table scroll={{ x: 1300, y: 500 }} dataSource={data} columns={columns} pagination={false} rowKey="organisation" />
    );
};

// Composant global
const OrganisationEvolutionChart = () => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        DashboardService.getInstance()
            .getOrganisationEvolutions()
            .then((response) => {
                setData(response.data);
                setLoading(false);
            }).catch(err => catchError(err))
            .finally(() => setLoading(false));
    }, []);

    const tabItems = [
        {
            key: 'chart',
            label: 'Diagramme de progression',
            children: <ChartComponent data={data} />
        },
        {
            key: 'table',
            label: 'Tableau de progression',
            children: <TableComponent data={data} />
        },
        {
            key: 'repartition',
            label: 'Répartition des objectifs',
            children: <EntiteAffectationObjectifsEvolutionsDonut data={data} />
        }
    ]

    return (
        <Card loading={loading} classNames={{body: 'p-0'}} className={styles.chartCard} title={
            <Title level={4}>Progression des Objectifs par Organisation</Title>
        }>
            {loading ? <Spin size="large" /> : (
                <Tabs defaultActiveKey="chart" items={tabItems} />
            )}
        </Card>
    );
};

export default OrganisationEvolutionChart;
