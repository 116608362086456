/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";
import { DashboardOutlined, PieChartOutlined, SettingOutlined } from "@ant-design/icons";
import { useUserContext } from "../../providers/UserProvider";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  const [visible, setVisible] = useState(false);
  const [sidenavColor, ] = useState("#d9363e");
  const [sidenavType, ] = useState("transparent");
  const [fixed, ] = useState(true);
  const [menuItems, setMenuItems] = React.useState([]);
  const { check } = useUserContext();
  const { pathname } = useLocation();
  const page = pathname.replace("/", "").split("/")[0];

  React.useEffect(() => {
    let menuItemData = [
        {
            key: "dashboard",
            label: (
                <NavLink to="/dashboard">
                    <span className={``}>
                        <DashboardOutlined />
                    </span>
                    <span className={``}>Tableau de bord</span>
                </NavLink>
            ),
            path: "/dashboard",
            visible: check("VIEW-DASHBOARD"),
        },
        {
            key: "parametrage",
            icon: (
                <span className={``}>
                    <SettingOutlined />
                </span>
            ),
            label: "Paramétrage",
            visible: false,
            children: [
                {
                    key: 'groupes',
                    label: <NavLink to="/parametrage/groupe">Groupes d'utilisateurs</NavLink>,
                    path: '/parametrage/groupe',
                    visible: check("VIEW-GROUPS"),
                },
                {
                    key: 'users',
                    label: <NavLink to="/parametrage/user">Utilisateurs</NavLink>,
                    path: '/parametrage/user',
                    visible: check("VIEW-USERS"),
                },
                {
                    key: "organisation",
                    label: <NavLink to="/parametrage/organisation">Organisations</NavLink>,
                    path: "/parametrage/organisation",
                    visible: check("VIEW-ORGANIZATIONS"),
                }
            ],
        },
        {
            key: "objectif",
            icon: (
                <span className={``}>
                    <PieChartOutlined />
                </span>
            ),
            label: "Gestion des objectifs",
            visible: false,
            children: [
                {
                    key: "objectif-list",
                    label: <NavLink to="/objectif/list">Paramétrage des Objectifs</NavLink>,
                    path: "/objectif/list",
                    visible: check("VIEW-OBJECTIVES"),
                },
                {
                    key: "alimenter-kpi",
                    label: <NavLink to="/objectif/kpi-alimentation">Alimentation des KPI</NavLink>,
                    path: "/objectif/kpi-alimentation",
                    visible: check("FEED-OBJECTIVE-KPI"),
                },
                {
                    key: "mission-controle",
                    label: <NavLink to="/objectif/mission-controle"> Missions de contrôle</NavLink>,
                    path: "/objectif/mission-controle",
                    visible: check("VIEW-AUDIT-MISSIONS"),
                },
            ],
        },
    ];
    // filtrer les éléments visibles
    menuItemData.forEach((item) => {
        if (item.children) {
            item.children = item.children.filter((child) => child.visible);
            item.visible = item.children.length > 0;
        }
    });
    // filtrer les items visibles
    menuItemData = menuItemData.filter((item) => item.visible);

    setMenuItems(menuItemData);
}, [check, page]);


  const openDrawer = () => setVisible(!visible);

  // pathname = pathname.replace("/", "");

  // useEffect(() => {
  //   if (pathname === "rtl") {
  //     setPlacement("left");
  //   } else {
  //     setPlacement("right");
  //   }
  // }, [pathname]);

  return (
    <Layout
      className={`layout-dashboard ${
        pathname === "profile" ? "layout-profile" : ""
      } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
    >
      <Drawer
        title={false}
        placement="left"
        closable={false}
        onClose={() => setVisible(false)}
        open={visible}
        key={"left"}
        width={300}
        className={`drawer-sidebar ${
          pathname === "rtl" ? "drawer-sidebar-rtl" : ""
        } `}
      >
        <Layout
          className={`layout-dashboard ${
            pathname === "rtl" ? "layout-dashboard-rtl" : ""
          }`}
        >
          <Sider
            trigger={null}
            width={300}
            theme="light"
            className={`sider-primary ant-layout-sider-primary ${
              sidenavType === "#fff" ? "active-route" : ""
            }`}
            style={{ background: sidenavType }}
          >
            <Sidenav color={sidenavColor} menuItems={menuItems} page={page} />
          </Sider>
        </Layout>
      </Drawer>
      {/* <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(collapsed, type) => {}}
        trigger={null}
        width={300}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${
          sidenavType === "#fff" ? "active-route" : ""
        }`}
        style={{ background: sidenavType }}
      >
        <Sidenav color={sidenavColor} />
      </Sider> */}
      <Layout>
        {fixed ? (
          <Affix>
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header menuItems={menuItems} page={page}
                onPress={openDrawer}
              />
            </AntHeader>
          </Affix>
        ) : (
          <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
            <Header
              onPress={openDrawer}
            />
          </AntHeader>
        )}
        <Content className="content-ant">{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

export default Main;
