import React from 'react';
import { Card, Typography, Tag, Popconfirm, Button, Space } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditFilled } from '@ant-design/icons';
import styles from './ObjectifInfo.module.scss';
import { useUserContext } from '../../providers/UserProvider';
import { useNavigate } from 'react-router-dom';
import { catchError } from '../../services/DaoService';
import ObjectifService from '../../services/ObjectifService';
import ObjectifEdit from './ObjectifEdit';

const { Title, Text } = Typography;
const ObjectifInfo = ({ objectif }) => {
  const { check } = useUserContext();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const navigate = useNavigate();
  const [editModalVisible, setEditModalVisible] = React.useState(false);
  const [selectedUid, setSelectedUid] = React.useState(null);
  const [getObjectif, setGetObjectif] = React.useState(objectif);


  const removeObjectif = (objectif) => {
    setIsDeleting(true);
    ObjectifService.getInstance()
      .remove(objectif.uid)
      .then(() => {
        navigate(`/objectif/list`);
      }).catch(error => {
        catchError(error);
      }).finally(() => {
        setIsDeleting(false);
      });
  }
  const setItemToEdit = (objectif) => {
    setSelectedUid(objectif.uid);
    setEditModalVisible(true);
  }
  const handleOjbectifUpdate = (objectif) => {
    setGetObjectif(objectif);
    setEditModalVisible(false);
  }
  return (
    <Card className={styles.card} title="Détails" bordered
      extra={
        <>
          <Space>
            {check('EDIT-OBJECTIVE') ? <Button onClick={() => setItemToEdit(getObjectif)} icon={<EditFilled />} className="btn btn-warning"></Button> : null}
            {check('DELETE-OBJECTIVE') ?
              <Popconfirm okType='danger' title="Voulez-vous vraiment supprimer cet objectif?" onConfirm={() => removeObjectif(objectif)} okText="Confirmer" cancelText="Annuler">
                <Button loading={isDeleting}
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  className="btn btn-danger"
                ></Button>
              </Popconfirm>
              : null}
          </Space>

        </>
      }>
      <ObjectifEdit onUpdate={handleOjbectifUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} uid={selectedUid} />      <div className={styles.info}>
        <div className={styles.item}>
          <Title level={5} className={styles.label}>Indicateur</Title>
          <Text>{getObjectif.indicateur}</Text>
        </div>
        <div className={styles.item}>
          <Title level={5} className={styles.label}>Unité</Title>
          <Text>{getObjectif.unite}</Text>
        </div>
        <div className={styles.item}>
          <Title level={5} className={styles.label}>Pente</Title>
          <Text>{getObjectif.pente}</Text>
        </div>
        <div className={styles.item}>
          <Title level={5} className={styles.label}>Description</Title>
          <Text>{getObjectif.description ?? 'NA'}</Text>
        </div>
        <div className={styles.item}>
          <Title level={5} className={styles.label}>Statut</Title>
          {getObjectif.enabled ? (
            <Tag className={`${styles.tag} ${styles.active}`} icon={<CheckCircleOutlined />} color="success">Actif</Tag>
          ) : (
            <Tag className={`${styles.tag} ${styles.inactive}`} icon={<CloseCircleOutlined />} color="error">Inactif</Tag>
          )}
        </div>
      </div>
    </Card>
  );
}

export default ObjectifInfo;