import { DaoService } from "./DaoService";

export default class MissionControleService extends DaoService{

    static getInstance() {
        return super.getInstance('missioncontrole');
    }

    all() {
        return MissionControleService.get(`${this.prefix}/all`);
    }

    findByMissionControle(missionControleId) {
        return MissionControleService.get(`${this.prefix}/${missionControleId}`);
    }

    search(data) {
        return MissionControleService.post(`${this.prefix}/search`, data);
    }

    enable(idOrUid) {
        return MissionControleService.post(`${this.prefix}/${idOrUid}/enable`);
    }
    disable(idOrUid, data) {
        return MissionControleService.post(`${this.prefix}/${idOrUid}/disable`, data);
    }
}