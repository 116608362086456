import React from "react";
import DashboardConfigService from "../../services/DashboardConfigService";
import { catchError } from "../../services/DaoService";
import { Card, Spin, Typography } from "antd";
import Chart from "react-apexcharts";

const { Title } = Typography;

const DonutChart = ({ data }) => {
    const options = {
        chart: {
            type: 'donut',
        },
        labels: data.map(item => item.sigle),
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
    };

    const series = data.map(item => item.objectifs_atteints_percentage);

    return (
        <div id="chart">
            <Chart options={options} series={series} type="donut" width={500} />
        </div>
    );
};

export default function OrganisationPerformanceGlobalChart() {

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        DashboardConfigService.getInstance()
            .getOrganisationPerformanceGlobal()
            .then((response) => {
                setData(response.data);
            }).catch(err => catchError(err))
            .finally(() => setLoading(false));
    }, []);

    return (
        <Card loading={loading} height={500} style={{ borderRadius: '0' }} title={
            <Title className="text-center" level={4}>Performance globale des organisations principales </Title>
        }>
            {loading ? <Spin size="large" /> : (
                <DonutChart data={data} />)}
        </Card>
    );
}