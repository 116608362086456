import { DaoService } from "./DaoService";

export default class NotificationService extends DaoService {
    static getInstance() {
        return super.getInstance('notification');
    }

    findUnreadNotifications(userUid) {
        return NotificationService.get(`${this.prefix}/${userUid}/unread`);
    }

    findAllNotifications(userUid) {
        return NotificationService.get(`${this.prefix}/${userUid}/all`);
    }

    markAllAsRead(userUid) {
        return NotificationService.post(`${this.prefix}/${userUid}/mark-all-as-read`);
    }

    markAsRead(userUid, notificationId) {
        return NotificationService.post(`${this.prefix}/${userUid}/mark-as-read/${notificationId}`);
    }

    markAsUnread(userUid, notificationId) {
        return NotificationService.post(`${this.prefix}/${userUid}/mark-as-unread/${notificationId}`);
    }

    countUnreadNotifications(userUid) {
        return NotificationService.get(`${this.prefix}/${userUid}/count-unread`);
    }
}