import { DaoService } from "./DaoService";

export default class ObjectifService extends DaoService {
    static PENTES = {
        ASCENDANTE: {libelle : 'Ascendante', value : 'ascendante'},
        DESCENDANTE: {libelle : 'Descendante', value : 'descendante'}
    }

    static getInstance() {
        return super.getInstance('objectif');
    }

    all() {
        return ObjectifService.get(`${this.prefix}/all`);
    }

    findByOrganisation(organisationId) {
        return ObjectifService.get(`${this.prefix}/${organisationId}/organisation`);
    }

    search(data) {
        return ObjectifService.post(`${this.prefix}/search`, data);
    }

    enable(idOrUid) {
        return ObjectifService.post(`${this.prefix}/${idOrUid}/enable`);
    }

    disable(idOrUid, data) {
        return ObjectifService.post(`${this.prefix}/${idOrUid}/disable`, data);
    }

}