import React from "react";
import { Form, Select, Button, Row, Col, Modal, Space, Checkbox, Typography, Card, Empty } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import OrganisationService from "../../services/OrganisationService";
import { catchError } from "../../services/DaoService";
import AffectationObjectifService from "../../services/AffectationObjectifService";
import Toast from "../../helpers/Toast";
import AffectationMissionService from "../../services/AffectationMissionService";

const { Option } = Select;
const { Title } = Typography;

export default function AffectationMissionNew({ visible, onCancel, onCreate, missionControle }) {
    const [form] = Form.useForm();
    const [organisations, setOrganisations] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loadingNextSttep, setLoadingNextSttep] = React.useState(false);
    const [step, setStep] = React.useState(1);
    const [selectedOrganisationIds, setSelectedOrganisationIds] = React.useState([]);
    const [selectedOrganisationData, setSelectedOrganisationData] = React.useState([]);
    const [affectionObjectifIds, setAffectionObjectifIds] = React.useState([]);

    React.useEffect(() => {
        setLoading(true);
        OrganisationService.getInstance().all()
            .then(response => {
                setOrganisations(response.data);
            })
            .catch(error => {
                catchError(error);
            }).finally(() => setLoading(false));
    }, []);

    React.useEffect(() => {
        AffectationMissionService.getInstance()
            .findByMissionControle(missionControle.uid)
            .then(response => {
                setAffectionObjectifIds(response.data.data.map(item => item.affectation_objectif_id));
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [missionControle.uid]);

    const gotoStep2 = () => {
        setLoadingNextSttep(true);
        form.validateFields().then(values => {
            AffectationObjectifService.getInstance().findByOrganisations(selectedOrganisationIds)
                .then(response => {
                    setSelectedOrganisationData(response.data.map(item => {
                        const affectations = item.affectations.filter(affectation => !affectionObjectifIds.includes(affectation.id));
                        item.affectations = affectations
                        return item;
                    }).filter(item => item.affectations.length > 0)
                    )
                    setStep(2);
                    setLoadingNextSttep(false);
                }).catch(error => {
                    catchError(error);
                });
        }).catch(error => {
            console.log(error);
        });
    }

    React.useEffect(() => {
        setStep(1);
    }, [selectedOrganisationIds]);

    const onFinish = (values) => {
        values.mission_controle_id = missionControle.id;
        setLoading(true);
        AffectationMissionService.getInstance()
            .create(values)
            .then(response => {
                Toast.success(response.message);
                form.resetFields();
                onCreate(response.data);
                setStep(1);
                setSelectedOrganisationIds(null);
                setSelectedOrganisationData(null);
                setAffectionObjectifIds(null);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <Modal title="Nouvelle mission de contrôle" open={visible} footer={null} onCancel={onCancel} width={"50%"}>
            <Card loading={loading}>
                <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                    <Row>
                        <Col className="gutter-row" span={24} >
                            <Form.Item
                                name="organisations"
                                label="Organisations"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez sélectionner au moins une organisation !',
                                        type: 'array',
                                    },
                                ]}
                            >
                                <Select value={selectedOrganisationIds} mode="multiple" placeholder="Veuillez sélectionner les organisations concernées"
                                    onChange={(value) => setSelectedOrganisationIds(value)}>
                                    {organisations.map(organisation => (
                                        <Option disabled={organisation.affectation_objectifs_count === 0} key={organisation.uid} value={organisation.id}>
                                            {organisation.nom}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {step === 2 ? <Row>
                        {selectedOrganisationData.length ? (
                            <Col className="gutter-row" span={24} >
                                <Form.Item
                                    name="affectation_objectif_ids"
                                    label={<span className="font-semibold text-lg ">Objectifs à contrôler</span>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Veuillez sélectionner au moins un objectif !',
                                            type: 'array',
                                        },
                                    ]}
                                >
                                    <Checkbox.Group>
                                        {
                                            <Row>
                                                {selectedOrganisationData.map((organisationData) => (
                                                    <React.Fragment key={organisationData.organisation.id}>
                                                        <Col className="gutter-row" span={24}>
                                                            <Title level={5}>{organisationData.organisation.nom}</Title>
                                                        </Col>
                                                        {organisationData.affectations ? (
                                                            <Row>
                                                                {organisationData.affectations.map((affectation) => (
                                                                    <Col className="gutter-row" span={24} key={affectation.uid}>
                                                                        <Checkbox value={affectation.id}>{affectation.objectif.intitule}</Checkbox>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        ) : null}
                                                    </React.Fragment>
                                                ))}
                                            </Row>
                                        }
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>)
                            : (
                                <Col className="gutter-row" span={24}>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={
                                            <span>
                                                Il n'y a aucun objectif non encore affecté qui est disponible pour les organisations sélectionnées.
                                            </span>}
                                    />
                                </Col>
                            )}
                    </Row> : null}
                    <Row>
                        <Col span={24}>
                            <Form.Item>
                                <Space>
                                    <Button onClick={onCancel}>Annuler</Button>
                                    {step === 1 ? <Button type="primary" htmlType="button" onClick={gotoStep2} loading={loadingNextSttep} icon={<ArrowRightOutlined />}>Continuer</Button> :
                                        <Button type="primary" htmlType="submit" icon={<ArrowRightOutlined />}>Enregistrer</Button>}
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Modal >
    )
}