import { Button, Col, Row, Spin } from "antd";
import Main from "../layout/Main";
import React from "react";
import DashboardConfigService from "../../services/DashboardConfigService";
import { catchError } from "../../services/DaoService";
import OrganisationEvolutionsDynamicChart from "../chart/OrganisationEvolutionsDynamicChart";
import ObjectifProgessionsChartDynamic from "../chart/ObjectifProgessionsChartDynamic";
import OrganisationPerformanceGlobalChart from "../chart/OrganisationPerformanceGlobalChart";
import ObjectifProgressionsGlobalChart from "../chart/ObjectifProgressionsGlobalChart";
import OrganisationPerformanceTop5Chart from "../chart/OrganisationPerformanceTop5Chart.js";
import DashboardStatistic from "./DashboardStatistic.js";
import { ExpandOutlined } from "@ant-design/icons";
import { useUserContext } from "../../providers/UserProvider.js";

export default function DashboardDynamic() {

    const { check } = useUserContext();

    const [dashboardConfig, setDashboardConfig] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [isFullScreen, setIsFullScreen] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        DashboardConfigService.getInstance()
            .getRecord()
            .then((response) => {
                setDashboardConfig(response.data);
                setLoading(false);
            }).catch(err => catchError(err))
            .finally(() => setLoading(false));
    }, []);


    const handleFullScreen = () => {
        if (!isFullScreen) {
            openFullScreen();
        }
    };

    const openFullScreen = () => {
        const element = document.documentElement;
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { // Firefox
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE/Edge
            element.msRequestFullscreen();
        }
        setIsFullScreen(true);
    };

    React.useEffect(() => {
        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                setIsFullScreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    const buttonStyle = {
        marginBottom: '16px',
        marginLeft: '10px',
        textAlign: 'right'
    }

    const fullScreenStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        zIndex: 1000,
        padding: '20px',
        overflow: 'auto'
    }

    return (
        <Main>
            {loading ?
                <Spin size="large" /> : (
                    <>
                        {
                            check('CONFIGURE-DYNAMIC-DASHBOARD') ?
                                <div className="d-flex mb-2">
                                    <Button type="primary" onClick={handleFullScreen} icon={<ExpandOutlined />} style={buttonStyle}></Button>
                                </div>
                                : null}
                        <div style={isFullScreen ? fullScreenStyle : {}}>
                            <Row gutter={24}>
                                <Col className="gutter-row" span={4}>
                                    <div style={{ height: 700 }}>
                                        <DashboardStatistic />
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={20}>
                                    <Row gutter={24}>
                                        <Col className="gutter-row mb-4" span={7}>
                                            <OrganisationPerformanceTop5Chart />
                                        </Col>
                                        <Col className="gutter-row mb-4" span={10}>
                                            <ObjectifProgessionsChartDynamic dashboardConfig={dashboardConfig} />
                                        </Col>
                                        <Col className="gutter-row mb-4" span={7}>
                                            <OrganisationEvolutionsDynamicChart dashboardConfig={dashboardConfig} />
                                        </Col>
                                        <Col className="gutter-row mb-4" span={12}>
                                            <OrganisationPerformanceGlobalChart />
                                        </Col>
                                        <Col className="gutter-row mb-4" span={12}>
                                            <ObjectifProgressionsGlobalChart />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </>
                )}
        </Main>
    )
}