import { DaoService } from "./DaoService";

export default class AffectationEntiteService extends DaoService {
    static getInstance() {
        return super.getInstance('affectationentite');
    }

    findByAffectationObjectif(affectationId) {
        return AffectationEntiteService.get(`${this.prefix}/${affectationId}/affectation-objectif`);
    }
}