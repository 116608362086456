import React from "react";
import { Card, Col, Form, Input, Modal, Row } from "antd";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";
import AffectationObjectifService from "../../services/AffectationObjectifService";

export default function AffectationObjectifEdit({ uid, visible, onUpdate, onCancel }) {
    const [form] = Form.useForm();
    const [updating, setUpdating] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [affectationObjectif, setAffectationObjectif] = React.useState(null);

    React.useEffect(() => {
        if (!visible) return;
        if (uid) {
            setLoading(true);
            AffectationObjectifService.getInstance()
                .find(uid)
                .then(response => {
                    setAffectationObjectif(response.data);
                    form.setFieldsValue(response.data);
                }).catch(error => {
                    catchError(error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [uid, form, visible]);

    const onFinish = (values) => {
        values.directe = affectationObjectif.directe;
        setUpdating(true);
        AffectationObjectifService.getInstance()
            .update(affectationObjectif.uid, values)
            .then(response => {
                Toast.success(response.message);
                form.resetFields();
                onUpdate(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setUpdating(false);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Modal open={visible} okText="Mettre à jour" cancelText="Annuler" onOk={form.submit} onCancel={onCancel} width={"50%"}>
            <Card loading={loading}>
                <Form form={form} name="AffectationObjectifEdit" layout="vertical"
                    onFinish={onFinish} onFinishFailed={onFinishFailed} loading={updating}>
                    <Row gutter={[24, 5]} >
                        {affectationObjectif?.directe ? (
                            <Col span={24}>
                                <Form.Item label="Valeur cible" name="valeur_cible" rules={[
                                    { required: true, message: 'Veuillez saisir une valeur cible!' }
                                ]}>
                                    <Input type="number" addonAfter={affectationObjectif.objectif.unite} />
                                </Form.Item>
                            </Col>
                        ) : (
                            <>
                                {
                                    affectationObjectif?.affectation_entites?.map((affectation_entite, index) => (
                                        <>
                                            <Col span={24} key={index}>
                                                <Form.Item label={affectation_entite.entite.nom} name={['affectation_entites', index, 'valeur_cible']} rules={[
                                                    { required: true, message: 'Veuillez saisir une valeur cible!' }
                                                ]}>
                                                    <Input type="number" addonAfter={affectationObjectif.objectif.unite} />
                                                </Form.Item>
                                                <Form.Item name={['affectation_entites', index, 'id']} hidden />
                                            </Col>
                                        </>
                                    ))
                                }
                            </>
                        )}
                        <Col span={24}>
                            <Form.Item label="Date Delai" name="date_delai" rules={[
                                { required: true, message: 'Veuillez saisir une date de délai!' }
                            ]}>
                                <Input type="date" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Commentaire" name="commentaire">
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Modal>
    );
};