import { Button, Card, Col, Divider, Input, Popconfirm, Row, Select, Space, Spin } from "antd";
import { useUserContext } from "../../providers/UserProvider";
import UnauthorizedMessage from "../utils/UnauthorizedMessage";
import React, { useCallback } from "react";
import InfiniteTable from "../utils/InfiniteTable";
import { DeleteFilled, EyeOutlined, PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import OrganisationRattacheeService from "../../services/OrganisationRattacheeService";
import { catchError } from "../../services/DaoService";
import TypeOrganisationService from "../../services/TypeOrganisationService";
import OrganisationRattacheeNew from "./OrganisationRattacheeNew";
import { useNavigate } from "react-router-dom";

export default function OrganisationRattacheeList({ parent }) {
    const navigate = useNavigate();
    const { check } = useUserContext();
    const [loading, setLoading] = React.useState(true);
    const [searchText, setSearchText] = React.useState(null);
    const [typeOrganisationIds, setTypeOrganisationIds] = React.useState([]);
    const [typeOrganisations, setTypeOrganisations] = React.useState([]);
    const [statut, setStatut] = React.useState('tout');
    const [organisationRattachees, setOrganisationRattachees] = React.useState([]);
    const [paginationData, setPaginationData] = React.useState(null);
    const [hasMore, setHasMore] = React.useState(true);
    const [newModalVisible, setNewModalVisible] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);

    const init = useCallback(() => {
        setLoading(true);
        OrganisationRattacheeService.getInstance()
            .search({ searchText, typeOrganisationIds, statut, parent_id: parent.id })
            .then((response) => {
                if (!response) return;
                setPaginationData(response.data);
                setOrganisationRattachees(response.data.data);
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [searchText, typeOrganisationIds, statut, setLoading, setPaginationData, parent.id]);

    const loadMore = () => {
        if (hasMore && !loading) {
            setLoading(true);
            OrganisationRattacheeService.post(paginationData.next_page_url, { searchText, typeOrganisationIds, statut, parent_id: parent.id })
                .then((response) => {
                    if (!response) return;
                    setPaginationData(response.data);
                }).catch((error) => {
                    catchError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    React.useEffect(() => {
        init();
    }, [init]);

    React.useEffect(() => {
        TypeOrganisationService.getInstance()
            .all()
            .then((response) => {
                setTypeOrganisations(response.data);
            }).catch((error) => {
                catchError(error);
            });
    }, []);

    React.useEffect(() => {
        if (paginationData) {
            // merge the new data with the old data without duplicates (if any)
            setOrganisationRattachees(organisationRattachees => [...new Map([...organisationRattachees, ...paginationData.data].map(item => [item['id'], item])).values()]);

            setHasMore(paginationData.next_page_url != null);
        } else {
            setHasMore(false);
        }
    }, [paginationData]);

    const handleNewOrganisationRattachee = ((orgRattachhees) => { 
        setOrganisationRattachees([...organisationRattachees, ...orgRattachhees]);
        setNewModalVisible(false);
    });

    const setItemToView = (record) => {
        navigate(`/parametrage/organisation/${record.enfant.uid}`);
    }

    const labelStyle = {
        marginRight: '10px', // espace entre le label et le select
        display: 'flex',
        alignItems: 'center',
    };

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    };

    const columns = [
        {
            title: 'Nom',
            dataIndex: ['enfant', 'nom'],
            key: 'enfant.nom',
            sorter: (a, b) => a.enfant.nom.localeCompare(b.enfant.nom),
            width: 300,
            // retour à la ligne si le texte est trop long
            render: (text) => <div style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                maxWidth: 300
            }}>{text}</div>,
        },
        {
            title: "Sigle",
            dataIndex: ['enfant', 'sigle'],
            key: 'enfant.sigle',
            sorter: (a, b) => a.enfant.sigle.localeCompare(b.enfant.sigle),
            width: 100,
            ellipsis: true
        },
        {
            title: "Type Organisation",
            dataIndex: ['enfant', 'type_organisation', 'nom'],
            key: 'enfant.type_organisation.nom',
            render: (nom) => nom,
            sorter: (a, b) => a.enfant.type_organisation.nom.localeCompare(b.enfant.type_organisation.nom),
            width: 150,
            ellipsis: true
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 150,
            render: (record) => (
                <Space>
                    {check('VIEW-ORGANIZATION') ? <Button  onClick={() => setItemToView(record)} icon={<EyeOutlined />} className="btn btn-success"></Button> : null}
                    {check('DELETE-ORGANIZATION') ?
                        <Popconfirm okType='danger' onConfirm={() => setItemToRemove(record)} title="Voulez-vous vraiment supprimer cette organisation?" okText="Confirmer" cancelText="Annuler">
                            <Button icon={<DeleteFilled />} loading={isDeleting} className="btn btn-danger"></Button>
                        </Popconfirm>
                        : null}
                </Space>
            )
        }
    ];

    const setItemToRemove = (organisationRattachee) => {
        setIsDeleting(true);
        OrganisationRattacheeService.getInstance()
            .remove(organisationRattachee.id)
            .then(() => {
                setOrganisationRattachees(organisationRattachees.filter(o => o.id !== organisationRattachee.id));
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsDeleting(false);
            });
    }


    return (
        <>
            {check('VIEW-ORGANIZATIONS') ? (
                <>
                    <OrganisationRattacheeNew visible={newModalVisible} onCreate={handleNewOrganisationRattachee} onCancel={()=>setNewModalVisible(false)} parent={parent} />
                    <Card title="Liste des organisations rattachées" classNames={{ body: 'p-0' }}>
                        <Spin spinning={loading}>
                            <InfiniteTable title={
                                <Row gutter={[24, 16]}>
                                    <Col span={24}>
                                        <Space>
                                            {
                                                check('ADD-ORGANIZATION') ? <Button onClick={() => setNewModalVisible(true)} icon={<PlusCircleOutlined />} type="primary">Associer des organisations</Button> : null
                                            }
                                            <Button onClick={init} icon={<ReloadOutlined />} type="default">Rafraîchir</Button>
                                        </Space>
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={[16, 16]} justify={'center'}>
                                            <Col span={24}>
                                                <Divider>Filtres rapides</Divider>
                                            </Col>
                                            <Col xs={24} md={8} lg={6}>
                                                <Input.Search value={searchText} size="large" onInput={
                                                    (e) => {
                                                        setSearchText(e.target.value);
                                                    }} placeholder="Rechercher une organisation" />
                                            </Col>
                                            <Col xs={24} md={8} lg={6}>
                                                <Select size="large" value={typeOrganisationIds} onChange={
                                                    (value) => {
                                                        setTypeOrganisationIds(value);
                                                    }
                                                }
                                                    allowClear mode='multiple' placeholder="Filtrer par type organisation" style={{ width: '100%' }}>
                                                    {typeOrganisations.map((typeOrganisation) => (
                                                        <Select.Option key={typeOrganisation.id} value={typeOrganisation.id}>{typeOrganisation.nom}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col xs={24} md={8} lg={6}>
                                                <div style={containerStyle}>
                                                    <div style={labelStyle}>Statut:</div>
                                                    <Select size="large" value={statut} onChange={
                                                        (value) => {
                                                            setStatut(value);
                                                        }
                                                    } placeholder="Filtrer par statut" style={{ width: '100%' }}>
                                                        <Select.Option value="tout">Tous</Select.Option>
                                                        <Select.Option value="actif">Activé</Select.Option>
                                                        <Select.Option value="inactif">Désactivé</Select.Option>
                                                    </Select>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }
                                dataSource={organisationRattachees} loading={loading} columns={columns} scroll={{ x: 1300, y: 500 }}
                                paginationData={paginationData} loadMore={loadMore}
                            />
                        </Spin>
                    </Card>
                </>) : <UnauthorizedMessage />
            }
        </>);
}