import React from "react";
import DashboardService from "../../services/DashboardService";
import { catchError } from "../../services/DaoService";
import styles from './EntiteAffectationObjectifsEvolutionsChart.module.scss';
import { Card, Spin, Typography } from "antd";
import Chart from "react-apexcharts";

const { Title } = Typography;

export default function EntiteAffectationObjectifsEvolutionsChart({ affectationObjectif }) {
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        DashboardService.getInstance()
            .getEntiteAffectationObjectifsEvolutions(affectationObjectif.uid)
            .then((response) => {
                setData(response.data);
                setLoading(false);
            }).catch(err => catchError(err))
            .finally(() => setLoading(false));
    }, [affectationObjectif]);

    const options = {
        chart: {
            type: 'bar',
            height: 500,
            zoom: {
                enabled: true
            },
            toolbar: {
                show: true
            }
        },
        xaxis: {
            categories: data ? data.entites : [],
            labels: {
                style: {
                    colors: Array(data ? data.entites.length : 0).fill('#000'),
                    fontSize: '12px',
                    fontWeight: 700,
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 700,
                },
            },
        },
        title: {
            text: data ? data.unite : '',
            style: {
                fontSize: '12px',
                fontWeight: 700,
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        },
        colors: ['#00E396', '#e69138'],
        dataLabels: {
            enabled: false
        }
    };

    const series = data ? [
        {
            name: 'Valeur Réelle',
            data: data.valeur_reelles
        },
        {
            name: 'Valeur Cible',
            data: data.valeur_cibles
        }
    ] : [];

    return (
        <Card loading={loading} className={styles.chartCard} title={
            <Title level={4}>Progression des Objectifs par Entité</Title>}>
            {loading ? <Spin size="large" /> : (
                <Chart options={options} series={series} type="bar" height={500} />)} </Card>
    );
}