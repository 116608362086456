import React from "react";
import { Menu } from "antd";

export default function MenuHorizontal({ menuItems, page }) {

    return (
        <div className="d-none d-sm-block mx-5">
            <Menu
                defaultSelectedKeys={[page]}
                // defaultOpenKeys={[page]}
                theme="light"
                mode="horizontal"
                items={menuItems}
                className={``}
            />
        </div>
    )
}