import React from "react";
import { Card, Col, Divider, List, Row, Select, Typography, Form, Input, Button, Popconfirm, Spin, Descriptions } from "antd";
import Main from "../../components/layout/Main";
import OrganisationService from "../../services/OrganisationService";
import { BankOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { catchError } from "../../services/DaoService";
import styles from "./KpiAlimentationPage.module.scss";
import AffectationObjectifService from "../../services/AffectationObjectifService";
import ObjectifRealiseService from "../../services/ObjectifRealiseService";
import Toast from "../../helpers/Toast";
import UnauthorizedMessage from "../../components/utils/UnauthorizedMessage";
import { useUserContext } from "../../providers/UserProvider";
import Moment from "react-moment";
import AffectationEntiteService from "../../services/AffectationEntiteService";

const { Text, Paragraph, Title } = Typography;

const { Option } = Select;

function OrganisationSelector({ organisations, selectedOrganisation, setSelectedOrganisation, isOrganisationLoading }) {
    if (isOrganisationLoading) {
        return <Spin size="large" />;
    }

    return (
        <>
            <Title level={5}>Choisir l'organisation concernée</Title>
            <Select
                showSearch
                placeholder="Sélectionner une organisation"
                optionFilterProp="children"
                value={selectedOrganisation ? selectedOrganisation.uid : undefined}
                onChange={(value) => {
                    const organisation = organisations.find(org => org.uid === value);
                    setSelectedOrganisation(organisation);
                }}
                className={`${styles.select} w-100`}
            >
                {organisations.map((item) => (
                    <Option disabled={item.affectation_objectifs_count === 0} key={item.uid} value={item.uid}>
                        <BankOutlined /> {item.nom}
                    </Option>
                ))}
            </Select>
        </>
    );
}

function AffectationSelector({ affectationObjectifs, selectedAffectationUid, handleSelectedAffectationChange }) {
    return (
        <>
            <Title level={5}>Choisir l'objectif à alimenter</Title>
            <Select showSearch optionFilterProp="children"
                value={selectedAffectationUid}
                onChange={handleSelectedAffectationChange}
                className="w-100"
                placeholder="Sélectionner un objectif">
                {affectationObjectifs.map(affectation => (
                    <Select.Option
                        key={affectation.uid}
                        value={affectation.uid}>
                        {affectation.objectif.intitule}
                    </Select.Option>
                ))}
            </Select>
        </>
    );
}

function RealisationForm({ form, onFinish, onFinishFailed, selectedAffectation, isRealisationAdding }) {
    const [affectationEntites, setAffectationEntites] = React.useState([]);

    React.useEffect(() => {
        if (!selectedAffectation) {
            return;
        }
        if (!selectedAffectation.directe) {
            AffectationEntiteService.getInstance()
                .findByAffectationObjectif(selectedAffectation.uid)
                .then(response => {
                    setAffectationEntites(response.data);
                }).catch(error => {
                    catchError(error);
                });
        } else {
            setAffectationEntites([]);
            return;
        }
    }, [selectedAffectation]);

    return (
        <Card>
            <Spin spinning={isRealisationAdding}>
                <Form
                    form={form}
                    name="alimentationObjectifForm"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    {selectedAffectation?.directe ? <Form.Item
                        label="Valeur Réelle"
                        name="valeur_reelle"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez saisir la valeur actuelle de l\'objectif.',
                            },
                        ]}
                    >
                        <Input type="number" addonAfter={selectedAffectation.objectif.unite} />
                    </Form.Item> : (
                        <>
                            {affectationEntites.map(ae => (
                                <Form.Item
                                    key={ae.uid}
                                    label={`${ae.entite.nom}`}
                                    name={`valeur_reelle_${ae.id}`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Veuillez saisir la valeur actuelle de l\'objectif.',
                                        },
                                    ]}
                                >
                                    <Input type="number" addonAfter={selectedAffectation.objectif.unite} />
                                </Form.Item>
                            ))}
                        </>
                    )}

                    <Form.Item
                        label="Date de réalisation"
                        name="date_realisation"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez choisir la date de l\'alimentation.',
                            },
                            {
                                validator: (_, value) => {
                                    if (value && new Date(value) > new Date()) {
                                        return Promise.reject('La date doit être antérieure ou égale à la date du jour.');
                                    }
                                    return Promise.resolve();
                                }
                            }
                        ]}
                    >
                        <Input type="date" className="w-100" />
                    </Form.Item>

                    <Form.Item
                        label="Remarque (Optionnel)"
                        name="remarque"
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Soumettre
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Card>
    );
}

function RealisationHistory({ objectifRealises, isRealisationLoading, deleteRealisation, isRealisationDeleting, orPaginationData, loadMore, check }) {
    return (
        <Card title="Historique">
            <Spin spinning={isRealisationLoading}>
                <List
                    itemLayout="horizontal"
                    dataSource={objectifRealises}
                    renderItem={item => (
                        <List.Item actions={[
                            <Button disabled type="primary" className="btn btn-warning" size="small" icon={<EditOutlined />}></Button>,
                            check('DELETE-OBJECTIVE-KPI') ? <Popconfirm title="Voulez-vous vraiment supprimer cette réalisation ?" onConfirm={() => deleteRealisation(item.uid)} okText="Oui" cancelText="Non" okType="danger">
                                <Button loading={isRealisationDeleting} type="primary" danger size="small" icon={<DeleteOutlined />}></Button>
                            </Popconfirm> : null
                        ]}>
                            <List.Item.Meta
                                title={`[${item.affectation_entite?.entite?.nom ?? ""}] --> ${item.valeur_reelle} ${item.unite}`}
                                description={
                                    <>
                                        <Moment>{item.date_realisation}</Moment>
                                        <br />
                                        {item.remarque}
                                    </>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Spin>
            {orPaginationData.next_page_url ? (
                <Button onClick={loadMore} className="w-100" type="link">Voir plus</Button>
            ) : null}
        </Card>
    );
}

function SelectedAffectationDetails({ selectedAffectation }) {
    return selectedAffectation ? (
        <Card classNames={{ body: "p-0" }}>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Objectif">
                    <Text>{selectedAffectation.objectif.intitule}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Indicateur">
                    <Text>{selectedAffectation.objectif.indicateur}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Description">
                    <Paragraph>{selectedAffectation.objectif.description}</Paragraph>
                </Descriptions.Item>
            </Descriptions>
        </Card>
    ) : null;
}

export default function KpiAlimentationPage() {
    const [loading, setLoading] = React.useState(true);
    const [isOrganisationLoading, setIsOrganisationLoading] = React.useState(true);
    const [organisations, setOrganisations] = React.useState([]);
    const [selectedOrganisation, setSelectedOrganisation] = React.useState(null);
    const [affectationObjectifs, setAffectationObjectifs] = React.useState([]);
    const [selectedAffectationUid, setSelectedAffectationUid] = React.useState(null);
    const [selectedAffectation, setSelectedAffectation] = React.useState(null);
    const [form] = Form.useForm();
    const [objectifRealises, setObjectifRealises] = React.useState([]);
    const [isRealisationLoading, setIsRealisationLoading] = React.useState(false);
    const [isRealisationAdding, setIsRealisationAdding] = React.useState(false);
    const [orPaginationData, setOrPaginationData] = React.useState({});
    const { check } = useUserContext();
    const [isRealisationDeleting, setIsRealisationDeleting] = React.useState(false);

    React.useEffect(() => {
        if (!check('FEED-OBJECTIVE-KPI')) {
            return;
        }
        setIsOrganisationLoading(true);
        OrganisationService.getInstance()
            .all()
            .then(response => {
                setOrganisations(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsOrganisationLoading(false);
            });
    }, [check]);

    React.useEffect(() => {
        if (organisations.length > 0) {
            setSelectedOrganisation(organisations[0]);
        } else {
            setSelectedOrganisation(null);
        }
    }, [organisations]);

    React.useEffect(() => {
        if (selectedOrganisation) {
            setSelectedAffectationUid(null);
            setLoading(true);
            AffectationObjectifService.getInstance()
                .findByOrganisation(selectedOrganisation.uid)
                .then(response => {
                    setAffectationObjectifs(response.data);
                }).catch(error => {
                    catchError(error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [selectedOrganisation]);

    React.useEffect(() => {
        if (selectedAffectationUid) {
            setSelectedAffectation(affectationObjectifs.find((val => val.uid === selectedAffectationUid)));
        } else {
            setSelectedAffectation(null);
        }
    }, [selectedAffectationUid, affectationObjectifs]);

    React.useEffect(() => {
        if (!check('VIEW-OBJECTIVE-KPI')) {
            return;
        }
        form.resetFields();
        if (selectedAffectation) {
            setIsRealisationLoading(true);
            ObjectifRealiseService.getInstance()
                .findByAffectation(selectedAffectation.uid)
                .then(response => {
                    setOrPaginationData(response.data);
                    setObjectifRealises(response.data.data);
                }).catch(error => {
                    catchError(error);
                }).finally(() => {
                    setIsRealisationLoading(false);
                });
        }
    }, [selectedAffectation, check, form]);

    const loadMore = () => {
        if (orPaginationData.next_page_url) {
            setIsRealisationLoading(true);
            ObjectifRealiseService.get(orPaginationData.next_page_url)
                .then(response => {
                    setOrPaginationData(response.data);
                    setObjectifRealises([...objectifRealises, ...response.data.data]);
                }).catch(error => {
                    catchError(error);
                }).finally(() => {
                    setIsRealisationLoading(false);
                });
        }
    }

    const handleSelectedAffectationChange = (value) => {
        setSelectedAffectationUid(value);
    }

    const onFinish = (values) => {
        if (!selectedAffectation) {
            Toast.error('Veuillez sélectionner un objectif à alimenter.');
        }
        values.affectation_objectif_id = selectedAffectation.id;
        // si pas affectation directe, on doit ajouter les valeurs des entités
        if (!selectedAffectation.directe) {
            /**
             * Exemple de clé dynamique: valeur_reelle_27: "84", valeur_reelle_28: "55"
            */
           let affectationEntites = [];
           for (const key in values) {
               if (key.includes('valeur_reelle_')) {
                   affectationEntites.push({affectation_entite_id: key.split('_')[2], valeur_reelle: values[key]})
                   delete values[key];
                }
            }
            values.valeur_reelles = affectationEntites;
        }
        setIsRealisationAdding(true);
        ObjectifRealiseService.getInstance()
            .create(values)
            .then(response => {
                Toast.success(response.message);
                form.resetFields();
                setObjectifRealises([response.data, ...objectifRealises]);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsRealisationAdding(false);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const deleteRealisation = (uid) => {
        setIsRealisationDeleting(true);
        ObjectifRealiseService.getInstance()
            .remove(uid)
            .then(response => {
                Toast.success(response.message);
                setObjectifRealises(objectifRealises.filter(or => or.uid !== uid));
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsRealisationDeleting(false);
            });
    }

    const Content = (
        <Card title={`Interface d'alimentation des KPI`}>
            <Row>
                <Col span={24}>
                    <Spin spinning={loading}>
                        <Card classNames={{body: 'p-2'}}>
                            <OrganisationSelector
                                organisations={organisations}
                                selectedOrganisation={selectedOrganisation}
                                setSelectedOrganisation={setSelectedOrganisation}
                                isOrganisationLoading={isOrganisationLoading}
                            />
                            <Divider />
                            <AffectationSelector
                                affectationObjectifs={affectationObjectifs}
                                selectedAffectationUid={selectedAffectationUid}
                                handleSelectedAffectationChange={handleSelectedAffectationChange}
                            />
                            <hr />
                            <SelectedAffectationDetails selectedAffectation={selectedAffectation} />
                            {selectedAffectation ? <>
                                <Divider>Alimentation</Divider>
                                <Row gutter={[24, 1]}>
                                    <Col xs={24} md={12}>
                                        <RealisationForm
                                            form={form}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            selectedAffectation={selectedAffectation}
                                            isRealisationAdding={isRealisationAdding}
                                        />
                                    </Col>
                                    {check('VIEW-OBJECTIVE-KPI') ? <Col xs={24} md={12}>
                                        <RealisationHistory
                                            objectifRealises={objectifRealises}
                                            isRealisationLoading={isRealisationLoading}
                                            deleteRealisation={deleteRealisation}
                                            isRealisationDeleting={isRealisationDeleting}
                                            orPaginationData={orPaginationData}
                                            loadMore={loadMore}
                                            check={check}
                                        />
                                    </Col> : null}
                                </Row>
                            </> : null}
                        </Card>
                    </Spin>
                </Col>
            </Row>
        </Card>
    );

    return (
        <Main>
            {check('FEED-OBJECTIVE-KPI') ? Content : <UnauthorizedMessage />}
        </Main>
    );
}
