import { DaoService } from "./DaoService";

export default class UserOrganisationService extends DaoService {
    static getInstance() {
        return super.getInstance('userorganisation');
    }

    findByOrganisation(organisationUid) {
        return UserOrganisationService.get(`${this.prefix}/${organisationUid}/organisation`);
    }
   
    linkUsersToOrganisation(uid, data) {
        return UserOrganisationService.post(`${this.prefix}/${uid}/organisation`, data);
    }
}