import { Card, Form, Modal, Select } from "antd";
import React from "react";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";
import AffectationObjectifService from "../../services/AffectationObjectifService";
import OrganisationRattacheeService from "../../services/OrganisationRattacheeService";
import './ProjectObjectivesToOrganisations.scss';

export default function ProjectObjectivesToOrganisations({ visible, onUpdate, onCancel, organisationUid }) {
    const [form] = Form.useForm();
    const [updating, setUpdating] = React.useState(false);
    const [affectationObjectifs, setAffectationObjectifs] = React.useState([]);
    const [organisationRattachees, setOrganisationRattachees] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loadingResponsable, setLoadingResponsable] = React.useState(false);
    const [selectedResponsibles, setSelectedResponsibles] = React.useState({});

    React.useEffect(() => {
        setLoading(true);
        if (!visible) return;
        AffectationObjectifService.getInstance()
            .findByOrganisation(organisationUid)
            .then(response => {
                setAffectationObjectifs(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [organisationUid, form, visible])

    React.useEffect(() => {
        setLoadingResponsable(true);
        OrganisationRattacheeService.getInstance()
            .findByParent(organisationUid)
            .then(response => {
                setOrganisationRattachees(response.data.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLoadingResponsable(false);
            });
    }, [organisationUid, form, visible])

    const handleSelectChange = (value, record) => {
        setSelectedResponsibles(prevState => ({
            ...prevState,
            [record.id]: value
        }));
    };

    const onFinish = (values) => {
        const updatedAffectationObjectifs = affectationObjectifs.map(affectationObjectif => ({
            ...affectationObjectif,
            organisation_id: selectedResponsibles[affectationObjectif.id]
        }));
        const affectationObjectifList = {
            affectation_objectifs: updatedAffectationObjectifs.map(affectationObjectif => ({
                id: affectationObjectif.id,
                organisation_id: affectationObjectif.organisation_id,
            })),
        };
        setUpdating(true);
        AffectationObjectifService.getInstance()
            .updateAffectationObjectifsList(affectationObjectifList)
            .then(response => {
                Toast.success(response.message);
                onUpdate(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setUpdating(false);
            });
    };
    React.useEffect(() => {
        const initialResponsibles = {};
        affectationObjectifs.forEach(affectationObjectif => {
            initialResponsibles[affectationObjectif.id] = affectationObjectif.organisation_id;
        });
        setSelectedResponsibles(initialResponsibles);
    }, [affectationObjectifs]);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }


    return (
        <Modal open={visible} okText="Sauvegarder" okType="primary" cancelText="Annuler" onOk={form.submit} onCancel={onCancel}>
            <Card loading={loading}>
                <Form
                    form={form}
                    name="editRepartitionProjectForm"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    loading={updating}
                >
                    <table className="table">
                        <div class="row header green">
                            <div className="cell">
                                Objectif
                            </div>
                            <div class="cell">
                                Responsable
                            </div>

                        </div>
                        <tbody>
                            {affectationObjectifs.map((affectationObjectif, index) => (
                                <tr key={index}>
                                    <td style={{ width: '100%' }}>
                                        {affectationObjectif.objectif.intitule}
                                    </td>
                                    <td style={{ width: '100%' }}>
                                        <Select
                                            value={selectedResponsibles[affectationObjectif.id]}
                                            onChange={(value) => handleSelectChange(value, affectationObjectif)}
                                            loading={loadingResponsable}
                                            placeholder="Sélectionner responsable"
                                        >
                                            {organisationRattachees.map((organisation) => (
                                                <Select.Option
                                                    key={organisation.enfant_id}
                                                    value={organisation.enfant_id}
                                                >
                                                    {organisation.enfant.nom}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Form>

            </Card>
        </Modal >
    );

}