import { Button, Card, Col, Divider, Input, Popconfirm, Row, Select, Space, Spin, Tag } from "antd";
import Main from "../../components/layout/Main";
import UnauthorizedMessage from "../../components/utils/UnauthorizedMessage";
import { useUserContext } from "../../providers/UserProvider";
import React, { useCallback } from "react";
import Toast from "../../helpers/Toast";
import InfiniteTable from "../../components/utils/InfiniteTable";
import MissionControleService from "../../services/MissionControleService";
import { catchError } from "../../services/DaoService";
import { DeleteFilled, EditOutlined, EyeOutlined, FileOutlined, FolderOpenOutlined, PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import MissionControleNew from "../../components/missioncontrole/MissionControleNew";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import MissionControleEdit from "../../components/missioncontrole/MissionControleEdit";

export default function MissionControleListPage() {

    const { check } = useUserContext();
    const navigate = useNavigate();
    const [missionControles, setMissionControles] = React.useState([]);
    const [missionControleUid, setMissionControleUid] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [isLoadingMore, setIsLoadingMore] = React.useState(false);
    const [hasMore, setHasMore] = React.useState(true);
    const [deleting, setDeleting] = React.useState(false);
    const [paginationData, setPaginationData] = React.useState(null);
    const [newModalVisible, setNewModalVisible] = React.useState(false);
    const [updateModalVisible, setUpdateModalVisible] = React.useState(false);
    // filtres
    const [searchText, setSearchText] = React.useState(null);
    const [statut, setStatut] = React.useState('tout');
    const [dateDebutAffectation, setDateDebutAffectation] = React.useState();
    const [dateFinAffectation, setDateFinAffectation] = React.useState();
    const [dateDebutDelai, setDateDebutDelai] = React.useState();
    const [dateFinDelai, setDateFinDelai] = React.useState();
    const [isArchiving, setIsArchiving]=React.useState(false);
    const init = useCallback(() => {
        setLoading(true);
        MissionControleService.getInstance()
            .search({ searchText, statut, dateDebutAffectation, dateFinAffectation, dateDebutDelai, dateFinDelai })
            .then((response) => {
                if (!response) return;
                setPaginationData(response.data);
                setMissionControles(response.data.data);
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [searchText, statut, dateDebutAffectation, dateFinAffectation, dateDebutDelai, dateFinDelai]);

    React.useEffect(() => {
        if (!check('VIEW-AUDIT-MISSIONS')) {
            return;
        }
        init();
    }, [init, check]);

    React.useEffect(() => {
        if (paginationData) {
            // merge the new data with the old data without duplicates (if any)
            setMissionControles(missionControles => [...new Map([...missionControles, ...paginationData.data].map(item => [item['id'], item])).values()]);

            setHasMore(paginationData.next_page_url != null);
        } else {
            setHasMore(false);
        }
    }, [paginationData]);

    const loadMore = () => {
        if (hasMore && !loading) {
            setIsLoadingMore(true);
            MissionControleService.post(paginationData.next_page_url, { searchText, statut, dateDebutAffectation, dateFinAffectation, dateDebutDelai, dateFinDelai })
                .then((response) => {
                    if (!response) return;
                    setPaginationData(response.data);
                }).catch((error) => {
                    catchError(error);
                }).finally(() => {
                    setIsLoadingMore(false);
                });
        }
    }

    const handleNewMissionControleCreation = (missionControle) => {
        setMissionControles([...missionControles, missionControle]);
    }
    const gotoMissionControleDetails = (missionControle) => {
        navigate(`/objectif/mission-controle/${missionControle.uid}`);
    }
    //Debut function archiver
    const handleDesarchiver = (missionControl) => {
        setIsArchiving(true);
        MissionControleService.getInstance()
            .enable(missionControl.uid)
            .then((response) => {
                missionControl.enabled = true;
                Toast.success(response.message);
                setMissionControles(missionControles.map(mc => mc.uid === missionControl.uid ? missionControl : mc));
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setIsArchiving(false);
            });
            //disable
    }
    const handleArchiver = (missionControl) => {
      setIsArchiving(true);
        MissionControleService.getInstance()
            .disable(missionControl.uid)
            .then((response) => {
                Toast.success(response.message);
                missionControl.enabled = false;
                setMissionControles(missionControles.map(mc => mc.uid === missionControl.uid ? missionControl : mc));
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setIsArchiving(false);
            });
    }
    const removeItem = (missionControl) => {
        setDeleting(true);
        MissionControleService.getInstance()
            .remove(missionControl.uid)
            .then((response) => {
                setMissionControles(missionControles.filter(item => item.uid !== missionControl.uid));
                Toast.success(response.message);
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setDeleting(false);
            });
    }

    const openEditModal = (missionControle) => {
        setMissionControleUid(missionControle.uid);
        setUpdateModalVisible(true);
    }

    const handleMissionControleUpdate = (missionControle) => {
        setMissionControles(missionControles.map(mc => mc.uid === missionControle.uid ? missionControle : mc));
        setUpdateModalVisible(false);
    }

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'nom',
            key: 'nom',
            sorter: (a, b) => a.nom.localeCompare(b.nom),
            width: 400,
            // rendre le texte en plusieurs lignes
            render: (text) => (
                <div style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                    maxWidth: 300
                }}>
                    {text}
                </div>
            ),
        },
        {
            title: 'Numero',
            dataIndex: 'numero',
            key: 'numero',
            width: 200,
            ellipsis: true,
        },
        {
            title: 'Responsable',
            dataIndex: 'responsable',
            key: 'responsable',
            width: 200,
            ellipsis: true,
        },
        {
            title: 'Statut',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (enabled) =>
                enabled ? <Tag color="green"> actif</Tag> : <Tag color="red">Inactif</Tag>,
            width: 80,
        },
        {
            title: "Affectations des objectifs",
            dataIndex: 'affectation_missions_count',
            key: 'affectation_missions_count',
            width: 150,
            render: (count) => <Tag color="blue">{count} affectation objectif(s)</Tag>
        },
        {
            title: 'Date affectation',
            dataIndex: 'date_affectation',
            key: 'responsable',
            width: 150,
            render: (date) => <Tag color="blue"><Moment format="DD/MM/YYYY">{date}</Moment></Tag>
        },
        {
            title: 'Delai affectation',
            dataIndex: 'date_delai',
            key: 'date_delai',
            width: 150,
            render: (date) => <Tag color="blue"><Moment format="DD/MM/YYYY">{date}</Moment></Tag>
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 300,
            // rendre le texte en plusieurs lignes
            render: (text) => (
                <div style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                    maxWidth: 300
                }}>
                    {text ?? 'N/A'}
                </div>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space size="small">
                    {check('VIEW-AUDIT-MISSION') ? <Button onClick={() => gotoMissionControleDetails(record)} type="primary" icon={<EyeOutlined />}></Button> : null}
                    {check('EDIT-AUDIT-MISSION') ? <Button onClick={() => openEditModal(record)}  type="default" className='btn-warning' icon={<EditOutlined />}></Button> : null}
                    {check('DELETE-AUDIT-MISSION') ? (
                        <Popconfirm title="Vous voulez vraiment supprimer cette mission" onConfirm={() => { removeItem(record) }} okText="Confirmer" cancelText="Annuler" okType="danger">
                            <Button loading={deleting} type="primary" danger icon={<DeleteFilled />}></Button>
                        </Popconfirm>
                    ) : null}

                    {record.enabled ?
                        (check('ARCHIVE-AUDIT-MISSION') ? (
                        <Popconfirm title="Voulez-vous  archiver ?" onConfirm={() => {handleArchiver (record) }} okText="Archiver" cancelText="Annuler" okType="danger">
                            <Button loading={isArchiving} type="primary" danger icon={<FileOutlined />}></Button>
                        </Popconfirm>
                    ) : null) :
                        (check('ARCHIVE-AUDIT-MISSION') ?
                            <Popconfirm title="Voulez-vous vraiment désarchiver ?" onConfirm={() => handleDesarchiver(record)} okText="Oui" cancelText="Non" okType="primary">
                                <Button loading={isArchiving} className="btn btn-primary" title="Archiver" icon={<FolderOpenOutlined />}></Button>
                            </Popconfirm> : null)
                    }

                </Space>
            ),
            width: 250,
        },
    ];

    const labelStyle = {
        marginRight: '10px', // espace entre le label et le select
        display: 'flex',
        alignItems: 'center',
    };

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    };

    return (
        <Main>
            {check('VIEW-AUDIT-MISSIONS') ?
                <>
                    <MissionControleNew onCreate={handleNewMissionControleCreation} visible={newModalVisible} onCancel={() => setNewModalVisible(false)} />
                    <MissionControleEdit onUpdate={handleMissionControleUpdate} visible={updateModalVisible} onCancel={() => setUpdateModalVisible(false)} missionControleUid={missionControleUid} />
                    <Card title="Liste des missions contrôles" classNames={{ body: 'p-0' }}>
                        <Spin spinning={loading}>
                            <InfiniteTable pagination={false} loadMore={loadMore}
                                rowKey={record => record.uid} loading={loading || isLoadingMore}
                                dataSource={missionControles} columns={columns} paginationData={paginationData}
                                title={
                                    <Row gutter={[24, 16]}>
                                        <Col span={24}>
                                            <Space>
                                                {
                                                    check('ADD-AUDIT-MISSION') ? <Button onClick={() => setNewModalVisible(true)} icon={<PlusCircleOutlined />} type="primary">Ajouter mission</Button> : null
                                                }
                                                {<Button onClick={init} icon={<ReloadOutlined />} type="default">Rafraîchir</Button>}
                                            </Space>
                                        </Col>
                                        <Col span={24}>
                                            <Row gutter={[16, 16]} justify={'center'}>
                                                <Col span={24}>
                                                    <Divider>Filtres rapides</Divider>
                                                </Col>
                                                <Col xs={24} md={8} lg={6}>
                                                        <div style={labelStyle}>Date debut affectation</div>
                                                    <Input type="date" value={dateDebutAffectation} onInput={
                                                        (e) => {
                                                            setDateDebutAffectation(e.target.value);
                                                        }} />
                                                </Col>
                                                <Col xs={24} md={8} lg={6}>
                                                <div style={labelStyle}>Date fin affectation</div>
                                                    <Input type="date" value={dateFinAffectation} onInput={
                                                        (e) => {
                                                            setDateFinAffectation(e.target.value);
                                                        }} />
                                                </Col>
                                                <Col xs={24} md={8} lg={6}>
                                                <div style={labelStyle}>Date debut delai</div>
                                                    <Input type="date" value={dateDebutDelai} onInput={
                                                        (e) => {
                                                            setDateDebutDelai(e.target.value);
                                                        }} />
                                                </Col>
                                                <Col xs={24} md={8} lg={6}>
                                                <div style={labelStyle}>Date fin delai</div>
                                                    <Input type="date" value={dateFinDelai} onInput={
                                                        (e) => {
                                                            setDateFinDelai(e.target.value);
                                                        }} />
                                                </Col>
                                                <Col xs={24} md={8} lg={6}>
                                                    <Input.Search value={searchText} onInput={
                                                        (e) => {
                                                            setSearchText(e.target.value);
                                                        }} placeholder="Rechercher une mission controle" />
                                                </Col>
                                                <Col xs={24} md={8} lg={6}>
                                                    <div style={containerStyle}>
                                                        <div style={labelStyle}>Statut:</div>
                                                        <Select value={statut} onChange={
                                                            (value) => {
                                                                setStatut(value);
                                                            }
                                                        } placeholder="Filtrer par statut" style={{ width: '100%' }}>
                                                            <Select.Option value="tout">Tous</Select.Option>
                                                            <Select.Option value="actif">Archivé</Select.Option>
                                                            <Select.Option value="inactif">Déarchivé</Select.Option>
                                                        </Select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                } />
                        </Spin>
                    </Card>
                </> : <UnauthorizedMessage />
            }
        </Main>
    )
}