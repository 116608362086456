import { Card, Col, Form, Input, Modal, Row } from "antd";
import UserService from "../../services/UserService";
import React, { useState } from "react";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";

const DisableUserModal = ({ visible, uid, onUpdate, onCancel }) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        values.enabled = false;
        setLoading(true);
        UserService.getInstance()
            .disable(uid, values)
            .then((response) => {
                Toast.success(response.message);
                onUpdate(response.data);
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    };
    return (
        <Modal open={visible}
            title="Désactiver l'utilisateur"
            okText="Désactiver"
            okButtonProps={{danger: true }}
            onOk={() =>  {
                form.validateFields()
                    .then((values) => {
                        onFinish(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            cancelText="Annuler"
            onCancel={onCancel} >
            <Card loading={loading} style={{ marginBottom: 20 }}>
                <Form layout='vertical' form={form}>
                    <Row gutter={24}>
                        <Col xs={24} md={24}>
                            <Form.Item label="Raison de la désactivation" name="disabled_reason"
                                rules={[
                                    { required: true, message: 'Veuillez saisir la raison pour laquelle vous désactiver cet utilisateur!' },
                                    { min: 3, message: 'La raison doit contenir au moins 3 caractères!' }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Modal>
    )
};
export default DisableUserModal;