import { DaoService } from "./DaoService";

export default class OrganisationRattacheeService extends DaoService {
    static getInstance() {
        return super.getInstance('organisationrattachee');
    }

    findByParent(organisationUid) {
        return OrganisationRattacheeService.get(`${this.prefix}/${organisationUid}/parent`);
    }

    search(data) {
        return OrganisationRattacheeService.post(`${this.prefix}/search`, data);
    }
}