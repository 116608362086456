import { Card, Col, Form, Input, Modal, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import AffectationMissionService from "../../services/AffectationMissionService";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";

export default function AffectationMissionDoneForm({ visible, uid, onDone, onCancel}) {

    const [form] = Form.useForm();
    const [doning, setDoning] = React.useState(false);

    
    const onFinish = (values) => {
        setDoning(true);
        values.done = true;
        AffectationMissionService.getInstance()
        .done(uid, values)
        .then(response => {
            Toast.success(response.message);
            form.resetFields();
            onDone(response.data);
        }).catch(error => {
            catchError(error);
        }).finally(() => {
            setDoning(false);
        });
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <Modal title="Renseigner la fin de la mission" open={visible} okText="Terminer" cancelText="Annuler" onOk={form.submit} onCancel={onCancel} width={"50%"}>
            <Card loading={doning}> 
                <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={24} >
                            <Form.Item label="Date Fin Mission" name="done_at">
                                <Input type="date" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24} >
                            <Form.Item label="Observation" name="observation">
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Modal>
    )
}