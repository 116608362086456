import React from "react";
import ObjectifList from "../../components/objectif/ObjectifList";
import Main from "../../components/layout/Main";
export default function ObjectifListPage() {
    const [loading, setLoading] = React.useState(true);
    const [paginationData, setPaginationData] = React.useState(null);
    return (
        <Main>
            <ObjectifList loading={loading} paginationData={paginationData} setLoading={setLoading} setPaginationData={setPaginationData} />
        </Main>
    );
}







