import React from "react";
import { Button, Card, Col, Form, Input, Modal, Row, Select } from "antd";
import ObjectifService from "../../services/ObjectifService";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";
import { useNavigate } from "react-router-dom";

export default function ObjectifNew({ visible, onCreate, onCancel, parent_id = null }) {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [creating, setCreating] = React.useState(false);

    const gotoObjectifDetails = (objectif) => {
        navigate(`/objectif/details/${objectif.uid}`);
    }

    const handleSave = (isRedirection) => {
        setCreating(true);
        form.validateFields().then(values => {
            values.objectif_id = parent_id;
            ObjectifService.getInstance()
                .create(values)
                .then(response => {
                    Toast.success(response.message);
                    form.resetFields();
                    onCreate(response.data);
                    if (isRedirection) {
                        gotoObjectifDetails(response.data);
                    }
                }).catch(error => {
                    catchError(error);
                }).finally(() => {
                    setCreating(false);
                });
        }).catch(info => {
            console.log('Validation Failed:', info);
            setCreating(false);
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const { Option } = Select;

    return (
        <Modal open={visible} onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Annuler
                </Button>,
                <Button key="save-show" type="primary" loading={creating} onClick={() => handleSave(true)}>
                    Enregistrer et Afficher
                </Button>,
                <Button key="save-again" type="primary" loading={creating} onClick={() => handleSave(false)}>
                    Enregistrer et Créer
                </Button>,
            ]}>
            <Card>
                <Form form={form} name="newObjectifForm" layout="vertical"
                    onFinishFailed={onFinishFailed}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, }}>
                        <Col className="gutter-row" span={24} >
                            <Form.Item help="L'intitulé est le nom de l'objectif."
                                label="Intitule"
                                name="intitule"
                                rules={[
                                    { required: true, message: 'l\'intitule est obligatoire!' },
                                    { min: 3, message: 'L\'intitule doit contenir au moins 3 caractères!' }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, }}>
                        <Col className="gutter-row" span={24} >
                            <Form.Item help="L'indicateur est l'élément quantitatif à mesurer."
                                label="Indicateur"
                                name="indicateur"
                                rules={[
                                    { required: true, message: 'L\'indicateur est obligatoire!' },
                                    { min: 3, message: 'L\'indicateur doit contenir au moins 3 caractères!' }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24} >
                            <Form.Item help="L'unité de mesure de l'élément quantitatif à mesurer."
                                label="Unite de mesure"
                                name="unite"
                                rules={[
                                    { required: true, message: 'L\'unite est obligatoire!' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item help="La pente définit si on doit progresser ou régresser pour atteindre l'objectif."
                        label="Pente"
                        name="pente"
                        rules={[
                            {
                                required: true,
                                message: 'Ce champ est obligatoire!',
                            },
                        ]}
                    >
                        <Select placeholder="Choisir une valeur">
                            <Option value="ascendante">Ascendante</Option>
                            <Option value="descendante">Descendante</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Card>
        </Modal>
    );

};