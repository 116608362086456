import React from "react";
import { Table, Button, Space, Card, Tag, Popconfirm, Progress, Spin } from "antd";
import AffectationObjectifService from "../../services/AffectationObjectifService";
import { catchError } from "../../services/DaoService";
import styles from "./OrganisationObjectifList.module.scss"; // Import du module SCSS
import { DeleteFilled, EditOutlined, EyeFilled, FileOutlined, FolderOpenOutlined, PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import Moment from "react-moment";
import AffectationObjectifToOrganisation from "../affectationobjectif/AffectationObjectifToOrganisation";
import { useUserContext } from "../../providers/UserProvider";
import Toast from "../../helpers/Toast";
import { Link } from "react-router-dom";
import AffectationObjectifEdit from "../affectationobjectif/AffectationObjectifEdit";

const OrganisationObjectifList = ({ organisation, objectif }) => {
    const [affectationObjectifs, setAffectationObjectifs] = React.useState([]);
    const [objectifAffectationFormVisible, setObjectifAffectationFormVisible] = React.useState(false);
    const { check } = useUserContext();
    const [deleting, setDeleting] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [selectedAffectationObjectif, setSelectedAffectationObjectif] = React.useState(null);

    const loadObjectifAffectations = React.useCallback(() => {
        if (!check('VIEW-ORGANIZATION-OBJECTIVES')) return;
        if (!organisation && !objectif) return;
        if (organisation && objectif) {
            Toast.error("Seul un objectif ou une organisation doit être sélectionné");
            return;
        }
        setLoading(true);
        if (organisation) {
            AffectationObjectifService.getInstance()
                .findByOrganisation(organisation.uid)
                .then(response => {
                    setAffectationObjectifs(response.data);
                }).catch(error => {
                    catchError(error);
                }).finally(() => {
                    setLoading(false);
                });
        } else {
            AffectationObjectifService.getInstance()
                .findByObjectif(objectif.uid)
                .then(response => {
                    setAffectationObjectifs(response.data);
                }).catch(error => {
                    catchError(error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [organisation, objectif, check]);

    React.useEffect(() => {
        loadObjectifAffectations();
    }, [loadObjectifAffectations]);

    const handleDelete = (affectationObjectif) => {
        setDeleting(true);
        AffectationObjectifService.getInstance()
            .remove(affectationObjectif.uid)
            .then((response) => {
                Toast.success(response.message);
                setAffectationObjectifs(affectationObjectifs.filter(item => item.uid !== affectationObjectif.uid));
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setDeleting(false);
            });
    };

    const columns = [
        {
            title: 'Indicateur',
            dataIndex: 'objectif.indicateur',
            key: 'indicateur',
            render: (_, record) => record.objectif?.indicateur,
            width: 200,
            ellipsis: true
        },
        {
            title: 'Statut',
            dataIndex: 'statut',
            key: 'statut',
            width: 100,
            render: (_, record) => record.atteint ? <Progress strokeColor="#1d7c55" type="circle" percent={100} size={50} /> : <Progress type="circle" percent={100} size={50} status="exception" />
        },
        {
            title: 'Valeur cible',
            dataIndex: 'valeur_cible',
            key: 'valeur_cible',
            width: 150,
            render: (_, record) => record.directe ? <Tag color="cyan">{record.valeur_cible} {record.objectif?.unite}</Tag> : <Tag color="red">Répartie</Tag>,
            ellipsis: true
        },
        {
            title: 'Valeur réalisée',
            dataIndex: 'valeur_reelle',
            key: 'valeur_reelle',
            width: 200,
            render: (valeur_reelle, record) => record.directe ? (
                <>
                    {valeur_reelle !== -1 ? <Tag color="green">{valeur_reelle} {record.objectif?.unite}</Tag> : <Tag color="red">Aucune résultat renseigné</Tag>}
                </>
            ) : <Tag color="red">Répartie</Tag>,
        },
        {
            title: "Date affectation",
            dataIndex: 'created_at',
            key: 'created_at',
            width: 150,
            render: (date) => <Tag color="blue"><Moment format="DD/MM/YYYY">{date}</Moment></Tag>
        },
        {
            title: 'Date délai',
            dataIndex: 'date_delai',
            key: 'date_delai',
            width: 150,
            render: (date) => <Tag color="red"><Moment format="DD/MM/YYYY">{date}</Moment></Tag>
        },
        {
            title: 'Commentaire',
            dataIndex: 'commentaire',
            key: 'commentaire',
            width: 300,
            ellipsis: true,
            render: (_, record) => record.commentaire ?? 'N/A'
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    {check('VIEW-ORGANIZATION-OBJECTIVES') ?
                        <Link to={`/objectif/affectation/${record.uid}`}>
                            <Button type="primary" icon={<EyeFilled />}></Button>
                        </Link>
                        : null}
                    {check('REMOVE-ORGANIZATION-OBJECTIVES') ?
                        <Popconfirm title="Voulez-vous vraiment supprimer cet objectif?" onConfirm={() => handleDelete(record)} okText="Oui" cancelText="Non">
                            <Button loading={deleting} type="primary" danger icon={<DeleteFilled />}></Button>
                        </Popconfirm>
                        : null}
                    {check('EDIT-ORGANIZATION-OBJECTIVES') ? <Button onClick={() => setItemToEdit(record)} className="btn btn-warning" title="Modifier" icon={<EditOutlined />}></Button> : null}
                    {record.enabled ?
                        (check('ARCHIVE-ORGANIZATION-OBJECTIVES') ? 
                        <Popconfirm title="Voulez-vous vraiment désarchiver ?" onConfirm={() =>  handleArchiver(record)} okText="Oui" cancelText="Non">
                                <Button  className="btn btn-danger" title="Archiver" icon={<FolderOpenOutlined />}></Button>
                            </Popconfirm> : null) :
                        (check('ARCHIVE-ORGANIZATION-OBJECTIVES') ?
                            <Popconfirm title="Voulez-vous vraiment archiver ?" onConfirm={() => handleDesarchiver(record)} okText="Oui" cancelText="Non">
                                <Button  className="btn btn-secondary" title="Archiver" icon={<FileOutlined />}></Button>
                            </Popconfirm> : null)
                    }
                </Space>
            ),
            width: 200
        },
    ];

    if (organisation) {
        columns.unshift({
            title: 'Objectif',
            dataIndex: 'objectif.intitule',
            key: 'objectif',
            render: (_, record) => record.objectif?.intitule,
            width: 350,
            ellipsis: true
        });
    } else if (objectif) {
        columns.unshift({
            title: 'Organisation',
            dataIndex: 'organisation.nom',
            key: 'organisation',
            render: (_, record) => record.affectable?.nom,
            width: 200,
            ellipsis: true,
        });
    }
    columns.unshift({
        title: 'N°',
        dataIndex: 'rowNumber',
        key: 'rowNumber',
        render: (text, record, index) => index + 1,
        width: 50,
        fixed: 'left'
    });

    const handleObjectifAffectationAdd = () => {
        setObjectifAffectationFormVisible(false);
        loadObjectifAffectations();
    }

    const handleObjectifAffectationFormClose = () => {
        setObjectifAffectationFormVisible(false);
    }

    const handleAffectationOjbectifUpdate = (affectationObjectif) => {
        setAffectationObjectifs(affectationObjectifs.map(ao => ao.uid === affectationObjectif.uid ? affectationObjectif : ao));
        setEditModalVisible(false);
    }

    const handleArchiver = (affectationObjectif) => {
        AffectationObjectifService.getInstance()
            .enable(affectationObjectif.uid)
            .then((response) => {
                Toast.success(response.message);
                setAffectationObjectifs(affectationObjectifs.map((ao) => {
                    if (ao.uid === affectationObjectif.uid) {
                        ao.enabled = true;
                        return ao;
                    }
                    return ao;
                }));
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
            });
    }
    const setItemToEdit = (affectationObjectif) => {
        setSelectedAffectationObjectif(affectationObjectif);
        setEditModalVisible(true);
    };

    const handleDesarchiver = (affectationObjectif) => {
        AffectationObjectifService.getInstance()
            .disable(affectationObjectif.uid)
            .then((response) => {
                Toast.success(response.message);
                setAffectationObjectifs(affectationObjectifs.map((ao) => {
                    if (ao.uid === affectationObjectif.uid) {
                        ao.enabled = false;
                        return ao;
                    }
                    return ao;
                }));
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
            });
    }

    return (
        <div className="py-2">
            {check('VIEW-ORGANIZATION-OBJECTIVES') ? (
                <>
                    {selectedAffectationObjectif ? <AffectationObjectifEdit onUpdate={handleAffectationOjbectifUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} uid={selectedAffectationObjectif.uid} /> : null}
                    <Card title="Liste des objectifs affectés à l'organisation" classNames={{ body: 'p-0' }} extra={
                        <Space>
                            <Button loading={loading} onClick={loadObjectifAffectations} type="default" icon={<ReloadOutlined />}>Rafraîchir</Button>
                            {(check('ASSIGN-OBJECTIVES-TO-ORGANIZATION') && organisation) ?
                                <Button icon={<PlusCircleOutlined />} onClick={() => setObjectifAffectationFormVisible(true)} type="primary">Ajouter</Button> : null}
                        </Space>
                    }>
                        <Spin spinning={loading}>
                            <Table scroll={{ x: 1300, y: 720 }} rowKey={record => record.uid}
                                dataSource={affectationObjectifs}
                                columns={columns}
                                pagination={false}
                                className={styles["organisation-objectif-table"]} // Utilisation du module SCSS pour les styles
                            />
                        </Spin>
                    </Card>
                </>
            ) : null
            }
            {(check('ASSIGN-OBJECTIVES-TO-ORGANIZATION') && organisation) ? (
                <AffectationObjectifToOrganisation onSuccess={handleObjectifAffectationAdd} onCancel={handleObjectifAffectationFormClose} visible={objectifAffectationFormVisible} organisation={organisation} />
            ) : null}
        </div>
    );
}

export default OrganisationObjectifList;