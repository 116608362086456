
import React, { createContext, useCallback, useContext } from 'react';
import { catchError } from '../services/DaoService';
import NotificationService from '../services/NotificationService';
import { useUserContext } from './UserProvider';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [unReadNotifications, setUnReadNotifications] = React.useState([]);
    const [countUnreadNotifications, setCountUnreadNotifications] = React.useState([]);
    const {currentUser} = useUserContext();

    const loadUnreadNotifications = useCallback(() => {
        if (!currentUser) {
            return;
        }
        NotificationService.getInstance()
            .findUnreadNotifications(currentUser.uid)
            .then(response => {
                setUnReadNotifications(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
            });
    }, [currentUser]);

    const loadUnreadNotificationsCount = useCallback(() => {
        if (!currentUser) {
            return;
        }
        NotificationService.getInstance()
            .countUnreadNotifications(currentUser.uid)
            .then(response => {
                setCountUnreadNotifications(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
            });
    }, [currentUser]);

    return (
        <NotificationContext.Provider value={{
            unReadNotifications, loadUnreadNotifications,
            countUnreadNotifications, loadUnreadNotificationsCount
        }}>
            {children}
        </NotificationContext.Provider>
    );
}


export const useNotificationContext = () => useContext(NotificationContext);