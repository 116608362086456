import React from "react";
import DashboardConfigService from "../../services/DashboardConfigService";
import { catchError } from "../../services/DaoService";
import { Card, Spin, Typography } from "antd";
import Chart from "react-apexcharts";

const { Title } = Typography;

// Composant pour le diagramme
const ChartComponent = ({ data }) => {
    // Vérifiez que les données sont définies et ont le bon format
  if (!data || !Array.isArray(data)) {
    return <div>Les données sont invalides</div>;
  }

  const options = {
    chart: {
      type: 'bar',
      height: 200,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: data.map(item => item.objectif), 
    },
    colors: ['#E91E63', '#00E396', '#0090FF', '#775DD0']
  };

  const series = [{
    name: 'Progressions',
    data: data.map(item => item.progression)
  }];

  return (
    <div id="chart">
      <Chart options={options} series={series} type="bar" height={200} />
    </div>
  );
};

export default function ObjectifProgessionsChartDynamic({ dashboardConfig }) {

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (!dashboardConfig) { return; }
        setLoading(true);
        const values = dashboardConfig.objectif_ids;
        DashboardConfigService.getInstance()
            .getObjectifProgressions(values)
            .then((response) => {
                setData(response.data);
            }).catch(err => catchError(err))
            .finally(() => setLoading(false));
    }, [dashboardConfig]);

    return (
        <Card loading={loading} height={500} style={{ borderRadius: '0' }} title={
            <Title className="text-center" style={{whiteSpace: 'pre-wrap'}} level={4}>Progression des Objectifs par rapport aux assignation dans les organisations</Title>
        }>
            {loading ? <Spin size="large" /> : (
                <ChartComponent data={data} />)}
        </Card>
    );

}