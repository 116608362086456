import React from "react";
import Main from "../../components/layout/Main";
import OrganisationService from "../../services/OrganisationService";
import { useParams } from "react-router-dom";
import OrganisationDetails from "../../components/organisation/OrganisationDetails";
import { catchError } from "../../services/DaoService";
import OrganisationObjectifList from "../../components/objectif/OrganisationObjectifList";
import LoadingPlaceholder from "../../components/utils/LoadingPlaceholder";
import { Button, Card, Divider, Space, Tabs } from "antd";
import { useUserContext } from "../../providers/UserProvider";
import UnauthorizedMessage from "../../components/utils/UnauthorizedMessage";
import UserOrganisationList from "../../components/organisation/UserOrganisationList";
import PieceJointeList from "../../components/piecejointe/PieceJointeList";
import PieceJointeService from "../../services/PieceJointeService";
import SousOrganisationList from "../../components/organisation/SousOrganisationList";
import OrganisationRattacheeList from "../../components/organisationrattachee/OrganisationRattacheeList";
import ProjectObjectivesToOrganisations from "../../components/organisation/ProjectObjectivesToOrganisations";
import { PlusCircleOutlined } from "@ant-design/icons";

export default function OrganisationDetailsPage() {
    const [organisation, setOrganisation] = React.useState(null);
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const { check } = useUserContext();

    const [editModalVisible, setEditModalVisible] = React.useState(false);


    React.useEffect(() => {
        setLoading(true);
        OrganisationService.getInstance()
            .find(id)
            .then(response => {
                setOrganisation(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [id]);

    const tabItems = [
        check('VIEW-ORGANIZATION-OBJECTIVES') && {
            key: "objectifs",
            label: "Objectifs affectés",
            children: <>
                {organisation ? <OrganisationObjectifList organisation={organisation} /> : null}
            </>
        },
        organisation?.type_organisation?.code !== 'PROJET' && {
            key: "children",
            label: "Sous organisations",
            children: <SousOrganisationList parentId={organisation?.id} />
        },
        organisation?.type_organisation?.code === 'PROJET' && {
            key: "related-organisations",
            label: "Organisations rattachées",
            children: <OrganisationRattacheeList parent={organisation}></OrganisationRattacheeList>
        },
        {
            key: "users",
            label: "Utilisateurs rattachés",
            children: <UserOrganisationList organisationUid={id} />,
        },
        {
            key: "attachments",
            label: "Pièces jointes",
            children: <>
                <PieceJointeList canSee={check('VIEW-ORGANIZATION-ATTACHMENTS')}
                    canAdd={check('ADD-ORGANIZATION-ATTACHMENTS')} candRemove={check('DELETE-ORGANIZATION-ATTACHMENTS')}
                    parentType={PieceJointeService.TYPES.ORGANISATION} parentId={organisation?.id} />
            </>
        }
    ]

    const handleOrganisationUpdate = (organisation) => {
        setEditModalVisible(false);
    }

    return (
        <Main>
            {
                check('VIEW-ORGANIZATION') ?
                    <Space>
                        <ProjectObjectivesToOrganisations onUpdate={handleOrganisationUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} organisationUid={id} />
                        <Card classNames={{ body: 'p-2' }}>
                            <Card.Meta title={`${organisation?.type_organisation.nom}: ${organisation?.nom}`} />
                            <Divider />
                            {loading ? <LoadingPlaceholder /> : (
                                <>
                                    <Button onClick={() => setEditModalVisible(true)} icon={<PlusCircleOutlined />} type="primary">Répartition</Button>
                                    <OrganisationDetails organisation={organisation} />
                                    <Card classNames={{ body: 'p-1' }}>
                                        <Tabs items={tabItems} />
                                    </Card>
                                </>
                            )}
                        </Card>
                    </Space> : <UnauthorizedMessage />
            }
        </Main>
    );
} 