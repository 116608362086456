import React, { useCallback } from "react";
import UserOrganisationService from "../../services/UserOrganisationService";
import { catchError } from "../../services/DaoService";
import { useUserContext } from "../../providers/UserProvider";
import { Table, Button, Badge, Card, Space, Typography, Spin, Popconfirm } from 'antd';
import { DeleteOutlined, LoadingOutlined, PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import styles from './UserOrganisationList.module.scss';
import UsersOrganisationAdd from "./UsersOrganisationAdd";

const { Title } = Typography;

export default function UserOrganisationList({ organisationUid }) {
    const [loading, setLoading] = React.useState(true);
    const [userOrganisations, setUserOrganisations] = React.useState([]);
    const [usersOrganisationNew, setUsersOrganisationNew] = React.useState([]);
    const [paginationData, setPaginationData] = React.useState(null);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [newModalVisible, setNewModalVisible] = React.useState(false);
    const { check } = useUserContext();


    const init = useCallback(() => {
        setLoading(true);
        UserOrganisationService.getInstance()
            .findByOrganisation(organisationUid)
            .then((response) => {
                setPaginationData(response.data);
                setUserOrganisations(response.data.data);
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [organisationUid, usersOrganisationNew]);

    React.useEffect(() => {
        if (!organisationUid || !check('VIEW-ORGANIZATION-USERS')) {
            return;
        }
        init();
    }, [init, organisationUid, check]);

    const loadMore = () => {
        if (!paginationData.next_page_url) {
            return;
        }
        setLoading(true);
        UserOrganisationService
            .get(paginationData.next_page_url)
            .then((response) => {
                setPaginationData(response.data);
                setUserOrganisations([...userOrganisations, ...response.data.data]);
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }

    const handleDelete = (usersOrganisation) => {
        setIsDeleting(true);
        UserOrganisationService.getInstance()
            .remove(usersOrganisation.id)
            .then(() => {
                setUserOrganisations(userOrganisations.filter(uo => uo.id !== usersOrganisation.id));
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setIsDeleting(false);
            });
    }

    const columns = [
        {
            title: 'N°',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
            width: 30,
            fixed: 'left'
        },
        {
            title: 'Nom',
            dataIndex: ['user', 'name'],
            key: 'name',
            width: 100,

        },
        {
            title: 'Email',
            dataIndex: ['user', 'email'],
            key: 'email',
            width: 100,
        },
        {
            title: 'Statut',
            dataIndex: ['user', 'enabled'],
            key: 'enabled',
            render: enabled => (
                <Badge
                    status={enabled ? "success" : "error"}
                    text={enabled ? "Actif" : "Inactif"}
                />
            ),
            width: 100
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space>
                    {check('ADD-USER-TO-ORGANIZATION') ? <Popconfirm okType="danger" okText="Supprimer" cancelText="Annuler" placement="topRight"
                        title="Voulez-vous vraiment supprimer cet utilisateur de l'oganisation ?" onConfirm={() => handleDelete(record)}>
                        <Button loading={isDeleting}
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm> : null}
                </Space>
            ),
            width: 100
        },
    ];
    const handleAssignUserOrganisation = (usersOrganisationNew) => {
        setUsersOrganisationNew(usersOrganisationNew);
        setNewModalVisible(false);
    }

    return (
        <div>
            <UsersOrganisationAdd onSave={handleAssignUserOrganisation} visible={newModalVisible} onCancel={() => setNewModalVisible(false)} uid={organisationUid} />
            <Card className={styles.userOrganisationListCard} classNames={{ body: 'p-0' }}
                extra={
                    <>
                        <Space>
                            {check('DELETE-ORGANIZATION') ?
                                <Button onClick={() => setNewModalVisible(true)} icon={<PlusCircleOutlined />} type="primary">Ajouter des utilisateurs</Button>
                                : null}
                            <Button type='default' icon={<ReloadOutlined />} onClick={init}>Rafraîchir</Button>
                        </Space>
                    </>
                }
            >
                {check('VIEW-ORGANIZATION-USERS') ? (
                    <>
                        <Title level={5}>Liste des utilisateurs ayant accès à l'organisation</Title>
                        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                            <Table scroll={{ x: 700, y: 500 }}
                                columns={columns}
                                dataSource={userOrganisations}
                                rowKey="uid"
                                pagination={false}
                            />
                        </Spin>
                        {paginationData?.next_page_url && (
                            <div className={styles.loadMore}>
                                <Button onClick={loadMore} disabled={!paginationData?.next_page_url}>
                                    Charger plus
                                </Button>
                            </div>
                        )}
                    </>
                ) : null}
            </Card>
        </div>
    );
}
