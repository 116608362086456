import React, { useState, useEffect } from 'react';
import { Modal, Card, Spin, Form, Button, Row, Col, Space, Input, Select, Switch } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { catchError } from '../../services/DaoService';
import Toast from '../../helpers/Toast';
import ObjectifService from '../../services/ObjectifService';
import AffectationObjectifService from '../../services/AffectationObjectifService';
import TypeEntiteService from '../../services/TypeEntiteService';
import EntiteService from '../../services/EntiteService';

const { Option } = Select;

const Step1Form = ({ form, objectifs, handleSearch, buildMultiAffectationForm, onFinishFailed }) => (
    <Form form={form} name="affectationObjectifForm" layout="vertical" onFinish={buildMultiAffectationForm} onFinishFailed={onFinishFailed}>
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form.Item
                    label="Objectifs"
                    name="objectifs"
                    rules={[
                        { required: true, message: 'Veuillez choisir au moins un objectif!' },
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        onSearch={handleSearch}
                        filterOption={false}
                        placeholder="Sélectionner des objectifs"
                        style={{ width: '100%' }}
                    >
                        {objectifs.map(objectif => (
                            <Option key={objectif.id} value={objectif.id}>{objectif.intitule}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item>
                    <Button type="primary" htmlType='submit' icon={<ArrowRightOutlined />}>Continuer</Button>
                </Form.Item>
            </Col>
        </Row>
    </Form>
);

const AffectationFormItem = ({ formItem, index, objectifs }) => {
    const objectif = objectifs.find(obj => obj.id === formItem.objectif_id);
    const [affectationDirecte, setAffectationDirecte] = useState(true);
    const [typeEntites, setTypeEntites] = useState([]);
    const [isTypeLoading, setIsTypeLoading] = useState(false);
    const [selectedTypeEntiteUid, setSelectedTypeEntiteUid] = useState(null);
    const [entites, setEntites] = useState([]);
    const [isEntiteLoading, setIsEntiteLoading] = useState(false);
    const [selectedEntiteIds, setSelectedEntiteIds] = useState([]);
    const [selectedEntites, setSelectedEntites] = useState([]);

    useEffect(() => {
        if (!affectationDirecte) {
            setSelectedTypeEntiteUid(null);
            setIsTypeLoading(true);
            TypeEntiteService.getInstance()
                .all().then((response) => {
                    setTypeEntites(response.data);
                }).catch((error) => {
                    catchError(error);
                }).finally(() => {
                    setIsTypeLoading(false);
                });
        }
    }, [affectationDirecte]);

    React.useEffect(() => {
        if (selectedTypeEntiteUid) {
            setSelectedEntiteIds([]);
            setIsEntiteLoading(true);
            EntiteService.getInstance()
                .findByTypeEntite(selectedTypeEntiteUid)
                .then((response) => {
                    setEntites(response.data);
                }).catch((error) => {
                    catchError(error);
                }).finally(() => {
                    setIsEntiteLoading(false);
                });
        } else {
            setEntites([]);
        }
    }, [selectedTypeEntiteUid]);

    React.useEffect(() => {
        if (selectedEntiteIds.length) {
            setSelectedEntites(selectedEntiteIds.map(id => entites.find(entite => entite.id === id)));
        }
    }, [selectedEntiteIds, entites]);


    return (
        <Row gutter={[16, 16]} key={index}>
            <Col span={24}>
                <Form.Item label={`Objectif ${index + 1}`}>
                    <Input value={objectif?.intitule} disabled />
                </Form.Item>
                <Form.Item name={`objectif_id_${index}`} initialValue={formItem.objectif_id} hidden>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} lg={8}>
                        <Form.Item label="Répartition des résultats attendus">
                            <Switch value={affectationDirecte} onChange={(checked) => {
                                setAffectationDirecte(checked);
                                if (checked) {
                                    setSelectedEntiteIds([]);
                                }
                            }}
                                checkedChildren="Assigner directement" unCheckedChildren="Répartir les résultats" defaultChecked />
                        </Form.Item>
                    </Col>
                    {!affectationDirecte && (
                        <>
                            <Col xs={24} sm={12} lg={8}>
                                <Form.Item label="Type Entité">
                                    <Select onChange={setSelectedTypeEntiteUid}
                                        value={selectedTypeEntiteUid} loading={isTypeLoading} placeholder="Sélectionner un type d'entité" style={{ width: '100%' }}>
                                        {typeEntites.map(typeEntite => (
                                            <Option key={typeEntite.uid} value={typeEntite.uid}>{typeEntite.nom}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {selectedTypeEntiteUid && (
                                <Col xs={24} sm={12} lg={8}>
                                    <Form.Item label="Entités">
                                        <Select mode="multiple" onChange={setSelectedEntiteIds} value={selectedEntiteIds} loading={isEntiteLoading} placeholder="Sélectionner des entités" style={{ width: '100%' }}>
                                            {entites.map(entite => (
                                                <Option key={entite.id} value={entite.id}>{entite.nom}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                        </>
                    )
                    }
                </Row>
            </Col>
            <Col span={12}>
                {selectedEntiteIds.length === 0 ? <Form.Item label="Valeur Cible" name={`valeur_cible_${index}`} rules={[
                    { required: true, message: 'Veuillez saisir une valeur cible!' },
                    { pattern: /^[0-9]*$/, message: 'Veuillez saisir une valeur numérique!' }
                ]}>
                    <Input type='number' addonAfter={objectif?.unite} />
                </Form.Item> : 
                selectedEntites.map((entite, i) => (
                    <Form.Item key={entite.uid} label={`Valeur Cible - ${entite.nom}`} name={`cible_${index}_${entite.id}`} rules={[
                        { required: true, message: 'Veuillez saisir une valeur cible!' },
                        { pattern: /^[0-9]*$/, message: 'Veuillez saisir une valeur numérique!' }
                    ]}>
                        <Input type='number' addonAfter={objectif?.unite} />
                    </Form.Item>
                ))}
            </Col>
            <Col span={12}>
                <Form.Item label="Date Delai" name={`date_delai_${index}`} rules={[
                    { required: true, message: 'Veuillez saisir une date de délai!' },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue(`date_delai_${index}`) >= new Date().toISOString().split('T')[0]) {
                                return Promise.resolve();
                            }
                            return Promise.reject('La date de délai doit être supérieure ou égale à la date d\'aujourd\'hui!');
                        },
                    }),
                ]}>
                    <Input type="date" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item label="Commentaire" name={`commentaire_${index}`}>
                    <Input.TextArea />
                </Form.Item>
            </Col>
        </Row>
    );
};


const Step2Form = ({ form, multiAffectationForm, objectifs, setStep, handleMultiAffectationSubmit, onFinishFailed }) => (
    <Form form={form} layout="vertical" onFinish={handleMultiAffectationSubmit} onFinishFailed={onFinishFailed}>
        {multiAffectationForm.map((formItem, index) => (
            <AffectationFormItem key={index} formItem={formItem} index={index} objectifs={objectifs} />
        ))}
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form.Item>
                    <Space>
                        <Button onClick={() => setStep(1)} icon={<ArrowLeftOutlined />}>Retour</Button>
                        <Button type="primary" htmlType='submit'>Enregistrer</Button>
                    </Space>
                </Form.Item>
            </Col>
        </Row>
    </Form>
);

const AffectationObjectifToOrganisation = ({ organisation, onCancel, onSuccess, visible }) => {
    const [objectifs, setObjectifs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const [filteredObjectifs, setFilteredObjectifs] = useState([]);
    const [multiAffectationForm, setMultiAffectationForm] = useState([]);
    const [step, setStep] = useState(1);

    useEffect(() => {
        if (!organisation?.uid || !visible) return;
        setLoading(true);
        Promise.all([
            ObjectifService.getInstance().all(),
            ObjectifService.getInstance().findByOrganisation(organisation.uid)
        ])
            .then(([allObjectifs, organisationObjectifs]) => {
                const organisationObjectifIds = organisationObjectifs.data.map(obj => obj.id);
                const nonAssignedObjectifs = allObjectifs.data.filter(obj => !organisationObjectifIds.includes(obj.id));
                setObjectifs(nonAssignedObjectifs);
                setFilteredObjectifs(nonAssignedObjectifs);
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [organisation?.uid, visible]);

    const buildMultiAffectationForm = (values) => {
        const selectedObjectifs = values.objectifs;
        const multiForm = selectedObjectifs.map(objectif => ({
            objectif_id: objectif,
            organisation_id: organisation.id,
            valeur_cible: null,
            date_delai: null,
            commentaire: null
        }));
        form.resetFields();
        setMultiAffectationForm(multiForm);
        setStep(2);
    };

    const handleMultiAffectationSubmit = (values) => {
        const multiAffectationForm = {
            organisation_id: organisation.id,
            valeur_cibles: [],
            date_delais: [],
            commentaires: [],
            objectif_ids: [],
            entite_cibles: []
        };
        Object.keys(values).forEach(key => {
            if (key.startsWith('valeur_cible')) {
                // eslint-disable-next-line no-unused-vars
                const [_,__, index] = key.split('_');
                multiAffectationForm.valeur_cibles.push({
                    objectif_id: values[`objectif_id_${index}`],
                    valeur_cible: values[key],
                });
            } else if (key.startsWith('date_delai')) {
                multiAffectationForm.date_delais.push(values[key]);
            } else if (key.startsWith('commentaire')) {
                multiAffectationForm.commentaires.push(values[key]);
            } else if (key.startsWith('objectif_id')) {
                multiAffectationForm.objectif_ids.push(values[key]);
            } else if (key.startsWith('cible')) {
                // eslint-disable-next-line no-unused-vars
                const [_, index, entiteId] = key.split('_');
                const cibleIndex = multiAffectationForm.entite_cibles.findIndex(cible => cible.objectif_id === values[`objectif_id_${index}`]);
                if (cibleIndex === -1) {
                    multiAffectationForm.entite_cibles.push({
                        objectif_id: values[`objectif_id_${index}`],
                        entites: [{ entite_id: entiteId, valeur_cible: values[key] }]
                    });
                } else {
                    multiAffectationForm.entite_cibles[cibleIndex].entites.push({ entite_id: entiteId, valeur_cible: values[key] });
                }
            }
        });
        setLoading(true);
        AffectationObjectifService.getInstance()
            .assignObjectifsToOrganisation(multiAffectationForm)
            .then((response) => {
                Toast.success(response.message);
                onSuccess(response.data);
                form.resetFields();
                setStep(1);
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    };

    const handleSearch = (value) => {
        const filtered = objectifs.filter(objectif => objectif.intitule.toLowerCase().includes(value.toLowerCase()));
        setFilteredObjectifs(filtered);
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Modal width={"70%"} open={visible} footer={null} cancelText="Annuler" onCancel={onCancel}>
            <Card>
                <Spin spinning={loading}>
                    {step === 1 ? <Step1Form form={form} objectifs={filteredObjectifs} handleSearch={handleSearch} buildMultiAffectationForm={buildMultiAffectationForm} onFinishFailed={onFinishFailed} /> : <Step2Form form={form} multiAffectationForm={multiAffectationForm} objectifs={objectifs} setStep={setStep} handleMultiAffectationSubmit={handleMultiAffectationSubmit} onFinishFailed={onFinishFailed} />}
                </Spin>
            </Card>
        </Modal>
    );
}

export default AffectationObjectifToOrganisation;