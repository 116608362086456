import { DaoService } from "./DaoService";

export default class SearchService extends DaoService {
    static getInstance() {
        return super.getInstance('search');
    }

    search(searchText) {
        return SearchService.post(`${this.prefix}`,  {searchText} );
    }
}