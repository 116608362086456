import React, { useState, useEffect } from 'react';
import { Modal, Form, Checkbox, Button, Spin, Input, Divider } from 'antd';
import OrganisationService from '../../services/OrganisationService';
import UserService from '../../services/UserService';
import { catchError } from '../../services/DaoService';
import Toast from '../../helpers/Toast';

const { Search } = Input;

const UserOrganisationsForm = ({ userId, visible, onCancel, onUpdate }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [organisations, setOrganisations] = useState([]);
    const [filteredOrganisations, setFilteredOrganisations] = useState([]);
    const [selectedOrganisations, setSelectedOrganisations] = useState([]);
    const allCheck = filteredOrganisations.length === selectedOrganisations.length;
    const indeterminate = selectedOrganisations.length > 0 && selectedOrganisations.length < filteredOrganisations.length;
    
    const onCheckAllChange = (e) => {
        setSelectedOrganisations(e.target.checked ? filteredOrganisations.map(org => org.id) : []);
    };

    useEffect(() => {
        setLoading(true);
        Promise.all([
            OrganisationService.getInstance().all(),
            UserService.getInstance().findLinkedOrganisations(userId)
        ]).then(([organisationsResponse, linkedOrganisationsResponse]) => {
            setOrganisations(organisationsResponse.data);
            setSelectedOrganisations(linkedOrganisationsResponse.data.map(org => org.id));
            setFilteredOrganisations(organisationsResponse.data);
        }).catch((error) => {
            catchError(error);
        }).finally(() => {
            setLoading(false);
        });
    }, [userId]);

    const onFinish = () => {
        setSubmitting(true);
        UserService.getInstance()
            .linkOrganisations(userId, { organisationIds: selectedOrganisations })
            .then((response) => {
                Toast.success(response.message);
                onUpdate();
            })
            .catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const onChange = (checkedValues) => {
        setSelectedOrganisations(checkedValues);
    };

    const onSearch = (value) => {
        const filtered = organisations.filter(org => org.nom.toLowerCase().includes(value.toLowerCase()));
        setFilteredOrganisations(filtered);
    };

    return (
        <Modal
            open={visible}
            title="Associer des organisations"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Annuler
                </Button>,
                <Button key="submit" type="primary" onClick={onFinish} loading={submitting}>
                    Enregistrer
                </Button>
            ]}
        >
            <Spin spinning={loading}>
                <Search placeholder="Rechercher une organisation" onSearch={onSearch} style={{ marginBottom: '1rem' }} />
                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={allCheck} style={{fontWeight: 'bold'}}>
                    Tout sélectionner
                </Checkbox>
                <Divider />
                <Form form={form} layout="vertical">
                    <Form.Item>
                        <Checkbox.Group style={{ width: '100%' }} onChange={onChange} value={selectedOrganisations} defaultValue={selectedOrganisations}>
                            {filteredOrganisations.map((org) => (
                                <Checkbox key={org.id} value={org.id} style={{ width: '100%' }}>
                                    {org.nom}
                                </Checkbox>
                            ))}
                        </Checkbox.Group>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export default UserOrganisationsForm;