import { Alert, Card, Checkbox, Col, Form, Modal, Row, Switch } from "antd";
import React from "react";
import OrganisationService from "../../services/OrganisationService";
import ObjectifService from "../../services/ObjectifService";
import DashboardConfigService from "../../services/DashboardConfigService";
import Toast from "../../helpers/Toast";

export default function DashboardConfigForm({ visible, onCreate, onCancel }) {

    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const [organisations, setOrganisations] = React.useState([]);
    const [objectifs, setObjectifs] = React.useState([]);
    const [checkedOrganisationList, setCheckedOrganisationList] = React.useState([]);
    const [checkedObjectifList, setCheckedObjectifList] = React.useState([]);
    const [showOrganisationChecked, setShowOrganisationChecked] = React.useState(false);
    const [showObjectifChecked, setShowObjectifChecked] = React.useState(false);
    const [dashboardConfig, setDashboardConfig] = React.useState();
    const [initialValues, setInitialValues] = React.useState(null);
    const [organisationDisabled, setOrganisationDisabled] = React.useState(false);
    const [objectifDisabled, setObjectifDisabled] = React.useState(false);

    React.useEffect(() => {
        if (!visible) return;
        setLoading(true);
        OrganisationService.getInstance()
            .all().then((response) => {
                setOrganisations(response.data);
            })
            .catch(error => {
                console.error(error);
            });
        ObjectifService.getInstance()
            .all().then((response) => {
                setObjectifs(response.data);
            })
            .catch(error => {
                console.error(error);
            });
        DashboardConfigService.getInstance()
            .getRecord().then((response) => {
                setDashboardConfig(response.data);
                if (response.data) {
                    setInitialValues(response.data);
                    setShowOrganisationChecked(response.data.show_organisations);
                    setShowObjectifChecked(response.data.show_objectifs);
                    setCheckedOrganisationList(response.data?.organisation_ids);
                    setCheckedObjectifList(response.data.objectif_ids);
                } else {
                    setInitialValues({
                        show_objectifs: false,
                        show_organisations: false,
                        organisation_ids: [],
                        objectif_ids: [],
                    });
                }
            })
            .catch(error => {
                console.error(error);
            }).finally(() => {
                setLoading(false);
            }
            );
    }, [visible]);

    React.useEffect(() => {
        setOrganisationDisabled(checkedOrganisationList?.length >= 5);
    }, [checkedOrganisationList]);

    React.useEffect(() => {
        setObjectifDisabled(checkedObjectifList?.length >= 5);
    }, [checkedObjectifList]);

    const onFinish = (values) => {
        if (!values.show_organisations) {
            form.setFields([
                {
                    name: "show_organisations",
                    errors: ["Veuillez visualiser au moins une organisation."],
                }
            ]);
            return;
        }
        if (!values.show_objectifs) {
            form.setFields([
                {
                    name: "show_objectifs",
                    errors: ["Veuillez visualiser au moins un objectif."],
                },
            ]);
            return;
        }
        setLoading(true);
        DashboardConfigService.getInstance()
            .create(values).then(response => {
                Toast.success(response.message);
                setDashboardConfig(response.data);
                onCreate();
            }).catch(error => console.log('error : ', error)
            ).finally(() => setLoading(false));
    }

    const onFailed = (error) => {
        console.log('failed : ', error);
    }

    const onValuesChange = () => {
        const values = form.getFieldsValue();
        if (!values.show_organisations) {
            form.setFields([
                {
                    name: "show_organisations",
                    errors: ["Veuillez visualiser au moins une organisation."],
                }
            ]);
        }
        if (!values.show_objectifs) {
            form.setFields([
                {
                    name: "show_objectifs",
                    errors: ["Veuillez visualiser au moins un objectif."],
                },
            ]);
        }
    }

    const onChangeOrganisationChecked = (checkedValues) => {
        if (checkedValues.length <= 5) {
            setCheckedOrganisationList(checkedValues);
        }
    };

    const onChangeObjectifChecked = (checkedValues) => {
        if (checkedValues.length <= 5) {
            setCheckedObjectifList(checkedValues);
        }
    };

    return (
        <Modal title="Configurer le tableau de bord dynamique" open={visible} width={"75%"}
            okText="Enregistrer" onOk={form.submit} cancelText="Annuler" onCancel={onCancel}>
            <Card loading={loading} style={{ marginBottom: 20 }}>
                <Alert
                    message="Information"
                    description="Veuillez sélectionner au moins une organisation et un objectif à visualiser. Vous pouvez sélectionner jusqu'à 5 organisations et 5 objectifs."
                    type="info"
                    showIcon
                    style={{ marginBottom: '16px', padding: '10px' }}
                />
                <Form layout='vertical'
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFailed}
                    initialValues={initialValues}
                    onValuesChange={onValuesChange}
                >
                    <Row gutter={24}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label="Visualiser des organisations"
                                name="show_organisations"
                                rules={[
                                    { required: true, message: 'Veuillez configurer au moins une organisation !' },
                                ]}
                            >
                                <Switch onChange={(checked) => {
                                    setShowOrganisationChecked(checked);
                                    form.resetFields(['organisation_ids']);
                                    setCheckedOrganisationList(dashboardConfig?.organisation_ids);
                                }} />
                            </Form.Item>
                        </Col>
                        {showOrganisationChecked ?
                            <Form.Item
                                label="Organisations"
                                name="organisation_ids"
                                rules={[
                                    {
                                        required: true,
                                        message: "Veuillez sélectionner au moins une organisation",
                                    },
                                ]}
                            >
                                <Checkbox.Group value={checkedOrganisationList} defaultValue={checkedOrganisationList} onChange={onChangeOrganisationChecked}>
                                    <Row gutter={[16, 16]}>
                                        {organisations.map(organisation => (
                                            <Col span={12} key={organisation.id}>
                                                <Checkbox value={organisation.id} disabled={organisationDisabled && !checkedOrganisationList.includes(organisation.id)}>
                                                    {organisation.nom}
                                                </Checkbox>
                                            </Col>
                                        ))}
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                            : null
                        }
                        <Col xs={24} md={24}>
                            <Form.Item
                                label="Visualiser des objectifs"
                                name="show_objectifs"
                                rules={[
                                    { required: true, message: 'Veuillez configurer au moins un objectif !' },
                                ]}
                            >
                                <Switch onChange={(checked) => {
                                    setShowObjectifChecked(checked);
                                    form.resetFields(['objectif_ids']);
                                    setCheckedObjectifList(dashboardConfig?.objectif_ids);
                                }} />
                            </Form.Item>
                        </Col>
                        {showObjectifChecked ?
                            <Form.Item
                                label="Objectifs"
                                name="objectif_ids"
                                rules={[
                                    {
                                        required: true,
                                        message: "Veuillez sélectionner au moins un objectif",
                                    },
                                ]}
                            >
                                <Checkbox.Group value={checkedObjectifList} onChange={onChangeObjectifChecked}>
                                    <Row gutter={[16, 16]}>
                                        {objectifs.map(objectif => (
                                            <Col span={12} key={objectif.id}>
                                                <Checkbox value={objectif.id} disabled={objectifDisabled && !checkedObjectifList.includes(objectif.id)}>
                                                    {objectif.intitule}
                                                </Checkbox>
                                            </Col>
                                        ))}
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                            : null
                        }
                    </Row>
                </Form>
            </Card>
        </Modal>
    )
}