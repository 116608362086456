import { Checkbox, Col, Divider, Empty, Form, Modal, Row, Spin } from "antd";
import React from "react";
import OrganisationService from "../../services/OrganisationService";
import { catchError } from "../../services/DaoService";
import Toast from "../../helpers/Toast";
import OrganisationRattacheeService from "../../services/OrganisationRattacheeService";

export default function OrganisationRattacheeNew({ visible, onCreate, onCancel, parent }) {

    const [form] = Form.useForm();
    const [organisationsNonRattachees, setOrganisationsNonRattachees] = React.useState([]);
    const [creating, setCreating] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [checkedList, setCheckedList] = React.useState([]);
    const [allChecked, setAllChecked] = React.useState(false);
    const indeterminate = checkedList.length > 0 && checkedList.length < organisationsNonRattachees.length;

    React.useEffect(() => {
        // set checkedList to empty array when allChecked is false
        if (!allChecked) {
            setCheckedList([]);
        } else {
            // set checkedList to all organisations when allChecked is true
            setCheckedList(organisationsNonRattachees.map(org => org.id));
        }
    }, [allChecked, organisationsNonRattachees]);

    React.useEffect(() => {
        // update form values when checkedList changes
        form.setFieldsValue({ enfant_ids: checkedList });
    }, [checkedList, form]);

    React.useEffect(() => {
        if (!visible) return;
        setLoading(true);
        // Récupérer toutes les organisations
        OrganisationService.getInstance()
            .all()
            .then(response => {
                const allOrganisations = response.data;
                // Récupérer les organisations rattachées
                OrganisationRattacheeService.getInstance()
                    .findByParent(parent.uid)
                    .then(response => {
                        const organisationRattachees = response.data.data;
                        // Extraire les ids des enfants des organisations rattachées
                        const rattachésIds = organisationRattachees.map(rattachee => rattachee.enfant.id);

                        // Filtrer les organisations non rattachées et celles dont le code de type d'organisation est "PROJET"
                        const nonRattachees = allOrganisations.filter(org => (
                            !rattachésIds.includes(org.id) && org.type_organisation.code !== 'PROJET'
                        ));

                        // Mettre à jour le state avec les organisations non rattachées
                        setOrganisationsNonRattachees(nonRattachees);
                        setLoading(false);
                    }).catch(error => {
                        console.error(error);
                    });
            }).catch(error => {
                console.error(error);
            });
    }, [visible, parent?.uid]);

    const onSave = (values) => {
        values.parent_id = parent.id;
        setCreating(true);
        OrganisationRattacheeService.getInstance()
            .create(values)
            .then(response => {
                Toast.success(response.message);
                form.resetFields();
                onCreate(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setCreating(false);
            });
    };

    const onFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const onChange = (list) => {
        setCheckedList(list);
    };

    const onCheckAllChange = (e) => {
        setAllChecked(e.target.checked);
    };

    return (
        <Modal open={visible} okText="Enregistrer" cancelText="Annuler" onOk={form.submit} onCancel={onCancel} width={"50%"}>
            <Spin spinning={creating || loading}>
                <Form
                    form={form}
                    name="newOrganisationRattacheeForm"
                    layout="vertical"
                    onFinish={onSave}
                    onFinishFailed={onFailed}
                >
                    <Form.Item label="Organisations">
                        <Checkbox disabled={!organisationsNonRattachees?.length} indeterminate={indeterminate} onChange={onCheckAllChange} checked={allChecked} style={{ fontWeight: 'bold', marginTop: 10 }}>
                            Tout sélectionner
                        </Checkbox>
                        <Divider />
                    </Form.Item>
                    <Form.Item
                        name="enfant_ids"
                        rules={[
                            { required: true, message: 'Ce champ est obligatoire!' }
                        ]}
                    >
                        {organisationsNonRattachees.length ?
                            <Checkbox.Group value={checkedList} defaultValue={checkedList} onChange={onChange} style={{ width: '100%' }}>
                                <Row gutter={[16, 16]}>
                                    {organisationsNonRattachees.map(organisation => (
                                        <Col span={12} key={organisation.id}>
                                            <Checkbox value={organisation.id} style={{ width: '100%' }}>
                                                {organisation.nom}
                                            </Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                            : <Empty description="Aucune organisation à rattacher..." />}
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
}