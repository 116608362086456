import React from "react";
import { Button, Card, Col, Popconfirm, Row, Space, Tag, Typography } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditFilled } from "@ant-design/icons";
import styles from '../organisation/OrganisationDetails.module.scss';
import OrganisationService from "../../services/OrganisationService";
import { catchError } from "../../services/DaoService";
import { useUserContext } from "../../providers/UserProvider";
import { useNavigate } from "react-router-dom";
import ProgressChart from "../../charts-model/ProgressChart";
import OrganisationEdit from "./OrganisationEdit";

const { Title, Text } = Typography;

export default function OrganisationDetails({ organisation }) {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { check } = useUserContext();
  const navigate = useNavigate();
  const [editModalVisible, setEditModalVisible] = React.useState(false);
  const [selectedUid, setSelectedUid] = React.useState(null);
  const [getOrganisation, setGetOrganisation] = React.useState(organisation);
  
  
  const removeOrganisation = (organisation) => {
    setIsDeleting(true);
    OrganisationService.getInstance()
      .remove(organisation.uid)
      .then(() => {
        navigate(`/parametrage/organisation`);
      }).catch(error => {
        catchError(error);
      }).finally(() => {
        setIsDeleting(false);
      });
  }

  const setItemToEdit = (organisation) => {
    setSelectedUid(organisation.uid);
    setEditModalVisible(true);
  }
  const handleOrganisationUpdate = (organisation) => {
    setGetOrganisation(organisation);
    setEditModalVisible(false);
}

  return (
    <Row gutter={[24, 16]}>
      <Col xs={24} lg={12}>
      <OrganisationEdit onUpdate={handleOrganisationUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} uid={selectedUid} />
        <Card className={styles.card} title="Détails" bordered
          extra={
            <Space>
              {check('EDIT-ORGANIZATION') ? <Button onClick={() => setItemToEdit(getOrganisation)} icon={<EditFilled />} className="btn btn-warning"></Button> : null}
              {check('DELETE-ORGANIZATION') ?
                <Popconfirm okType='danger' title="Voulez-vous vraiment supprimer cette organisation?" onConfirm={() => removeOrganisation(getOrganisation)} okText="Confirmer" cancelText="Annuler">
                  <Button loading={isDeleting}
                    type="primary"
                    danger
                    icon={<DeleteOutlined /> }
                    className="btn btn-danger"
                  ></Button>
                </Popconfirm>
                : null}
            </Space>
          }
        >
          <div className={styles.info}>
            <div className={styles.item}>
              <Title level={5} className={styles.label}>Nom</Title>
              <Text>{getOrganisation.nom}</Text>
            </div>
            <div className={styles.item}>
              <Title level={5} className={styles.label}>Sigle</Title>
              <Text>{getOrganisation.sigle}</Text>
            </div>
            <div className={styles.item}>
              <Title level={5} className={styles.label}>Description</Title>
              <Text>{getOrganisation.description}</Text>
            </div>
            <div className={styles.item}>
              <Title level={5} className={styles.label}>Type d'organisation</Title>
              <Text>{getOrganisation.type_organisation ? getOrganisation.type_organisation.nom : 'N/A'}</Text>
            </div>
            <div className={styles.item}>
              <Title level={5} className={styles.label}>Responsable</Title>
              <Text>{getOrganisation.responsable ? getOrganisation.responsable : 'N/A'}</Text>
            </div>
            <div className={styles.item}>
              <Title level={5} className={styles.label}>Statut</Title>
              {getOrganisation.enabled ? (
                <Tag className={`${styles.tag} ${styles.active}`} icon={<CheckCircleOutlined />} color="success">Actif</Tag>
              ) : (
                <Tag className={`${styles.tag} ${styles.inactive}`} icon={<CloseCircleOutlined />} color="error">Inactif</Tag>
              )}
            </div>
          </div>
        </Card>
      </Col>
      <Col xs={24} lg={12}>
        <Card classNames={{ body: "p-0" }}>
          <ProgressChart percentage={getOrganisation.objectifs_atteints_percentage} />
        </Card>
      </Col>
    </Row>
  );
}
