import { DaoService } from "./DaoService";

export default class AffectationMissionService extends DaoService {

    static getInstance() {
        return super.getInstance('affectationmission');
    }

    findByMissionControle(missionControleId) {
        return AffectationMissionService.get(`${this.prefix}/${missionControleId}/mission-controle`);
    }
    enable(idOrUid) {
        return AffectationMissionService.post(`${this.prefix}/${idOrUid}/enable`);
    }

    disable(idOrUid, data) {
        return AffectationMissionService.post(`${this.prefix}/${idOrUid}/disable`, data);
    }

    done(idOrUid, data) {
        return AffectationMissionService.put(`${this.prefix}/${idOrUid}/done`, data);
    }

}