import React from "react";
import { Card, Typography } from "antd";
import Chart from "react-apexcharts";
import styles from './EntiteAffectationObjectifsEvolutionsDonut.module.scss';

const { Title } = Typography;

const EntiteAffectationObjectifsEvolutionsDonut = ({ data }) => {
  const series = data.map(item => item.objectifs);
  const labels = data.map(item => item.sigle);

  const options = {
    chart: {
      type: 'donut',
      height: 500,
    },
    labels: labels,
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => a + b, 0) + '%'
              }
            }
          }
        }
      }
    },
    legend: {
      position: 'bottom'
    },
    dataLabels: {
      dropShadow: {
        enabled: false
      }
    },
    fill: {
      type: 'gradient',
    },
    colors: ['#00E396', '#FEB019', '#FF4560', '#775DD0', '#00D9E9', '#FFB567'],
  };

  return (
    <Card className={styles.chartCard} classNames={{body: 'p-0', header: 'px-1'}} title={
      <Title level={4}>Répartition des Objectifs</Title>
    }>
      <Chart options={options} series={series} type="donut" height={500} />
    </Card>
  );
};

export default EntiteAffectationObjectifsEvolutionsDonut;