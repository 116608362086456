import React from "react";
import ObjectifList from "./ObjectifList";



export default function SousObjectifList({parentId}) {
    const [loading, setLoading] = React.useState(true);
    const [paginationData, setPaginationData] = React.useState(null);
    
    return (
        <ObjectifList loading={loading} paginationData={paginationData} setLoading={setLoading} setPaginationData={setPaginationData} title='Liste des sous objectifs' parentId={parentId}/>
    );
}