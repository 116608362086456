import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import DashboardService from '../../services/DashboardService';
import { catchError } from '../../services/DaoService';

const AffectationDirecteObjectifsEvolutions = ({ affectationObjectif }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        DashboardService.getInstance()
            .getAffectationDirecteObjectifsEvolutions(affectationObjectif.uid)
            .then(response => {
                setData(response.data);
                console.log(response.data);
            })
            .catch(error => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [affectationObjectif]);

    const chartOptions = {
        chart: {
            type: 'line',
            height: 350
        },
        title: {
            text: 'Évolution des objectifs',
            align: 'left'
        },
        xaxis: {
            categories: data.map(item => item.date)
        },
        yaxis: {
            title: {
                text: 'Valeur'
            }
        }
    };

    const chartSeries = [
        {
            name: 'Valeur réelle',
            data: data.map(item => item.valeur_reelle)
        }
    ];

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Chart options={chartOptions} series={chartSeries} type="line" height={350} />
    );
};

export default AffectationDirecteObjectifsEvolutions;