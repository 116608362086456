/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Route, Routes } from "react-router-dom";
import { ConfigProvider, Spin } from 'antd';

// import "antd/dist/antd.css";
import "antd/dist/reset.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/responsive.css";
import { useUserContext } from "./providers/UserProvider";
import React from "react";
import GroupeListPage from "./pages/groupe/GroupeListPage";
import HomePage from "./pages/HomePage";
import OrganisationListPage from "./pages/organisation/OrganisationListPage";
import OrganisationDetailsPage from "./pages/organisation/OrganisationDetailsPage";
import LoginPage from "./pages/LoginPage";
import ObjectifListPage from "./pages/objectif/ObjectifListPage";
import ObjectifDetailsPage from "./pages/objectif/ObjectifDetailsPage";
import UserProfilePage from "./pages/user/UserProfilePage";
import UserListPage from "./pages/user/UserListPage";
import UserDetailsPage from "./pages/user/UserDetailsPage";
import Moment from 'react-moment';
import 'moment/locale/fr';
import moment from 'moment-timezone';
import frFR from 'antd/locale/fr_FR';
import ResetPasswordViaTokenPage from "./pages/user/ResetPasswordViaTokenPage";
import KpiAlimentationPage from "./pages/objectif/KpiAlimentationPage";
import ForgotPasswordPage from "./pages/user/ForgotPasswordPage";
import AffectationObjectifDetailsPage from "./pages/affectationobjectif/AffectationObjectifDetailsPage";
import GroupeDetailsPage from "./pages/groupe/GroupeDetailsPage";
import { usePusher } from "./providers/PusherProvider";
import MissionControleListPage from "./pages/missioncontrole/MissionControleListPage";
import MissionControleDetailsPage from "./pages/missioncontrole/MissionControleDetailsPage";
import NotificationListPage from "./pages/notification/NotificationListPage";
import { useNotificationContext } from "./providers/NotificationProvider";
import DashboardDynamic from "./components/dashboarddynamic/DashboardDymanic";

// Sets the moment instance to use.
Moment.globalMoment = moment;
// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'fr';
// Set the output format for every react-moment instance.
//Moment.globalFormat = 'D MMM YYYY';
// Set time zone for every react-moment instance : Africa/Dakar
Moment.globalTimezone = 'Africa/Dakar';
// Set the output timezone for local for every instance.
Moment.globalLocal = true;
// Use a <span> tag for every react-moment instance.
Moment.globalElement = 'span';
// set antd locale to fr

function App() {

  const { getCurrentUser, currentUser, loadingCurrentUser } = useUserContext();
  const { loadUnreadNotificationsCount, loadUnreadNotifications } = useNotificationContext();

  const pusher = usePusher();

  React.useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  React.useEffect(() => {
    if (currentUser) {
      loadUnreadNotificationsCount();
      loadUnreadNotifications();
    }
  }, [currentUser, loadUnreadNotificationsCount, loadUnreadNotifications]);

  React.useEffect(() => {
    if (currentUser && pusher) {
      const channelName = `private-App.Models.User.${currentUser.id}`;
      // const channelName = `test-channel`;
      console.log(`Subscribing to channel: ${channelName}`);
      const channel = pusher.subscribe(channelName);

      channel.bind('test-event', function (data) {
        console.log(data);
      });

      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [pusher, currentUser]);

  if (loadingCurrentUser) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spin size="large" />
      </div>
    );
  }


  return (
    <ConfigProvider locale={frFR}
      theme={{
        // algorithm: theme.darkAlgorithm,
        token: {
          // Seed Token
          colorPrimary: '#1d7c55',
          borderRadius: 5,

          // Alias Token
          // colorBgContainer: '#f2f1e6',
          // person
        },
        components: {
          Table: {
            headerBg: '#1d7c55',
            headerColor: '#fff',
            borderColor: '#fadb14',
            footerBg: '#ffffb8',
            headerSortHoverBg: '#fadb14',
            headerSortActiveBg: '#fadb14',
            cellFontSize: 14
          },
          Card: {
            // headerBg: '#1d7c55',
          },
          Menu: {
            iconSize: 15,
            // itemActiveBg: '#D9363E',
            // itemActiveColor: 'red',
            // itemActiveBorderWidth: 0,
            // itemActiveBorderColor: 'red',
            // itemActiveBorderRadius: 0,
            // itemHoverBorderWidth: 0,
            // itemHoverBorderColor: 'red',
            // itemHoverBorderRadius: 0,
            // itemGroupTitleColor: 'red',
            // itemGroupTitleFontSize: 14,
          }
        }

      }}
    >
      <div className="App">
        <Routes>
          <Route path="/login" exact element={<LoginPage />} />
          <Route exact path="/dashboard" element={<HomePage />} />
          <Route exact path="/dashboard-dynamic" element={<DashboardDynamic />} />
          <Route exact path="/parametrage/groupe" element={<GroupeListPage />} />
          <Route exact path="/parametrage/groupe/:uid" element={<GroupeDetailsPage />} />
          <Route exact path="/parametrage/organisation" element={<OrganisationListPage />} />
          <Route exact path="/parametrage/organisation/:id" element={<OrganisationDetailsPage />} />
          <Route exact path="/parametrage/user" element={<UserListPage />} />
          <Route exact path="/parametrage/user/:uid" element={<UserDetailsPage />} />
          <Route exact path="/objectif/list" element={<ObjectifListPage />} />
          <Route path="/objectif/details/:uid" element={<ObjectifDetailsPage />} />
          <Route path="/objectif/affectation/:uid" element={<AffectationObjectifDetailsPage />} />
          <Route exact path="/objectif/kpi-alimentation" element={<KpiAlimentationPage />} />
          <Route exact path="/objectif/mission-controle" element={<MissionControleListPage />} />
          <Route exact path="/objectif/mission-controle/:uid" element={<MissionControleDetailsPage />} />
          <Route exact path="/profile" element={<UserProfilePage />} />
          <Route exact path="/verify-email/:token" element={<ResetPasswordViaTokenPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/notifications" element={<NotificationListPage />} />
          {
            currentUser ?
              <Route path="*" element={<HomePage />} /> :
              <Route path="*" element={<LoginPage />} />
          }
        </Routes>
      </div>
    </ConfigProvider>
  );
}

export default App;
