import React from "react";

import {
  Button,
  Result,
} from "antd";
import Main from "../components/layout/Main";
import { useUserContext } from "../providers/UserProvider";
import UnauthorizedMessage from "../components/utils/UnauthorizedMessage";
import CountKPIsChart from "../components/chart/CountKPIsChart";
import OrganisationEvolutionChart from "../components/chart/OrganisationEvolutions";
import { EyeFilled, PlusCircleFilled } from "@ant-design/icons";
import DashboardConfigForm from "../components/dashboardconfig/DashboardConfigForm";
import { useNavigate } from "react-router-dom";




function HomePage() {
  const navigate = useNavigate();
  const { check } = useUserContext();
  const [configModalVisible, setConfigModalVisible] = React.useState(false);


  const openDashboardDynamicConfigModal = () => {
    setConfigModalVisible(true);
  }

  const gotoDashboardDynamic = () => {
    navigate(`/dashboard-dynamic`);
}


  const buttonStyle = {
    marginBottom: '16px',
    marginLeft: '10px',
  }

  return (
    <Main>
      <DashboardConfigForm visible={configModalVisible} onCreate={() => setConfigModalVisible(false)} onCancel={() => setConfigModalVisible(false)} />
      {check('VIEW-DASHBOARD') ?
        (
          <div className="layout-content">
            <CountKPIsChart />
            <div className="d-flex mb-2">
              {
                check('CONFIGURE-DYNAMIC-DASHBOARD') ?
                  <Button type="primary" icon={<PlusCircleFilled />} style={buttonStyle} onClick={openDashboardDynamicConfigModal}>Configurer Dashboard Dynamique</Button>
                  : null}
              {
                check('VIEW-DYNAMIC-DASHBOARD') ?
                  <Button type="primary" icon={<EyeFilled />} style={buttonStyle} onClick={gotoDashboardDynamic}>Visualiser Dashboard Dynamique</Button>
                  : null}
            </div>
            <OrganisationEvolutionChart />
          </div>
        ) : (
          <Result status="403" title="403" subTitle="Désolé, vous n'êtes pas autorisé à accéder à cette page." extra={<UnauthorizedMessage />} />
        )}
    </Main>
  );
}

export default HomePage;
