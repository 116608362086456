import { Button, Card, Popconfirm, Progress, Space, Spin, Table} from "antd";
import { useUserContext } from "../../providers/UserProvider";
import React from "react";
import Toast from "../../helpers/Toast";
import { Link } from "react-router-dom";
import { CheckCircleTwoTone, DeleteFilled, EyeFilled, PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import AffectationMissionService from "../../services/AffectationMissionService";
import { catchError } from "../../services/DaoService";
import AffectationMissionNew from "./AffectationMissionNew";
import AffectationMissionDoneForm from "./AffectationMissionDoneForm";

export default function AffectationMissionList({ missionControle }) {
    const { check } = useUserContext();
    const [loading, setLoading] = React.useState(false);
    const [affectationMissions, setAffectationMissions] = React.useState([]);
    const [deleting, setDeleting] = React.useState(false);
    const [doneModalVisible, setDoneModalVisible] = React.useState(false);
    const [newModalVisible, setNewModalVisible] = React.useState(false);
    const [selectedAffectationMissionUid, setSelectedAffectationMissionUid] = React.useState();


    const loadMissionControleAffectations = React.useCallback(() => {
        if (!check('VIEW-AUDIT-MISSION')) return;
        if (!missionControle) return;
        setLoading(true);
        AffectationMissionService.getInstance()
            .findByMissionControle(missionControle.uid)
            .then(response => {
                setAffectationMissions(response.data.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [missionControle, check]);

    React.useEffect(() => {
        loadMissionControleAffectations();
    }, [loadMissionControleAffectations]);

    const removeItem = (missionControl) => {
        setDeleting(true);
        AffectationMissionService.getInstance()
            .remove(missionControl.uid)
            .then((response) => {
                setAffectationMissions(affectationMissions.filter(item => item.uid !== missionControl.uid));
                Toast.success(response.message);
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setDeleting(false);
            });
    }

    const columns = [
        {
            title: 'N°',
            dataIndex: 'rowNumber',
            key: 'rowNumber',
            render: (text, record, index) => index + 1,
            width: 30,
            fixed: 'left'
        },
        {
            title: 'Objectif',
            dataIndex: ['affectation_objectif', 'objectif', 'intitule'],
            key: 'affectation_objectif.objectif.intitule',
            width: 300,
            ellipsis: true
        },
        {
            title: 'Organisation',
            dataIndex: ['affectation_objectif', 'affectable', 'nom'],
            key: 'affectation_objectif.affectable.nom',
            width: 300,
            ellipsis: true,
        },
        {
            title: 'Observation de la mission',
            dataIndex: 'observation',
            key: 'observation',
            width: 300,
            ellipsis: true,
            render: (_, record) => record.observation ?? 'N/A'
        },
        {
            title: 'Statut',
            dataIndex: 'done',
            key: 'done',
            width: 100,
            render: (_, record) => record.done ? <Progress strokeColor="#1d7c55" type="circle" percent={100} size={50} /> : <Progress type="circle" percent={100} size={50} status="exception" />
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    {check('VIEW-AUDIT-MISSION') ?
                        <Link to={`/objectif/affectation/${record.affectation_objectif.uid}`}>
                            <Button type="primary" icon={<EyeFilled />}></Button>
                        </Link>
                        : null}
                    {check('VALIDATE-AUDIT-MISSION') && !record.done ? 
                    <Button className="bg-success" onClick={() => openDoneModal(record)} type="primary" title="Renseigner les résultats" icon={<CheckCircleTwoTone />}></Button> : null}
                    {check('DELETE-AUDIT-MISSION') ?
                        <Popconfirm title="Voulez-vous vraiment supprimer cette Mission affectée?"
                            onConfirm={() => removeItem(record)} okText="Confirmer"
                            okType="danger" cancelText="Annuler">
                            <Button loading={deleting} type="primary" danger icon={<DeleteFilled />}></Button>
                        </Popconfirm>
                        : null}
                </Space>
            ),
            width: 200
        },
    ];

    const handleAffectationMissionNew = (affectationMissionsNew) => {
        setAffectationMissions([...affectationMissions, ...affectationMissionsNew]);
        setNewModalVisible(false);
    }

    const setItemToEdit = (affectationMissionsNew) => {
        setNewModalVisible(true);
    }

    const openDoneModal = (affectationMission) => {
        setSelectedAffectationMissionUid(affectationMission.uid);
        setDoneModalVisible(true);
    }
    
    const handleAffectationMissionDone = (affectationMission) => {
        setAffectationMissions(affectationMissions.map(am => am.uid === affectationMission.uid ? affectationMission : am));
        setDoneModalVisible(false);
    }

    return (
        <div className="py-2">
            {check('VIEW-AUDIT-MISSION') ? (
                <>
                    <AffectationMissionNew onCreate={handleAffectationMissionNew} visible={newModalVisible} onCancel={() => setNewModalVisible(false)} missionControle={missionControle} />
                    <AffectationMissionDoneForm onDone={handleAffectationMissionDone} visible={doneModalVisible} onCancel={() => setDoneModalVisible(false)} uid={selectedAffectationMissionUid} />

                    <Card title="Liste des objectifs par organisation pour cette mission contrôle" classNames={{ body: 'p-0' }}
                        extra={
                            <Space>
                                <Button loading={loading} onClick={loadMissionControleAffectations} type="default" icon={<ReloadOutlined />}>Rafraîchir</Button>
                                {check('ADD-AUDIT-MISSION') ? <Button icon={<PlusCircleOutlined />} onClick={() => setItemToEdit(missionControle)} type="primary">Ajouter</Button> : null}
                            </Space>
                        } >
                        <Spin spinning={loading}>
                            <Table scroll={{ x: 1300, y: 720 }} rowKey={record => record.uid}
                                dataSource={affectationMissions}
                                columns={columns}
                                pagination={false}
                            />
                        </Spin>
                    </Card>
                </>
            ) : null
            }
        </div>
    );
}